/* global msal */
/* eslint no-throw-literal: 0 */
import Common from "../util/Common";
import API from "./API";
export default class Authenticator {

  constructor() {
    this.mode = "Enterprise";
    this.loginStyle = "popup";

    this.clientId = "604417e0-0d21-48fa-895a-1b0a2e9a3db6";
    this.config = {
      cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {},
      },
    };


   let currentAccount =  window.localStorage.getItem("fo.account");
    this.account = currentAccount ?  JSON.parse(currentAccount):null;

    let usernamePref = window.localStorage.getItem("fo.username");

    this.switchAccount = this.switchAccount.bind(this);
    this.logout = this.logout.bind(this);
    this.api = new API(this, null, "Enterprise");
  }

  async login(credentials) {
    var loginResponse = await this.api.login(credentials.email,credentials.password);
    if (loginResponse && loginResponse.user && loginResponse.user.userId > 0) {
      let user = loginResponse.user;
      let username = user.emailAddress;
      let prefUsername = `${user.firstName} ${user.lastName}`;      
      window.localStorage.setItem("fo.username", username);
      window.localStorage.setItem("fo.preferred_username", prefUsername);
      window.localStorage.setItem("fo.authToken", loginResponse.token);
      
      this.account = loginResponse.user;
      window.localStorage.setItem("fo.account",JSON.stringify(this.account));
    } else {
      throw this.loginFailure();
    }
  }

  async forgetPassword(credentials) {
    return await this.api.forgetPasword(credentials.email);
    
  }
  
  async register(body) {
    return await this.api.register(body);
  }
  async registerCompany(body) {
    return await this.api.registerCompany(body);
  }
  async createUser(body) {
    return await this.api.createUser(body);
  }

  switchAccount() {
    this.login("select_account");
  }

  logout() {
    window.localStorage.removeItem("rm.login_mode");
      window.localStorage.clear();

      window.location.href = '/';

  }

  async isAuthenticated() {
    return !!this.account;
  }

  allowAnonymous() {
    return new Promise((resolve, reject) => {
      let allowAnonymous = window.sessionStorage.getItem('fo.allowAnonymous');
      resolve(allowAnonymous==="true");      
    });
  }

  acquireToken() {
    let self = this;
    return new Promise((resolve, reject) => {
      let token = window.localStorage.getItem("fo.authToken");
      if (token) resolve("Bearer " + token);
      else reject(self.tokenFailure("Not authorized"));
    });
  }

  loginFailure(error) {
    return {
      message: "Login failed. Possibly cancelled by user or popup blocked.",
    };
  }

  tokenFailure(error) {
    return {
      message: "Token acquisition failed. " + error.toString(),
      name: "acquisitionFailure",
    };
  }

  getUsername() {
    let account = this.account;
    return account.username;
  }
}
