/* global EM */
import React, { Component } from "react";
import _ from "underscore";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import Routes from "../app/Routes";
import PageTitle from '../components/PageTitle';
class CompanyConfig extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.companyConfig;
    this.state = {
      parentRecordDetail: { id: this.props.match.params.companyId },
      config: {},
      isSuperAdmin:EM.isSuperAdmin(),
    };
    EM.company.loadSingleEntity(this.state.parentRecordDetail);
    this.entity.loadSingleEntity(this.state.parentRecordDetail).then(() => {
      this.setState({ config: this.entity.get() || {} });
    });

    this.onCheckChanged = this.onCheckChanged.bind(this);
    this.onTextChanged = this.onTextChanged.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onCheckChanged(event, field) {
    let config = Object.assign(this.state.config);
    config[field] = event.target.checked;
    this.setState({ config });
  }
  async componentDidMount(){
    
    this.setState({companyName:EM.getDefaultCompany().companyName})    
    
  }
  onTextChanged(event, field) {
    let self = this;
    let config = Object.assign(this.state.config);
    config[field] = event.target.value;
    this.setState({ config }, () => {
      console.log(self.state.config);
    });
  }

  onSave() {
    this.entity.update(this.entity.get(), this.state.config);
  }

  render() {
    let data = {};
    let config = {}
    if (EM.allLoaded(EM.company, this.entity)) {
      data = EM.company.get() || {};
      config = this.state.config;
    }
    const expiryDays = [{
      value: 30,
      label: "30 Days"
    },{
      value: 60,
      label: "60 Days"
    },{
      value: 90,
      label: "90 Days"
    },{
      value: 120,
      label: "120 Days"
    },{
      value: 150,
      label: "150 Days"
    },{
      value: 180,
      label: "180 Days"
    }
  ]
    return (
      <div key="contents" className="page container-fluid">   
      <PageTitle title={`Services ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`} bar={true}></PageTitle>    
        <div className="container">
          <Form className="form-create">
            <FormGroup className="row mt-4">
              <div className="col-4">  <Input type="checkbox" onChange={(event) => { this.onCheckChanged(event, "stripe"); }} checked={config.stripe} />
                <Label>Stripe</Label></div>
              <div className="col-8"><span className="configSpan">Stripe integration</span></div>

            </FormGroup>
            <FormGroup hidden={!config.stripe}>
              <Label>Stripe Publishable Key	</Label>
              <Input type="text" onChange={(event) => { this.onTextChanged(event, "stripePublishableKey"); }} value={config.stripePublishableKey ? config.stripePublishableKey : ''} />
            </FormGroup>
            <FormGroup hidden={!config.stripe}>
              <Label>Stripe Secret Key	</Label>
              <Input type="text" onChange={(event) => { this.onTextChanged(event, "stripeSecretKey"); }} value={config.stripeSecretKey ? config.stripeSecretKey : ''} />
            </FormGroup>
            <FormGroup hidden={!config.stripe}>
              <Label>Stripe API Key		</Label>
              <Input type="text" onChange={(event) => { this.onTextChanged(event, "stripeApiKey"); }} value={config.stripeApiKey ? config.stripeApiKey : ''} />
            </FormGroup>
            {this.state.isSuperAdmin===true && <FormGroup>
              <Label>Per Unit Price</Label>
              <Input type="text" onChange={(event) => { this.onTextChanged(event, "unitPrice"); }} value={config.unitPrice ? config.unitPrice : ''} />
            </FormGroup>}
            <FormGroup>
              <Label>Expiration Duration (Days)</Label>
              <select className="form-control" onChange={(event) => { this.onTextChanged(event, "sessionExpiryDuration"); }}>
                  <option value="">Please Select</option>
                  {expiryDays.map(day => <option selected={config.sessionExpiryDuration === day.value} value={day.value}>{day.label}</option>)}
              </select>            
            </FormGroup>
            <FormGroup className="row  mt-4">
              <div className="col-4">
                <Input type="checkbox" onChange={(event) => { this.onCheckChanged(event, "sessionEnhance"); }} checked={config.sessionEnhance} />
                <Label>Session Enhance</Label></div>
              <div className="col-8">
                <span className="configSpan">Enables Flashop Studio to digitally enhance your uploaded images saving you time. Allows you to focus on the business while delivering beautiful images!</span>
              </div>
            </FormGroup>
            {/* <FormGroup className="row  mt-4">
              <div className="col-4">
                <Input type="checkbox" onChange={(event) => { this.onCheckChanged(event, "digitalCopyPromo"); }} checked={config.digitalCopyPromo} />
                <Label>Digital Copy Promo</Label>
              </div>
              <div className="col-8">
                <span className="configSpan">Enables your users to select one image as a digital promo. This is a proven way to sell as the loss leaders.</span>
              </div>
            </FormGroup> */}
            <FormGroup className="row  mt-4">
              <div className="col-4">
                <Input type="checkbox" onChange={(event) => { this.onCheckChanged(event, "mazibook"); }} checked={config.mazibook} />
                <Label>Enable Mazibook</Label>
              </div>
              <div className="col-8">
                <span className="configSpan">Enables MaziBook integration.</span>
              </div>
            </FormGroup>
            {/* <FormGroup className="row  mt-4">
              <div className="col-4">
                <Input type="checkbox" onChange={(event) => { this.onCheckChanged(event, "enableInAppPurchases"); }} checked={config.enableInAppPurchases} />
                <Label>Enable In-App<br />Purchasing</Label>
              </div>
              <div className="col-8">
                <span className="configSpan">Enables In-App purchasing.</span>
              </div>
            </FormGroup> */}
            {/* <FormGroup className="row  mt-4">
              <div className="col-4">
                <Input key="ctrlschoolDays" type="checkbox" onChange={(event) => { this.onCheckChanged(event, "schoolDays"); }} checked={config.schoolDays} />
                <Label>School Days</Label>
              </div>
              <div className="col-8">
                <span className="configSpan">Enables School Days Pricing.</span>
              </div>
            </FormGroup> */}
            <FormGroup className="row  mt-4">
              <div className="col-4">
                <Input type="checkbox" onChange={(event) => { this.onCheckChanged(event, "printing"); }} checked={config.printing} />
                <Label>Printing</Label>
              </div>
              <div className="col-8">
                <span className="configSpan">Enables you to own the printing.</span>
              </div>
            </FormGroup>
            <FormGroup hidden={!config.printing}>
              <Label>Customer Number		</Label>
              <Input type="text" onChange={(event) => { this.onTextChanged(event, "customerNumber"); }} value={config.customerNumber ? config.customerNumber : ''} />
            </FormGroup>
            <FormGroup hidden={!config.printing}>
              <Label>Printing Lab</Label>
              <Input
                name="printinglabId"
                type="select"
                onChange={(event) => { this.onTextChanged(event, "printinglabId"); }}
                value={config.printinglabId ? config.printinglabId : 1}
              >
                <option value={1}>
                UPILAB
                </option>
                <option value={2}>
                GESKUS
                </option>
              </Input>
            </FormGroup>
            <FormGroup className="row mt-4">
              <div className="col-4"><Input type="checkbox" onChange={(event) => { this.onCheckChanged(event, "sendGrid"); }} checked={config.sendGrid} />
                <Label>Send Grid</Label></div>
              <div className="col-8"><span className="configSpan">SendGrid Twilio integration</span></div>
            </FormGroup>
              <FormGroup hidden={!config.sendGrid}>
                <Label>From Email</Label>
                <Input type="text" onChange={(event) => { this.onTextChanged(event, "sendGridFromEmail"); }} value={config.sendGridFromEmail ? config.sendGridFromEmail : ''} />
              </FormGroup>
              <FormGroup hidden={!config.sendGrid}>
                <Label>Send Grid API Key		</Label>
                <Input type="text" onChange={(event) => { this.onTextChanged(event, "sendGridApikey"); }} value={config.sendGridApikey ? config.sendGridApikey : ''} />
              </FormGroup>
              <FormGroup hidden={!config.sendGrid}>
                <Label>Company Name</Label>
                <Input type="text" onChange={(event) => { this.onTextChanged(event, "sendGridCompanName"); }} value={config.sendGridCompanName ? config.sendGridCompanName : ''} />
              </FormGroup>
              {/* twillio */}
            <FormGroup className="row mt-4">
              <div className="col-4"><Input type="checkbox" onChange={(event) => { this.onCheckChanged(event, "twilio"); }} checked={config.twilio} />
                <Label>Twilio SMS</Label></div>
              <div className="col-8"><span className="configSpan">Twilio SMS integration</span></div>
            </FormGroup>
              <FormGroup hidden={!config.twilio}>
                <Label>Twilio Phone</Label>
                <Input type="text" onChange={(event) => { this.onTextChanged(event, "twilioPhone"); }} value={config.twilioPhone ? config.twilioPhone : ''} />
              </FormGroup>
              <FormGroup hidden={!config.twilio}>
                <Label>Twilio  Account Sid</Label>
                <Input type="text" onChange={(event) => { this.onTextChanged(event, "twilioAccountSid"); }} value={config.twilioAccountSid ? config.twilioAccountSid : ''} />
              </FormGroup>
              <FormGroup hidden={!config.twilio}>
                <Label>Twilio Auth Token</Label>
                <Input type="text" onChange={(event) => { this.onTextChanged(event, "twilioAuthToken"); }} value={config.twilioAuthToken ? config.twilioAuthToken : ''} />
              </FormGroup>
          </Form>
          <div className="row  mt-4">
            <div className="col-md-12 text-center">
              <Button color="primary mr-1" onClick={this.onSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyConfig;
