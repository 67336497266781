import * as types from '../actionTypes';

const intialState = {
    dateUpdated : null
}
export default function terms(state = intialState, action) {
    switch (action.type) {
        case types.LOAD_TERMS:
            return action.data;
        default:
            return state;
    }
}