/* global EM */
import React, { Component } from "react";


class Packages extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let self = this;
        if (EM.allLoaded(EM.products, EM.companyConfig)) {
            return (
                <div>
                    {
                        (self.props.packageOrders.length === 0) ? (<div>No records found</div>) :
                            self.props.packageOrders.map((order) => {
                                return (
                                    <div className="row">
                                        <div className="col-6">
                                            <div style={{ backgroundColor: '#f5f6fa' }}><h5>Packages Order {order.printOrderId}</h5></div>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr style={{ backgroundColor: "#ecf0f1" }}>
                                                        <th>ID</th>
                                                        <th>All Digital</th>
                                                        <th>Consumed</th>
                                                        <th>Image Count</th>
                                                        <th>Sheet Count</th>
                                                        <th>16x20</th>
                                                        <th>11x14</th>
                                                        <th>Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{order.prepaidId}</td>
                                                        <td>{order.allDigital == true?"Yes":"No"}</td>
                                                        <td>{order.consumed == true?"Yes":"No"}</td>
                                                        <td>{order.imageCount}</td>
                                                        <td>{order.sheetCount}</td>
                                                        <td>{order.largePrint1620}</td>
                                                        <td>{order.largePrint1114}</td>
                                                        <td>{order.price}</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>)
                            })
                    }
                </div>
            )
        }
        return (<div>Loading...</div>);
    }
}
export default Packages;