/* global EM */
import * as types from '../actionTypes';

function translate(itemName){
    let t = EM.t(itemName.toLowerCase() + '.title', true);
    return t || itemName;
}

export function startFetch(itemName) {
    return { type: types.START_FETCH, data: translate(itemName) };
}

export function endFetch(itemName) {
    window.setTimeout(() => {
        EM.dispatch(removeFetchItem(itemName));
    }, 500);
    return { type: types.END_FETCH, data: translate(itemName) };
}

export function removeFetchItem(itemName, displayAs) {
    return { type: types.REMOVE_FETCH, data: translate(itemName) };
}
