/* global EM */
import React, { Component } from "react";

class CompletedOrders extends Component {
  constructor(props) {
    super(props);    
    this.entity = EM.totalCompletedOrders;

    let company = EM.getDefaultCompany();

    this.state = {
      parentRecordDetail:{companyId:company.companyId},
    };
    this.entity.loadEntityByParent(this.state.parentRecordDetail);
  }

  render() {
    let data = [];
    let errMsg = ''
    
    if (EM.allLoaded(EM.companies)) {
      data = this.entity.get();
      if(!data?.length) errMsg= "No Record Found"
    }
    return (
      <div key="contents" className="page container-fluid">
        <div className="d-flex align-items-center justify-content-center mb-2">
          <button className="mr-auto btn btn-primary buttonwidth" onClick={()=>this.props.history.goBack()}><i className="fas fa-light fa-chevron-left"></i></button>
          <h5 className="mr-auto mb-0">Completed Orders</h5>
        </div>
        <div className="row mt-2">
          <div className="col-12">
              <table className="table table-bordered mb-0">
                  <thead>
                      <tr style={{ backgroundColor: "#ecf0f1" }}>
                        <th>Event ID</th>
                        <th>Event Name</th>
                        <th>User's Cart Count</th>
                        <th>Actions</th>
                      </tr>
                  </thead>
                  <tbody>
                    {data?.length ? data.map((row) => (
                      <tr key={row.locationId}>
                        <td style={{ paddingTop: '1rem' }}>{row.locationId}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.locationName}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.userCartCount}</td>
                        <td style={{ paddingTop: '1rem' }}>
                          <i className='fas fa-eye' style={{color: '#007bff', cursor: "pointer"}} onClick={()=>this.props.history.push(`/userCompletedOrders/${row.locationId}`)}/>
                        </td>
                      </tr>
                    )): <tr className="text-center">
                    <td colSpan="4" className="text-danger"><h6>{errMsg}</h6></td>
                  </tr>}
                  </tbody>
              </table>
          </div>
      </div>
      </div>
    );
  }
}

export default CompletedOrders;
