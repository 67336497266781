/* global EM */
import React, { Component } from 'react';
import AppointmentOptions from './AppointmentOptions';
import { Button } from 'reactstrap';
import Routes from '../app/Routes';
import _ from 'underscore';
import PageTitle from '../components/PageTitle';
import Sales from '../pages/Sales'
import Spinner from './Spinner';
import scrollToTop from "../assets/scrollToTop.svg";
export default class CalendarDayDetails extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      locationId: this.props.locationId,
      data: this.props.data,
      date: this.props.date,
      m_bPleaseWait: true,
      notes: [],
      createAppointmentMode:false,
      showScrollButton: false,
    };
    this.Forward = this.Forward.bind(this);
    this.Backward = this.Backward.bind(this);
    this.notesChange = this.notesChange.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
    this.checkinChanged = this.checkinChanged.bind(this);
    this.createAppointmentToggle = this.createAppointmentToggle.bind(this);
    this.reLoad = this.reLoad.bind(this);
    this.sales=React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { showScrollButton } = this.state;
    if (window.scrollY > 100 && !showScrollButton) {
      this.setState({ showScrollButton: true });
    } else if (window.scrollY <= 100 && showScrollButton) {
      this.setState({ showScrollButton: false });
    }
  };

  Forward() {
    let dt = this.state.date;
    dt.setDate(dt.getDate() + 1);
    this.props.onDayClick(dt);
  }

  reLoad(){
    let dt = this.state.date;
    this.props.onDayClick(dt);
  }

  Backward() {
    let dt = this.state.date;
    dt.setDate(dt.getDate() - 1);
    this.props.onDayClick(dt);
  }


  testButton(phoneNumber) {
    return (<Button onClick={() => this.sendApp(phoneNumber)} className="btn btn-primary button width100 m-1">Send App</Button>)
  }

  sendApp(phoneNumber) {
    EM.calendar.executeGet(null, { phoneNumber: phoneNumber }, Routes.clientApi.sendApp, {});
  }

  notesChange(event, appointmentID) {
    var notes = _.reject([...this.state.notes], (note) => {
      return note.appointmentId === appointmentID;
    });
    notes.push({ appointmentId: appointmentID, notes: event.target.value });
    this.setState({ notes });
  }

  saveNotes(appointmentId) {
    var notes = _.findWhere(this.state.notes, { appointmentId: appointmentId });
    if (notes)
      EM.calendar.executePut({ appointmentId: appointmentId, notes: notes.notes }, null, Routes.clientApi.saveNotes, {})
        .then((result) => {
          // handle change if need be
        });
  }

  checkinChanged(appointment,value){
    // let data = [...this.state.data];
    // var index = _.indexOf(data,appointment);
    // data[index].checkedIn = value;
    // this.setState({data});
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.data !== props.data) {
      return {
        data: props.data
      }
    }
    return null
  }

  createAppointmentToggle(){
    this.setState({createAppointmentMode:!this.state.createAppointmentMode})
    if(this.state.createAppointmentMode){
      this.reLoad()
    }
  }
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  render() {

    if(this.props.loading){
      return<Spinner text="Loading..."/>
    }
    const createAppointmentMode = this.state.createAppointmentMode
    let dateString = this.state.date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const createAppointmentButton=<div className='col-12  align-middle text-right border'><button onClick={() => this.createAppointmentToggle()} className="btn btn-primary m-2">{createAppointmentMode?'List Appointments':'Create Appointment'}</button></div>
    return (
      <div>      
        <div className="container">
          <div className='row appointmentBtns' style={{top:this.props.btnsCol?"0":"70px"}}>
            <div className={`col-${this.props?.btnsCol??"2"} align-middle border`}><button disabled={createAppointmentMode} onClick={() => this.Backward()} className="btn btn-primary m-2 buttonWidth100">Back</button></div>
            <div className={`col-${this.props?.btnsCol??"8"} align-middle text-center border`}><a className='m-1' href={`javascript:void(0);`} onClick={() => (!createAppointmentMode)?this.props.onClose():console.log('nn')}><b>{dateString}</b></a></div>
            <div className={`col-${this.props?.btnsCol??"2"} align-middle text-right border`}><button disabled={createAppointmentMode} onClick={() => this.Forward()} className="btn btn-primary m-2 buttonWidth100">Forward</button></div>
            {this.state.showScrollButton && (
            <div className='col-12 text-right'>
              <img onClick={this.scrollToTop} src={scrollToTop} className='scroll-to-top' alt="Scroll to Top"/>
            </div>
            )}
          </div>
          {!this.props.bookingPage? (
            <PageTitle children={createAppointmentButton} className='mt-3' title="Appointments"></PageTitle>
          ):null}
          {createAppointmentMode && <Sales ref={this.sales} calendar={{date:dateString, locationId:this.state.locationId}}/>}
          {!createAppointmentMode && this.state.data.map((loc,index) => {
            return (
              <div className='row border mt-3 appointmentCard' key={index}>
                <div className='col-12'>
                  <div className='row mt-1 mb-1'>
                    <div className='col-12 font-weight-bold'>{loc.displayTime} {loc.deal}</div>
                  </div>
                  <div className='row mt-1 mb-1'>
                    <div className='col-3'>Name : <a href={`/UserDetails/${loc.userID}/${loc.location.companyID}`}>{loc.firstName} {loc.lastName}</a> <span className={loc.recheduleCSS}>{loc.recheduleCount}</span></div>
                    <div className='col-3'>Phone: {loc.phoneNumber}</div>
                    <div className='col-3'>Backup Phone: {loc.backupNumber}</div>
                    <div className={`col-${this.props?.btnsCol??"3"}`}>Email: {loc.eMail}</div>
                  </div>
                  <div className='row mt-1 mb-1'>
                    <div className='col-3'>Seller: {loc.sales}</div>
                    <div className='col-9' hidden={loc.checkedIn !== true}>SN: {loc.sitting ? loc.sitting : ''}
                    </div>
                  </div>
                  <div className='row mt-1 mb-1'>
                    <div className='col-3'>Notes: </div>
                  </div>
                  <div className='row mt-1 mb-1'>
                    <div className='col-12'><textarea cols={40} rows={5} className="form-control" defaultValue={loc.notes} onChange={(event) => { this.notesChange(event, loc.appointmentID) }}></textarea> </div>
                  </div>
                  <div className='row mt-3 mb-3'>
                    <div className='col-12'>
                      {this.testButton(loc.phoneNumber)} <button onClick={() => this.saveNotes(loc.appointmentID)} className="btn btn-primary button width100 m-1">Save Notes</button>
                      <AppointmentOptions onCheckInChange={this.checkinChanged} data={loc} bookingPage={this.props.bookingPage}></AppointmentOptions>
                    </div>
                  </div>
                </div>
              </div>
            )

          })}
        </div>
      </div>
    );
  }
}