import Routes from '../app/Routes';

export default [
    {
        name: 'account', 
        idField: 'userId'
    },
    {
        name: 'companies', 
        apiName:'company',
        singleName:'company',        
        idField: 'companyId',
        uniqueness: [ 'name' ]
    },
    {
        name: 'dashboard', 
        apiName:'company/dashboardcounts',
        getUrl:Routes.clientApi.dashboardCount,
    },
    {
        name: 'futureEvents', 
        apiName:'futureeventsdetails',
        getUrl:Routes.clientApi.futureEventsDetails,
        idField: 'eventTypeId',
        uniqueness: [ 'eventTypeId' ]
    },
    {
        name: 'noOrderUsers', 
        apiName:'noOrderUsers',
        getUrl:Routes.clientApi.noOrdersUsers,
        idField: 'locationId',
        uniqueness: [ 'locationId' ]
    },
    {
        name: 'userNoOrderUsers', 
        apiName:'userNoOrder',
        getUrl:Routes.clientApi.userNoOrdersUsers,
        idField: 'locationId',
        uniqueness: [ 'locationId' ]
    },
    {
        name: 'eventsWithPackagesSales', 
        apiName:'eventsWithpackagesSales',
        getUrl:Routes.clientApi.eventsWithPackagesSales,
        idField: 'locationId',
        uniqueness: [ 'locationId' ]
    },
    {
        name: 'totalCompletedOrders', 
        apiName:'totalCompletedOrders',
        getUrl:Routes.clientApi.totalCompletedOrders,
        idField: 'locationId',
        uniqueness: [ 'locationId' ]
    },
    {
        name: 'userCompletedOrders', 
        apiName:'userCompletedOrders',
        getUrl:Routes.clientApi.userCompletedOrders,
        idField: 'locationId',
        uniqueness: [ 'locationId' ]
    },
    {
        name: 'incompleteOrders', 
        apiName:'incompleteOrders',
        getUrl:Routes.clientApi.incompleteOrders,
        idField: 'locationId',
        uniqueness: [ 'locationId' ]
    },
    {
        name: 'userIncompleteOrders', 
        apiName:'userIncompleteOrders',
        getUrl:Routes.clientApi.userIncompleteOrders,
        idField: 'locationId',
        uniqueness: [ 'locationId' ]
    },
    {
        name: 'eventTypes', 
        apiName:'location/eventtypes',
        getUrl:Routes.clientApi.eventTypes,
        createUrl:Routes.clientApi.eventTypeCreate,
        editUrl:Routes.clientApi.eventTypeCreate,
        deleteUrl:Routes.clientApi.eventTypeCreate,
        idField: 'eventTypeId',
        uniqueness: [ 'eventTypeId' ]
    },
    {
        name: 'eventGroups', 
        apiName:'location/eventgroups',
        getUrl:Routes.clientApi.eventgroups,
        createUrl:Routes.clientApi.eventgroupsCreate,
        editUrl:Routes.clientApi.eventgroupsCreate,
        deleteUrl:Routes.clientApi.eventgroupsCreate,
        assignUserUrl:Routes.clientApi.eventgroupsAssignUsers,
        singleName:'Group',        
        idField: 'eventGroupId',
        uniqueness: [ 'eventGroupId' ]
    },
    {
        name: 'companyConfig', 
        apiName:'companyConfig',
        singleName:'companyConfig',        
        idField: 'companyConfigId',
        uniqueness: [ 'companyConfigId' ]
    },
    {
        name: 'locations', 
        singleName:'location',
        apiName:'location',
        createUrl:Routes.clientApi.childEntity,
        editUrl:Routes.clientApi.childEntity,
        deleteUrl:Routes.clientApi.childEntity,
        idField: 'locationId',
        uniqueness: [ 'name' ]
    },
    {
        name: 'compaign', 
        apiName:'marketing/compaign',
        getUrl:Routes.clientApi.compaign,
        createUrl:Routes.clientApi.compaignCUD,
        editUrl:Routes.clientApi.compaignCUD,
        deleteUrl:Routes.clientApi.compaignCUD,
        idField: 'marketingCompaignId',
        uniqueness: [ 'marketingCompaignId' ]
    },
    {
        name: 'sendGridStats', 
        apiName:'marketing/compaign/stats',
        getUrl:Routes.clientApi.sendGridStats,
        idField: 'date',
    },
    {
        name: 'compaignType', 
        singleName:'compaignType',
        apiName:'marketing/compaigntype',
        getUrl:Routes.clientApi.compaignType,
        createUrl:Routes.clientApi.compaignTypeCUD,
        editUrl:Routes.clientApi.compaignTypeCUD,
        deleteUrl:Routes.clientApi.compaignTypeCUD,
        idField: 'marketingComaignTypeId',
        uniqueness: [ 'marketingComaignTypeId' ]
    },
    {
        name: 'products', 
        singleName:'product',
        apiName:'product',
        createUrl:Routes.clientApi.addUpdateProduct,
        editUrl:Routes.clientApi.addUpdateProduct,
        deleteUrl:Routes.clientApi.addUpdateProduct,
        idField: 'productId',
        uniqueness: [ 'name' ]
    },
    {
        name: 'companyProducts', 
        singleName:'companyProducts',
        apiName:'companyProducts',        
        idField: 'companyProductId',
        uniqueness: [ 'companyProductId' ]
    },
    {
        name: 'intervals', 
        apiName:'interval',
        getUrl:Routes.clientApi.intervals,
        createUrl:Routes.clientApi.intervals,
        editUrl:Routes.clientApi.intervals,
        deleteUrl:Routes.clientApi.intervals,
        idField: 'intervalId',
        uniqueness: [ 'intervalId' ]
    },
    {
        name: 'groupUsers', 
        apiName:'groupUsers',
        getUrl:Routes.clientApi.groupUsers,
        createUrl:Routes.clientApi.assignGroupUsers,
        deleteUrl:Routes.clientApi.unassignGroupUsers,
        idField: 'userId',
        uniqueness: [ 'userId' ]
    },
    {
        name: 'eventpackage', 
        apiName:'eventpackage',
        getUrl:Routes.clientApi.eventPackages,
        createUrl:Routes.clientApi.eventPackages,
        editUrl:Routes.clientApi.eventPackages,
        deleteUrl:Routes.clientApi.eventPackages,
        idField: 'eventPackageId',
        uniqueness: [ 'eventPackageId' ]
    },
    {
        name: 'events', 
        singleName:'event',
        apiName:'event',
        getUrl:Routes.clientApi.events,
        editUrl:Routes.clientApi.updateEvent,
        getSingleUrl:Routes.clientApi.singleEvent,
        idField: 'eventId',
        uniqueness: [ 'name' ]
    },
    {
        name: 'eventPromotion', 
        apiName:'eventPromotion',
        getUrl:Routes.clientApi.eventPromotion,
        idField: 'eventId',
        uniqueness: [ 'eventId' ]
    },
    {
        name: 'users', 
        apiName:'user',
        singleName:'user',
        idField: 'userId',
        uniqueness: [ 'firstName' ]
    },
    {
        name: 'userDetails', 
        apiName:'user',
        getUrl:Routes.clientApi.userDetails,
        idField: 'userId',
        uniqueness: [ 'name' ]
    },
    {
        name: 'userScheduler', 
        apiName:'calendar',
        getUrl:Routes.clientApi.userScheduler,
    },
    {
        name: 'rescheduleAppointment', 
        apiName:'rescheduleAppointment',
        getUrl:Routes.clientApi.userScheduler,
    },
    {
        name: 'userSessions', 
        apiName:'userSession',
        getUrl:Routes.clientApi.userSessions,
        editUrl:Routes.clientApi.userSessions,
        idField: 'userSessionId',
        uniqueness: [ 'name' ]
    },
    {
        name: 'userSessionImage', 
        apiName:'usersessionimage',
        getUrl:Routes.clientApi.userSessionImage,
        idField: 'userSessionId',
        uniqueness: [ 'name' ]
    },
    {
        name: 'appointments', 
        apiName:'appointment',
        getUrl:Routes.clientApi.appointments,
        idField: 'appointmentId',
        uniqueness: [ 'name' ]
    },
    {
        name: 'availableDays', 
        apiName:'calendarmonthsmall',
        searchUrl:Routes.clientApi.locationDates    
    },
    {
        name: 'availableSlots', 
        apiName:'slots',
        searchUrl:Routes.clientApi.locationSlots,
        searchUrlV2:Routes.clientApi.locationSlotsV2,    
    },
    {
        name: 'calendar', 
        apiName:'calendar'
    },
    {
        name: 'userTypes', 
        apiName:'userTypes',
        idField: 'userTypeId',
        uniqueness: [ 'name' ]
    },
    {
        name:'prepaid',
        apiName:'prepaid',
        getUrl:Routes.clientApi.prepaids,
        idField:'prepaidId',
        uniqueness:['prepaidId']
    },
    {
        name:'printOrders',
        apiName:'order',
        getUrl:Routes.clientApi.printOrders,
        requeueUrl:Routes.clientApi.orderRequeue,
        idField:'printOrderId',
        uniqueness:['printOrderId']
    },
    {
        name:'printOrderLineItems',
        apiName:'order',
        idField:'printOrderLineItemId',
        uniqueness:['printOrderLineItemId']
    },
    {
        name:'orderFulfillment',
        apiName:'order',
        getUrl:Routes.clientApi.printOrders,
        idField:'printOrderId',
        uniqueness:['printOrderId']
    },
    {
        name:'completedOrders',
        apiName:'order',
        getUrl:Routes.clientApi.completedOrders,
        idField:'printOrderId',
        uniqueness:['printOrderId']
    },
    {
        name:'presets',
        apiName:'presets',
        uploadUrl:Routes.clientApi.presetsUpload
    },
    {
        name:'export',
        apiName:'export',
    },
    {
        name: 'role', 
        apiName:'role',
        idField: 'id',
        uniqueness: [ 'name' ]
    },
    {
        name: 'eventOrder', 
        apiName:'order',
        createUrl:Routes.clientApi.eventOrder, 
    }
]