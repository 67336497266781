/* global EM */
import React, { Component } from "react";
// import EntityTable from "../components/EntityTables/EntityTable";
import CardStats from "../components/CardStats";
import DateEditField from "../components/DateEditField";
import {
  FormGroup,
  Label,
} from "reactstrap";
class Statistics extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.sendGridStats;
    const { campaignId, startDate, campaignName } = this.props.match.params;
    this.state = {
      selectedDate:startDate,
      parentRecordDetail:{campaignId, start_date:startDate, campaignName},
      isSuperAdmin:EM.isSuperAdmin(),
    };
    this.entity.loadEntityByParent({campaignId, start_date:this.state.selectedDate});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDate !== this.state.selectedDate) {
      this.entity.loadEntityByParent({
        campaignId: this.state.parentRecordDetail.campaignId,
        start_date: this.state.selectedDate,
      });
    }
  }
  componentWillUnmount() {
    this.entity.clear()
  }
  getTitle(){
    return `Marketing Campaign Statistics for ${this.state.parentRecordDetail.campaignName}`;
  }

  calculateTotals(statsData) {
    if (!statsData) return null;

    const totals = {
      bounce_drops: 0,
      bounces: 0,
      clicks: 0,
      delivered: 0,
      invalid_emails: 0,
      opens: 0,
      processed: 0,
      requests: 0,
      spam_report_drops: 0,
      spam_reports: 0,
      unique_clicks: 0,
      unique_opens: 0,
    };

    statsData.forEach(({ stats }) => {
      stats.forEach(({ metrics }) => {
        totals.bounce_drops += metrics.bounce_drops || 0;
        totals.bounces += metrics.bounces || 0;
        totals.clicks += metrics.clicks || 0;
        totals.delivered += metrics.delivered || 0;
        totals.invalid_emails += metrics.invalid_emails || 0;
        totals.opens += metrics.opens || 0;
        totals.processed += metrics.processed || 0;
        totals.requests += metrics.requests || 0;
        totals.spam_report_drops += metrics.spam_report_drops || 0;
        totals.spam_reports += metrics.spam_reports || 0;
        totals.unique_clicks += metrics.unique_clicks || 0;
        totals.unique_opens += metrics.unique_opens || 0;
      });
    });

    return totals;
  }

  getColorForValue = (value) => {
    if (value >= 100) {
      return 'green';
    } else if (value >= 1) {
      return 'orange';
    } else {
      return 'red';
    }
  };
  render() {
    // let columns = [];
    let errMsg = ''
    let data = [];
    let newData = [];
    // let self = this;
    let totals = null
    if (this.entity.isLoaded()) {
      data = this.entity.get();
      if((data.errors && data.errors.length > 0) || (data && data.length<1)) {
        errMsg= "No Analytics Found"
      } else {
        totals = this.calculateTotals(data)
        data.forEach(row => {
          const metricsObject = {
            date: row.date,
            ...row.stats[0].metrics
          };
          newData.push(metricsObject);
        });
      }
      // columns = [     
      //   {
      //     dataField: "date",
      //     text: "date",
      //     filterOptions: {
      //       text: true,
      //     },
      //   }
      // ];
    }
    return (
      <div style={{marginLeft:"40px", marginRight:"40px"}}>
        <div className="d-flex align-items-center justify-content-center">
        <button className="mr-auto btn btn-primary buttonwidth" onClick={()=>this.props.history.goBack()}><i className="fas fa-light fa-chevron-left"></i></button>
        <h2 className="mr-auto mb-0">{this.getTitle()}</h2>
      </div>

      <FormGroup className="d-flex justify-content-center mt-3">
        <Label className="mt-1 mr-1">From Date:</Label>
        <DateEditField
          hideTime={true}
          key="ctrl"
          value={this.state.selectedDate}
          onUpdate={(date) => this.setState({selectedDate:date?.split('T')[0]})}
        />
      </FormGroup>
      <div className="row">
        {totals&&newData.length>0?
          Object.keys(totals).map((key) => (
            <div className="col-md-2 mt-4" key={key}>
              <CardStats label={`${key}`} value={totals[key]} color={this.getColorForValue(totals[key])}/>
            </div>
          )):<div className="col-md-12 text-center">
              <h3 className="text-danger">{errMsg}</h3>
            </div>}
      </div>
      <table className="table table-bordered mt-4">
        <thead>
          <tr>
              <th>Date</th>
              <th>Bounce Drops</th>
              <th>Bounces</th>
              <th>Delivered</th>
              <th>Invalid Emails</th>
              <th>Opens</th>
              <th>Processed</th>
              <th>Requests</th>
              <th>Spam Report Drops</th>
              <th>Spam Reports</th>
              <th>Unique Clicks</th>
              <th>Unique Opens</th>
          </tr>
          </thead>
          {newData.length > 0 && newData.map(row => (
            <tbody>
            <tr>
              <td>{row.date}</td>
              <td>{row.bounce_drops}</td>
              <td>{row.bounces}</td>
              <td>{row.delivered}</td>
              <td>{row.invalid_emails}</td>
              <td>{row.opens}</td>
              <td>{row.processed}</td>
              <td>{row.requests}</td>
              <td>{row.spam_report_drops}</td>
              <td>{row.spam_reports}</td>
              <td>{row.unique_clicks}</td>
              <td>{row.unique_opens}</td>
            </tr>
            </tbody>
          ))}
      </table>
      {/* <EntityTable
            pageTitle={`${this.getTitle()}`}
            entity={this.entity}
            data={newData}
            columns={columns}
            customOverRide={[this.state.parentRecordDetail]}
            className="table-overflow-container"
            hideImportExport={true}
            hideAddRecord={true}
            hideDelete={true}
            hideAudit ={true}
            neverShowId={true}
            hideToolbar={true}
            parentRecordDetail={this.state.parentRecordDetail}
          /> */}
    </div>
    );
  }
}

export default Statistics;
