import React, { Component } from "react";
import Modal from 'react-modal';

const wide = { width: '100%' }
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
class MessageBox extends Component{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Modal isOpen={this.props.m_bOpen} style={customStyles} contentLabel="Confirm">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>Confirm</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            &nbsp;
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h4>                    {this.props.m_strMessage}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <table style={wide}>
                                <tr>
                                    <td align="center">
                                        <button onClick={() => this.props.m_fpYes()} className="btn btn-danger buttonwidth">Yes</button>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td align="center">
                                        <button onClick={() => this.props.m_fpNo()} className="btn btn-primary buttonwidth">No</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default MessageBox;
