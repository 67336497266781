/* global EM */
import React, { Component } from "react";
import Dates from '../util/Dates'
import Routes from "../app/Routes";
import CalDay from "../components/CalendarDay";


class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: Dates.now(),     //m_strSelectedDate
            lastSelectDay: 0,
            lastSelectWeek: 0,
            aptDays: [],
            selectedDateView: new Date(),
            months: this.getMonths(),
            locationId: this.props.aptCalender.locationId,
        };
    }
    getMonths() {
        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        return month;
    }

    componentDidUpdate() {
        if (this.state.locationId != this.props.aptCalender.locationId) {
            this.updateCalendar();
            this.setState({ locationId: this.props.aptCalender.locationId });
        }
    }
    componentDidMount() {
        var self = this;
        self.updateCalendar();
    }

    updateCalendar() {
        var self = this;
        EM.userScheduler.executeGet(null, { locationId: this.props.aptCalender.locationId, date: self.state.selectedDateView.toLocaleDateString() }, Routes.clientApi.calenderMonth, {}, true).then((res) => {
            this.setState({ aptDays: res });
        });
    }

    dayClick(enabled, strdate, day, week) {
        if (!enabled)
            alert("Not available");
        else {
            this.setState({ selectedDate: strdate, lastSelectDay: day, lastSelectWeek: week });
            this.props.calendarChange(strdate);
        }
    }

    getWeek(week) {
        return <tr>
            <td onClick={() => this.dayClick(this.getDay(0, week).enabled, this.getDay(0, week).strdt, 0, week)} className={this.getDay(0, week).css}>{this.getDay(0, week).title}</td>
            <td onClick={() => this.dayClick(this.getDay(1, week).enabled, this.getDay(1, week).strdt, 1, week)} className={this.getDay(1, week).css}>{this.getDay(1, week).title}</td>
            <td onClick={() => this.dayClick(this.getDay(2, week).enabled, this.getDay(2, week).strdt, 2, week)} className={this.getDay(2, week).css}>{this.getDay(2, week).title}</td>
            <td onClick={() => this.dayClick(this.getDay(3, week).enabled, this.getDay(3, week).strdt, 3, week)} className={this.getDay(3, week).css}>{this.getDay(3, week).title}</td>
            <td onClick={() => this.dayClick(this.getDay(4, week).enabled, this.getDay(4, week).strdt, 4, week)} className={this.getDay(4, week).css}>{this.getDay(4, week).title}</td>
            <td onClick={() => this.dayClick(this.getDay(5, week).enabled, this.getDay(5, week).strdt, 5, week)} className={this.getDay(5, week).css}>{this.getDay(5, week).title}</td>
            <td onClick={() => this.dayClick(this.getDay(6, week).enabled, this.getDay(6, week).strdt, 6, week)} className={this.getDay(6, week).css}>{this.getDay(6, week).title}</td>
        </tr>;
    }
    getDay(day, week) {
        let rmvc = new CalDay();
        for (let mvd of this.state.aptDays) {
            if (mvd.x == day && mvd.y == week) {
                mvd.css = "table-light sortable";
                if (mvd.enabled) {
                    mvd.css = "table-success sortable";
                    if (day == this.state.lastSelectDay && week == this.state.lastSelectWeek) {
                        mvd.css = "table-primary sortable";
                    }
                }
                return mvd;
            }
        }
        return rmvc;
    }
    navigateCalendar(isForward) {
        var self = this;
        let date = self.state.selectedDateView;
        var newDate = new Date();
        if (isForward)
            newDate = new Date(date.setMonth(date.getMonth() + 1));
        else
            newDate = new Date(date.setMonth(date.getMonth() - 1));
        this.setState({ selectedDateView: newDate });
        self.updateCalendar();
    }
    render() {
        var self = this;
        return (
            <div>
                <table className="table table-bordered">
                    <tr>
                        <td colSpan={2}><button onClick={() => self.navigateCalendar(false)} className="btn btn-primary buttonwidth"><i className="fas fa-light fa-chevron-left"></i></button></td>
                        <td align="center" colSpan={3}><b>{self.state.months[self.state.selectedDateView.getMonth()]}</b></td>
                        <td colSpan={2} align="right"><button onClick={() => self.navigateCalendar(true)} className="btn btn-primary buttonwidth"><i className="fas fa-light fa-chevron-right"></i></button></td> 
                    </tr>
                    <tr>
                        <th>Sun</th>
                        <th>Mon</th>
                        <th>Tues</th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                    </tr>
                    {self.getWeek(0)}
                    {self.getWeek(1)}
                    {self.getWeek(2)}
                    {self.getWeek(3)}
                    {self.getWeek(4)}
                    {self.getWeek(5)}
                </table>
            </div>
        )
    }
}
export default Calendar;