/* global EM */
/* eslint no-return-assign: 0 */
import React, { Component } from 'react';
import cs from 'classnames';

export default class AssignmentStatusEditField extends Component {
    componentDidMount() {
        const { defaultValue, didMount } = this.props;
        this.select.value = defaultValue;
        this.select.focus();
        if (didMount) didMount();
    }

    getValue() {
        return this.select.value.toString();
    }

    render() {
        const { defaultValue, className } = this.props;
        const editorClass = cs('form-control editor edit-select', className);
    
        const attr = {
            className: editorClass
        };

        return (
            <select
                { ...attr }
                ref={ node => this.select = node }
                defaultValue={ defaultValue }
                onChange={() => {
                    if (this.props.onUpdate)this.props.onUpdate(this.getValue());
                    this.select.blur();
                }}
            >
                <option value="Assigned">{EM.t('assignments.statuses.assigned')}</option>
                <option value="Locked">{EM.t('assignments.statuses.locked')}</option>
            </select>
        );
    }
}