export default {
    client: {
        home: '/',
        futureEvents:"/futureEvents",
        noOrderUsers:"/noOrderUsers",
        userNoOrdersUsers:"/userNoOrders/:locationId",
        totalCompletedOrders:"/totalCompletedOrders",
        userCompletedOrders:"/userCompletedOrders/:locationId",
        incompleteOrders:"/incompleteOrders",
        eventsWithPackagesSales:"/eventsWithPackagesSales",
        userIncompleteOrders:"/userIncompleteOrders/:locationId",
        companies: '/companies',
        eventGroups: '/location/eventgroups',
        products: '/products',
        companyConfig:'/companyConfig/:companyId',
        locations: '/locations',
        compaign: '/:locationId/compaign',
        sendGridStats: '/statistics/:eventName/:campaignName/:campaignId/:startDate',
        intervals: '/:locationId/intervals',
        groupUser: '/groupUsers/:eventGroupId',
        eventPackages: '/:locationId/eventPackages',
        events: '/:companyId/events',
        calendar: '/calendar',
        users:'/users',
        userDetails: '/userDetails/:userId/:companyId',
        userSchdeuler:"/userScheduler/:userId/:companyId",
        rescheduleAppointment:"/rescheduleAppointment/:userId/:companyId/:appointmentId",
        rescheduleAppointmentBooking:"/rescheduleAppointmentBooking/:userId/:companyId/:appointmentId/:locationId",
        printOrders: '/orderFulFillment/:userId',
        presets: '/presets',
        export:'/export',
        booking:'/booking/:eventId',
        completeOrder:'/CompleteOrder/:stripeSessionId',
        downloads:'/downloads',
        notFound: '/404',
        help: '/support',
        manual: '/manual',
        domain: '/:DomainName',
        visualizations: '/:DomainName/visualizations',
        demand: '/:DomainName/visualizations/demand',
        dataExplorer: '/:DomainName/visualizations/data-explorer',
        scenarioViewer: '/:DomainName/visualizations/scenario-view/:ScenarioId',
        scenarios: '/:DomainName/visualizations/scenarios',
        staffing: '/:DomainName/visualizations/staffing',
        staffingEmployee: '/:DomainName/visualizations/staffing/employee',
        staffingWorkItem: '/:DomainName/visualizations/staffing/workitem',
        dashboard: '/:DomainName/dashboard',
        demandReport: '/:DomainName/reports/demand',
        staffingReport: '/:DomainName/reports/staffing',
        management: '/:DomainName/management',
        schedules: '/:DomainName/management/schedules',
        activities: '/:DomainName/management/activities',
        allocations: '/:DomainName/management/allocations',
        complexities:  '/:DomainName/management/complexities',
        organizations:  '/:DomainName/management/organizations',
        departments:  '/:DomainName/management/departments',
        roles:  '/:DomainName/management/roles',
        attributes:  '/:DomainName/management/attributes',
        algorithms:  '/:DomainName/management/algorithms',
        headcounts:  '/:DomainName/management/headcounts',
        actuals:  '/:DomainName/management/actuals',
        employees:  '/:DomainName/management/employees',
        assignments:  '/:DomainName/management/assignments',
        settings:  '/:DomainName/management/settings',
        generalSettings:  '/:DomainName/management/settings/general',
        advancedSettings:  '/:DomainName/management/settings/advanced',
        security:  '/:DomainName/management/settings/security',
        referenceTables: '/:DomainName/management/settings/referenceTables',
        pipelinesOld: '/:DomainName/management/settings/pipelines-old',
        pipelines: '/:DomainName/management/settings/pipelines',
        pipelineViewer: '/:DomainName/management/settings/pipeline-view/:ConfigurationId',
        sales: "/sales",
    },
    clientApi: {
        login:'authentication/login',
        forgetPassword:'user/forgotpassword',
        company: 'company', //added it for company signup
        companySignup: 'companysignup', //added it for company signup
        dashboardCount: 'company/dashboardcounts/:id',
        futureEventsDetails: 'company/dashboard/futureeventsdetails/:companyId',
        eventsWithPackagesSales: 'company/dashboard/eventsWithpackagesSales/:companyId',
        incompleteOrders: 'company/dashboard/userOrdersWithevents/:companyId/incomplete_orders',
        userIncompleteOrders: 'company/dashboard/userListWithevents/:companyId/:locationId/incomplete_orders',
        totalCompletedOrders: 'company/dashboard/userOrdersWithevents/:companyId/completed_orders',
        userCompletedOrders: 'company/dashboard/userListWithevents/:companyId/:locationId/completed_orders',
        noOrdersUsers: 'company/dashboard/userOrdersWithevents/:companyId/no_orders_users',
        userNoOrdersUsers: 'company/dashboard/userListWithevents/:companyId/:locationId/no_orders_users',
        user: 'user', //added it for company signup
        account: 'account',
        byId:':Entity/:id',
        domainEntity: ':Entity',
        exportwithEventsList: 'exportwitheventList',
        eventTypes:'location/eventtypes/:companyId',
        eventTypeCreate: 'location/eventtypes',
        compaignCUD: 'marketing/compaign',
        compaign: 'marketing/compaign/:locationId',
        compaignTypeCUD: 'marketing/compaigntype',
        compaignType: 'marketing/compaigntype/:locationId',
        sendGridStats:'marketing/compaign/stats/:campaignId/:start_date',
        childEntity: ':companyId/:Entity',
        events:'company/:companyId/:Entity',
        singleEvent:'event/:eventId',
        updateEventBooking:'event/OnlineBooking',
        updateEvent:'event',
        search:':Entity/search',
        intervals:':companyId/:locationId/:Entity',
        groupUsers:'location/eventgroups/group/users/:eventGroupId',
        eventPromotion:'event/promotion/:locationId',
        eventUsers:'location/eventgroups/eventusers/:locationId',
        assignGroupUsers:'location/eventgroups/group',
        unassignGroupUsers:'location/eventgroups/group/:eventGroupId',
        eventgroups:'location/eventgroups/group/:companyId',
        eventgroupsAssignUsers:'location/eventgroups/csvUsers',
        eventgroupsCreate:'location/eventgroups',
        eventPackages:':companyId/:locationId/:Entity',
        userDetails: 'user/:id',
        updateUser:'user',
        userScheduler:':Entity/:locationId',
        calenderMonth:'calendar',
        calenderTab:'calendar/calendarTab',
        calendar:'mobile/calendar',
        calendarday:'calendarday',
        userSessions: ':Entity/:userId/:companyId',
        userSessionImage: ':Entity/byUserSessionId/:id',
        appointments: 'user/:Entity/:userId/:companyId',
        printOrders: ":Entity/:companyId/:userId",
        orderRequeue: "order/requeue",
        prepaids: ':Entity/:userId/:companyId',
        presetsUpload: ':Entity/uploadPreset',
        translation: '%PUBLIC_URL%/i18n/:Lang.json',
        locationDates:'location/dates/:locationId',
        locationSlots:'location/slots/:locationId',
        locationSlotsV2:'location/slots_v2/:locationId',
        blocktimeslots:'blocktimeslots',
        deletetimeslots:'blocktimeslots/:slotId',
        blocktimeslotsMultiple:'blocktimeslots/multiple',
        eventOrder:'order/event',
        resetUser:'user/reset',
        changeDefaultCompany:'user/changeDefaultCompany',
        assignCompanies:'user/assign',
        products:'product/:companyId',
        eventGroups:'product/:companyId',
        product:'product/:productId/:companyId',
        companyProduct:'companyProduct',
        addUpdateProduct:'product',
        shareSession:'usersession/sharesession',
        unlockSession:'usersession/unlock',
        textSession:'usersession/sendtext',
        sendApp:'sendapp',
        saveNotes:'appointment/savenotes',
        downloadAppointment:'appointment/printschedule?LocationId=:LocationId',
        patchAppointment:'appointment/patch',
        rescheduleAppointment:"appointment/reschedule",
        appointmentsByLocation:'appointment/bylocationId',
        openOrders:'order/openordersforfullfilment/:companyId',
        // completedOrders:'order/completedOrders/:companyId',
        completedOrders:'order/v2/completedOrders/:companyId',
        activateAppointment:'appointment/activate/:stripeSessionId',
        userSearch:'user/search',
        help: 'docs/:Lang/:Name?cid=:CID&meta=:IsMeta',
        appointmentPresale:"appointment/presales",

        domainEntityImport: 'data/:DomainId/:Entity/import',
        domainEntityFile: 'file/:DomainId/:Entity/:Id',
        domainEntityClear: 'data/:DomainId/:Entity/clear',
        projections: 'projections/:DomainId/:ScheduleId',
        auditEntity: 'audit/:DomainId/:Entity/:EntityId',
        notify: 'notify',
        proxy: 'proxy'
    },
    admin: {
        home: '/administration',
        tenants: '/administration/tenants',
        domains: '/administration/domains',
        // users: '/administration/users',
    },
    adminApi: {
        adminEntity: 'management/:TenantId/:Entity',
        adminEntityImport: 'management/:TenantId/:Entity/import',
        switchTenant: 'management/*/tenants/switch',
        uptimeReport: 'management/*/uptime'
    },
    compose: function(url, params, qs) {
        var output = url.replace('%PUBLIC_URL%', process.env.PUBLIC_URL);
        if (params){
            Object.keys(params).forEach(function (key, index) {
                let value = params[key];
                output = output.replace(':' + key, value);
            });
        }
        if (!qs) return output;    
        if (typeof qs === 'string'){
            return output + '?' + qs;
        }else{
            return output + '?' + Object.keys(qs).map(key => key + '=' + qs[key]).join('&');
        }
    },
    parseQuery(queryString) {
        let toParse = queryString || window.location.search.slice(1);
        let query = {};
        let pairs = toParse.split('&');
        for (let i = 0; i < pairs.length; i++) {
            let pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    },
    baseUrl(){
        return process.env.PUBLIC_URL || 'http://20.237.233.211/';
    }
}