/* global EM */
import React, { Component } from "react";
import EntityTable from "./EntityTables/EntityTable";
import _ from "underscore";
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PanelModal from '../components/PanelModal';

class PrintOrdersDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};    
  }

  getTransformOrderLines() {
    return _.map(this.props.order.printOrderLineItems, (item) => {
      return {
        name: item.product?.name,
        quantity: item.quantity,
        imagepath: item.userSessionImage.thumbnailUrl ? item.userSessionImage.thumbnailUrl : (item.userSessionImage.originalImageUrl ? item.userSessionImage.originalImageUrl : ''),
        price: item.product.price
      };
    });
  }

  getColumns() {
    return [
      {
        dataField: "name",
        text: 'Product name',
        editable: false,
      },
      {
        dataField: "imagepath",
        text: 'Image',
        editable: false,
        formatter: (cell, row) => {
          return (<img className="orderImagethumb" src={cell} alt='' />)
        }
      },
      {
        dataField: "quantity",
        text: 'Quantity',
        editable: false
      },
      {
        dataField: "price",
        text: 'Price',
        creatable: false,
        editable: false,
        formatter: (cell, row) => {
          return `$${cell ? cell : ''}`
        }
      }
    ];
  }

  render() {
    let columns = this.getColumns();
    let data = this.getTransformOrderLines();;

    return (
      <div key="contents" className="page container-fluid mt-4">
        <PanelModal isOpen={this.props.show}>
          <ModalHeader>Order Details</ModalHeader>
          <ModalBody>
            <EntityTable
              pageTitle={'Order Items'}
              entity={EM.printOrderLineItems}
              data={data}
              columns={columns}
              className="table-overflow-container-auto"
              collapsible={false}
              collapsed={false}
              hideImportExport={true}
              hideAudit={true}
              hideAddRecord={true}
              hideDelete={true}
              hideSearch={true}
              hideFilters={true}
            />
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.props.onClose}>Close</button>
          </ModalFooter>
        </PanelModal>

      </div>
    );
  }
}

export default PrintOrdersDetails;
