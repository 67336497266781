/* global EM */
import React, { Component } from "react";
import { Button } from "reactstrap";
import Spinner from "../Spinner";

export default class PaymentDetails extends Component {
  render() {
    if (EM.allLoaded(EM.event)) {
      return (
        <div className="step step1">
          <div className="border border-primary border-2 text-dark text-uppercase p-3 d-flex w-100">
            Stripe Payment
          </div>
          <p className="my-4">Flashop proudly uses Stripe for all if its electronic commerce transactions. Clicking next will take you to the Stripe site to complete your transaction.</p>
          <div>
          <Button className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" onClick={() => { this.props.next({}, this.props.step) }}>Next</Button>
            <Button className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" onClick={() => { this.props.prev({}, this.props.step) }}>Back</Button>
          </div>
        </div>
      );
    }
    return (
      <div className={this.props.className}>
        <Spinner text={EM.t("util.loading")} classes="small" />
      </div>
    );
  }
}
