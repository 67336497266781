/* global EM */
import React, { Component } from "react";
import { Validators } from "../util/EntityValidators";
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import { Type } from "react-bootstrap-table2-editor";
import {FaTrashAlt } from "react-icons/fa";
import Dates from '../util/Dates'
import Spinner from '../components/Spinner'
class EventPackages extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.eventpackage;
    let user = EM.account.get();
    let company = _.findWhere(user.userCompanies, { defaultCompany: true });
    let locationId = this.props.match.params.locationId;
    this.state = {
      data:false,  
      parentRecordDetail: { companyId: company.companyId, locationId },
      locationId: locationId,
      location:{name:''},
      isSuperAdmin:EM.isSuperAdmin(),
    };   
  }
  getLocation(){  
    let location = _.findWhere(EM.eventTypes.get(),{eventTypeId:parseInt(this.props.match.params.locationId)});
    if(!location)
    {
      let user = EM.account.get();
      let company = _.findWhere(user.userCompanies, { defaultCompany: true });
      EM.eventTypes.loadEntityByParent({companyId:company.companyId}).then(()=>{
        let location = _.findWhere(EM.eventTypes.get(),{eventTypeId:parseInt(this.props.match.params.locationId)});
        this.setState({location:location});
      });
    }
    else{
      this.setState({location});
    }

  }
  componentDidMount(){    
    this.setState({companyName:EM.getDefaultCompany().companyName}) 
    const self = this
    this.getLocation()
    this.entity.loadEntityByParent(this.state.parentRecordDetail,false).then((data)=>{
        if(EM.allLoaded(this.entity)){   
                    
          data = self.entity.get(true);
          self.setState({data:data})
          console.log(data)
        }
      })
  }


  getTitle(){
    return `Studio Packages for ${this.state.location.name}`;
  }

  render() {
    let columns = [];
    let data = [];    
    
    if (this.state.data) {
      data = this.entity.get(true);
      const self =this
      columns = [
        {
            dataField: "eventName",
            text: this.entity.columns("eventName"),
            editable:true,
            filterOptions: {
              text: true,
            },
            validators: [ Validators.required],
          },
        {
          dataField: "createdDate",
          text: this.entity.columns("createdDate"),
          creatable: false,
          editable:false,
          filterOptions: {
            text: true,
          },
          formatter: (cell, row) => {
            return (Dates.isoToDateTime(cell, "YYYY-MM-DD hh:mm A"));
          }
        },
        {
          dataField: "allDigital",
          text:"Include Digital",
          editable:true,
          asActive:true,
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "price",
          text: this.entity.columns("price"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        {
          dataField: "sheetCount",
          text: this.entity.columns("sheetCount"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        {
          dataField: "imageCount",
          text: this.entity.columns("imageCount"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        {
          dataField: "largePrint1114",
          text: this.entity.columns("largePrint1114"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        {
          dataField: "largePrint1620",
          text: this.entity.columns("largePrint1620"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        }
      ];
    }
    if(!this.state.data){      
        return <Spinner text={EM.t('util.loading')} classes="small"/>
      }
    return (
      <div key="contents" className="page container-fluid">
        <EntityTable
          entity={this.entity}          
          pageTitle={`${this.getTitle()} ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`}
          data={data}
          columns={columns}
          className="table-overflow-container"
          hideImportExport={true}
          hideAudit={true}
          parentRecordDetail={this.state.parentRecordDetail}
          onClose={() => {
            this.data = this.entity.get();
          }}
        />
      </div>
    );
  }
}

export default EventPackages;
