/* global EM */
import React, { Component } from "react";
import Spinner from "../Spinner";

export default class DateSelection extends Component {

  constructor(props) {
    super(props);    
    this.state = {
      event: EM.event.get()
    };
  }

  slotSelected(slot) {
    // slot.key, slot.enabled, slot.strdt, 0, 0
    this.props.next(slot, this.props.step);
  }
  render() {
    const { disableEvent } = this.props    
    if ((disableEvent && EM.allLoaded(EM.availableDays)) || EM.allLoaded(EM.event, EM.availableDays)) {
      return (
        <div className="step step3">
          <div className="input-group my-3 flex-column container">
            <div className="row">
              <div className="mr-auto">
                <label className="mb-2">Please Select a Date</label>
              </div>
              <div className="ml-auto" hidden={(this.state.event && this.state.event.showZipSearch === false) || disableEvent}>
                <button onClick={() => this.props.prev({}, this.props.step)} className="btn btn-primary buttonwidth btnSearch">Search</button>
              </div>
            </div>
          </div>
          <div className="overflow-auto container">
            {this.props.dates.map((slot,index) => (
              <div className="border p-3 d-flex gap-2 align-items-center row" key={index}>
                <div className="mr-auto"><h6 className="mb-0">{slot.title}</h6></div>
                <div className="ml-auto">
                  {
                    (slot.slotsAvailable === false)
                      ? <div className="booked py-2 px-3 text-nowrap ms-auto fw-bolder text-uppercase">Fully Booked!</div>
                      : <div onClick={() => {
                        if (slot.slotsAvailable === true)
                          this.slotSelected(slot)
                      }} className="available py-2 px-3 text-nowrap ms-auto fw-bolder text-uppercase" >Available</div>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div className={this.props.className}>
        <Spinner text={EM.t("util.loading")} classes="small" />
      </div>
    );
  }
}
