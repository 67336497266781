/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import Routes from '../app/Routes';
import Papa from 'papaparse';
const addColumnNames = (data, columnNames) => {
  const dataWithHeaders = data.map((row, index) => {
    const rowData = {};
    columnNames.forEach((columnName, columnIndex) => {
      if(row[columnIndex]!==undefined){
        rowData[columnName] = row[columnIndex];
      }else{
        rowData[columnName] = ""
      }
    });
    return rowData;
  });
  return dataWithHeaders;
};
class EventGroups extends Component {
  constructor(props) {
    super(props);        
    this.entity = EM.eventGroups;
    this.state = {
      pageLoad:true,
      isSuperAdmin:EM.isSuperAdmin(),
      defaultCompany:EM.getDefaultCompany(),
      parentRecordDetail:{companyId:EM.getDefaultCompany().companyId},
      uploadEventGroup:null,
      createModalOpen:null
    };       
    this.hiddenFileInput = React.createRef()
    
    this.openUpload = this.openUpload.bind(this);
    this.parseCSV = this.parseCSV.bind(this);
    this.updateCreateModel = this.updateCreateModel.bind(this);
    this.assignUsersToGroup = this.assignUsersToGroup.bind(this);
    this.createNewGroup = this.createNewGroup.bind(this);
  }
  async componentDidMount(){   
    await EM.locations.loadEntityByParent(this.state.parentRecordDetail,true);
    await this.entity.loadEntityByParent(this.state.parentRecordDetail,true)    
    
  }
  reloadEventGroups(){
    this.entity.loadEntityByParent(this.state.parentRecordDetail,true)
  }
  toUsers(eventGroupId){
    this.props.history.push(Routes.compose(Routes.client.groupUser,{eventGroupId}));    
  }
  openUpload(uploadEventGroup){
    this.setState({uploadEventGroup})
    this.hiddenFileInput.current.click();
  }
  handleAssignUsersFile = event => {
    const file = event.target.files[0];
    const allowedExtensions = /(\.csv)$/i;

    if (!allowedExtensions.test(file.name)) {
      alert('Please upload a CSV file.');
      return;
    }
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    const self=  this
    reader.onload = function (e) {
      const csvString = e.target.result;
      self.parseCSV(csvString);
    };

    reader.readAsText(fileUploaded);
  }
  async assignUsersToGroup(usersList){   
    const {uploadEventGroup} = this.state
    var request = {
      eventGroupName: uploadEventGroup.name,
      eventId: uploadEventGroup.locationId,
      usersList: usersList,
      eventGroupId: uploadEventGroup.eventGroupId
    }
    await this.entity.executePut(request,null,this.entity.entityTypeDef.assignUserUrl, {},false);
    this.reloadEventGroups()
  }
  parseCSV(csvString) {
    
    Papa.parse(csvString, { header: false,   
         
      complete: (results) => {  
        let parsedData = { data: [] };
        if (results && results.data && results.data.length > 0) {
          parsedData.data = results.data.slice(1).filter(row => {
            return row.some(cell => cell.trim() !== ''); // Filter out rows with at least one non-empty cell
          });
        }    
        const dataWithHeaders = addColumnNames(parsedData.data, ['phone','firstName','lastName']);        
        this.assignUsersToGroup(dataWithHeaders)
      } 
    });
  }
  updateCreateModel = (value) => {
    this.setState({ createModalOpen: value });
  };
  async getcsvData(file){
    if(!file) return null;
    const allowedExtensions = /(\.csv)$/i;

    if (!allowedExtensions.test(file.name)) {
      alert('Please upload a CSV file.');
      return false;
    }
    return new Promise((resolve, reject) => {
      const fileUploaded = file;
      const reader = new FileReader();      
      reader.onload = async function (e) {
        const csvString = e.target.result;
        await Papa.parse(csvString, {
          header: false,      
          complete: async (results) => {   
            let parsedData = { data: [] };
            if (results && results.data && results.data.length > 0) {
              parsedData.data = results.data.slice(1).filter(row => {
                return row.some(cell => cell.trim() !== ''); // Filter out rows with at least one non-empty cell
              });
            }     
            const dataWithHeaders = addColumnNames(parsedData.data, ['phone','firstName','lastName']);        
            resolve( dataWithHeaders)            
          } 
        });
      };
      
      reader.readAsText(fileUploaded);
    })
  }
  async createNewGroup(inputs){
    const response = await this.getcsvData(inputs.uploadAssignedUsers)
    
    if(response!==false){
      const body = {
        eventGroupName: inputs.name,
        eventId: inputs.locationId,
        usersList:response
      }
      await this.entity.executePost(body,null,this.entity.entityTypeDef.assignUserUrl, {},false);
      this.reloadEventGroups()  
      this.updateCreateModel(false)
      const self = this
      setTimeout(() => {
        self.updateCreateModel(null)
      }, 100);
    }
  }
  helperContent(){
    return <a href="https://flashopmobileweb.blob.core.windows.net/install/test_csv_flashop_group_upload.csv" target="_blank" rel="noreferrer">Download a Sample CSV</a>
  }
  render() {
    let columns = [];
    let data = [];
    let self=this
    if (EM.allLoaded(EM.eventGroups,EM.locations)) {
      data = this.entity.get();
      columns = [          
        {
          dataField: "locationId",
          text: "Event",
          // hideInTable:true,
          validators: [ Validators.required],
          fromEntity:EM.locations,
          creatable:true
        },  
        {
          dataField: "name",
          text: "Name",
          validators: [ Validators.required,Validators.length100],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "uploadAssignedUsers",
          text: "Upload Csv to assign Users",
          hideInTable:true,
          // validators: [ Validators.required],
          asFile:true,
          helperContent:this.helperContent()
        }, 
        {
          dataField: "numberOfUsers",
          text: "Users",
          editable:false,
          creatable:false
        },
        // {
        //   dataField: "isActive",
        //   text: "Active",
        //   asActive: true,
        //   creatable:false
        // },
        {
          dataField: "Users",
          text:"Users",          
          creatable:false,
          editable:false,
          formatter: (cell,row) => {            
            return (
            <>
            <button className="btn btn-primary" title="List Users in Group" onClick={(event)=>{self.toUsers(row.eventGroupId);}}><i className="fas fa-users"></i></button>

            <button className="btn btn-primary" title="Upload CSV to Assign users" onClick={(event)=>{self.openUpload(row);}}><i className="fas fa-upload"></i></button>            
             </> 
            )
        },  
        },
      ];
    }
    
    return (
      <div key="contents" className="page container-fluid">
        <EntityTable
        createModalOpen={this.state.createModalOpen}
        onCreate={(data)=>{
          
          
          this.createNewGroup(data)
          
        }}
        // onDelete={()=>alert('das')}
          pageTitle={`Groups ${(this.state.defaultCompany.companyName && this.state.isSuperAdmin)?`(${this.state.defaultCompany.companyName})`:''}`}
          entity={this.entity}
          data={data}
          columns={columns}
          className="table-overflow-container"
          hideImportExport={true}
          hideAudit ={true}
          onClose={() => {
            
            this.data = this.entity.get();
          }}
        />
        <input
        type="file"
        ref={this.hiddenFileInput}
        onChange={this.handleAssignUsersFile}
        style={{display: 'none'}}
      />
      </div>
    );
  }
}

export default EventGroups;
