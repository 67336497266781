import * as types from '../actionTypes';
import _ from 'underscore';

export default function error(state = null, action) {
    switch (action.type) {
        case types.GLOBAL_ERROR:
            return action.data;
        case types.LOCAL_ERROR:
            return Object.assign({}, state, { [action.eid]: action.data })    
        case types.CLEAR_LOCAL_ERROR:
            return _.omit(state, action.eid);
        default:
            return state;
    }
}