/* global EM */
import React, { Component } from "react";
import Dates from '../util/Dates';
import _ from 'underscore';
import Routes from '../app/Routes'

class Appointments extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.appointments;
    this.state = {
      parentRecordDetail: { userId: this.props.data.id, companyId: this.props.data.companyId },
      appointmentsData: [],
      collapsed:true,
      notes:[]
    };

  }
  componentDidMount() {
    var self = this;
    self.entity.loadEntityByParent(this.state.parentRecordDetail,true).then(() => {
      self.setState({ appointmentsData: self.entity.get() });
    });
  }
  
  confirm(appointment) {      
    let self = this;
    EM.appointments.executePut({ appointmentId: appointment.appointmentId, propertyName: "Confirmed", confirmed: true }, null, Routes.clientApi.patchAppointment, {},true)
        .then(() => {self.setState({appointmentsData:self.entity.get()});});
}


  patchCheckIn(checkedIn,appointment) {
    let self = this;
    EM.appointments.executePut({ appointmentId: appointment.appointmentId, propertyName: "CheckedIn", checkedIn: checkedIn }, 
    null, Routes.clientApi.patchAppointment, {},true)
        .then((result) => {
          self.setState({appointmentsData:self.entity.get()});
        });
  }

  cancel(appointment) {
    let self = this;
    EM.appointments.executePut({ appointmentId: appointment.appointmentId, propertyName: "Cancelled", cancelled: true }, null, Routes.clientApi.patchAppointment, {},true)
    .then((result) => {
      self.setState({appointmentsData:self.entity.get()});
    });  
  }

  saveNotes(appointmentId){
    var notes = _.findWhere(this.state.notes, {appointmentId:appointmentId});
    if(notes)
      EM.appointments.executePut({appointmentId:appointmentId, notes:notes.notes},null,Routes.clientApi.saveNotes, {},true)
      .then((result)=>{
        // handle change if need be
      });
  }

  redirectToReschedule(apt) {
    let url = Routes.compose(Routes.client.rescheduleAppointment, { userId: this.state.parentRecordDetail.userId, companyId: this.state.parentRecordDetail.companyId, appointmentId: apt.appointmentId });
    this.props.history.push(url);
  }

  notesChange(event,appointmentID){
    var notes = _.reject([...this.state.notes],(note)=>{
      return note.appointmentId === appointmentID;
    });
    notes.push({appointmentId:appointmentID, notes:event.target.value});
    this.setState({notes});
  }

  render() {
    let self = this;
    return (
      <div key="contents" className="page container-fluid mt-4">
        <div className="row p-1 mb-2 pt-2  border-bottom" style={{ backgroundColor: "#f9f9f9" }} onClick={()=>{this.setState({collapsed:!this.state.collapsed})}}>
          <h4 className="ml-2">Appointments { this.state.collapsed ? <i class="fa-solid fa-caret-down"></i> : <i class="fa-solid fa-caret-up"></i>}</h4>
        </div>
        <div hidden={this.state.collapsed}>
        {_.filter(self.state.appointmentsData,(appointment)=>appointment.canceled!==true).map((appointment, index) => {
          let slot = Dates.fromISO(appointment.slot);          
          return <div key={index} style={{ backgroundColor: '#F5F5F5', padding: '5px 30px' }} className="border-top border-bottom mb-1">
            <div className="row">
              <div className="col">
                <div className="locationName">
                  <label><b>{appointment.location?.name}</b></label>
                </div>
                <div>

                  <label className="slot">{Dates.toDateTime(slot)}</label>
                  <label className="event"><b>&nbsp;&nbsp;{appointment.event !== null ? appointment.event.description : ""}</b></label>
                </div>
              </div>

              {(() => {
                if (appointment.checkedIn) {
                  return <div className="col p-3" >
                    <button className="btn btn-primary btn-sm m-2" title="WalkOut" onClick={() => this.patchCheckIn(false,appointment)} >
                      WalkOut</button>
                  </div>
                }
                else {
                  return (<div className="col p-3">
                    <button className="btn btn-danger btn-sm m-2" title="Reschedule" onClick={() => { self.props.redirectToReschedule(appointment) }}>
                      Reschedule</button>

                    <button className="btn btn-success btn-sm m-2" title="Checkin" onClick={() => this.patchCheckIn(true,appointment)}>
                      Checkin
                    </button>
                    {(() => {                      
                      if (!appointment.confirmed)
                        return (<button className="btn btn-success btn-sm m-2" title="Confirmed" onClick={() => { self.confirm(appointment) }}>
                          Confirmed
                        </button>)
                    })()}
                    {
                      EM.isAdmin() || EM.isSuperAdmin() ? 
                    <button className="btn btn-danger btn-sm m-2" title="Cancel" onClick={() => { self.cancel(appointment) }}>
                      Cancel
                    </button> : null
                    }
                  </div>)
                }
              })()}
            </div>
            <div className="row" style={{ padding: '5px 10px' }}>
              <label><b>Notes:</b></label>
            </div>
            <div className="row" style={{ padding: '5px 10px' }}>
              <textarea readOnly={true} cols={40} rows={5} className="form-control" defaultValue={appointment.notes} onChange={(event)=>{this.notesChange(event,appointment.appointmentId)}}></textarea>
            </div>
            <div className="row" style={{ padding: '5px 10px' }}>
              <label>Seller: {appointment.sales?appointment.sales:'Online'}</label>
            </div>
            {/* <div className="row" style={{ padding: '5px 5px' }}>
              <button className="btn btn-primary btn-sm m-2" title="Save Notes" onClick={() => { self.saveNotes(appointment.appointmentId) }}>
                Save Notes
              </button>
            </div> */}
          </div>
        })}
        </div>
      </div>
    );
  }
}

export default Appointments;
