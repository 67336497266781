/* global EM */
import React from "react";
import { FormGroup, Label } from "reactstrap";
import Select from 'react-select';
import { Button } from "reactstrap";
import DateSelection from "../components/BookingWizard/DateSelection";
import TimeSelection from "../components/BookingWizard/TimeSelection";
import InputMask from 'react-input-mask';
import _ from 'underscore';
import Dates from '../util/Dates';
import Routes from "../app/Routes";

class Sales extends React.Component {
  stateData () {
    if(this.props.calendar){
      console.log(this.props)
      return {
        locations: [],
        userFirstName: '',
        userLastName: '',
        userPhone: '',
        userEmail: '',
        locationId: this.props.calendar.locationId,
        locationAddress: '',
        locationobj: null,
        dates: [],
        selectedDateSlot: {strdt:this.props.calendar.date},
        slots: [],
        selectedTimeSlot: null,
        isPaid: null,
        isCreated: false,
        paymentObj: null,

        selectedStep:  {
          name: 'contactInformation',
          visible: true,
          data: null,
          done: false,
          complete: () => { return {...this.state.steps.contactInformation, visible: false, done: true} },
          next: async() => { 
            await this.loadSlots()
            return  {...this.state.steps.timeDetails, visible: true}
          },
          incomplete: () => {}
        },
        steps: {
          locationsDetails: {
            name: 'locationsDetails',
            visible: false,
            data: null,
            done: true,
            complete: () => { return  {...this.state.steps.dateDetails, visible: false, done: true} },
            next: () => { return  {...this.state.steps.timeDetails, visible: true} },
          },
          contactInformation: {
            name: 'contactInformation',
            visible: true,
            data: null,
            done: false,
            complete: () => { return {...this.state.steps.contactInformation, visible: false, done: true} },
          next: async() => { 
            await this.loadSlots()
            return  {...this.state.steps.timeDetails, visible: true}
          },
            incomplete: () => { return  {...this.state.steps.contactInformation, visible: false, done: false} },
          },
          dateDetails: {
            name: 'dateDetails',
            visible: false,
            data: null,
            done: true,
            complete: () => { return  {...this.state.steps.dateDetails, visible: false, done: true} },
            next: () => { return  {...this.state.steps.timeDetails, visible: true} },
            prev: () => { return {...this.state.steps.contactInformation, visible: true} },
            incomplete: () => { return  {...this.state.steps.dateDetails, visible: false, done: false} },
          },
          timeDetails: {
            name: 'timeDetails',
            visible: false,
            data: null,
            done: false,
            complete: () => { return  {...this.state.steps.timeDetails, visible: false, done: true}},
            next: () => { return  {...this.state.steps.paymentDetails, visible: true} },
            prev: () => { return {...this.state.steps.contactInformation, visible: true} },
            incomplete: () => { return  {...this.state.steps.timeDetails, visible: false, done: false}},
          },
          paymentDetails: {
            name: 'paymentDetails',
            visible: false,
            data: null,
            done: false,
            complete: () => { return  {...this.state.steps.paymentDetails, visible: false, done: true} },
            prev: () => { return {...this.state.steps.timeDetails, visible: true} },
            incomplete: () => { return  {...this.state.steps.paymentDetails, visible: false, done: false} },
          },
        },
      }
    }
    else {
      return {
        locations: [],
        userFirstName: '',
        userLastName: '',
        userPhone: '',
        userEmail: '',
        locationId: null,
        locationAddress: '',
        locationobj: null,
        dates: [],
        selectedDateSlot: null,
        slots: [],
        selectedTimeSlot: null,
        isPaid: null,
        isCreated: false,
        paymentObj: null,

        selectedStep:  {
          name: 'locationsDetails',
          visible: true,
          data: null,
          done: false,
          complete: () => { return {...this.state.steps.locationsDetails, visible: false, done: true} },
          next: () => { return  {...this.state.steps.contactInformation, visible: true}},
          incomplete: () => {}
        },
        steps: {
          locationsDetails: {
            name: 'locationsDetails',
            visible: true,
            data: null,
            done: false,
            complete: () => { return{...this.state.steps.locationsDetails, visible: false, done: true} },
            next: () => { return {...this.state.steps.contactInformation, visible: true} },
          },
          contactInformation: {
            name: 'contactInformation',
            visible: false,
            data: null,
            done: false,
            complete: () => { return  {...this.state.steps.contactInformation, visible: false, done: true} },
            next: () => { return {...this.state.steps.dateDetails, visible: true} },
            prev: () => { return {...this.state.steps.locationsDetails, visible: true} },
            incomplete: () => { return  {...this.state.steps.contactInformation, visible: false, done: false} },
          },
          dateDetails: {
            name: 'dateDetails',
            visible: false,
            data: null,
            done: false,
            complete: () => { return  {...this.state.steps.dateDetails, visible: false, done: true} },
            next: () => { return  {...this.state.steps.timeDetails, visible: true} },
            prev: () => { return {...this.state.steps.contactInformation, visible: true} },
            incomplete: () => { return  {...this.state.steps.dateDetails, visible: false, done: false} },
          },
          timeDetails: {
            name: 'timeDetails',
            visible: false,
            data: null,
            done: false,
            complete: () => { return  {...this.state.steps.timeDetails, visible: false, done: true}},
            next: () => { return  {...this.state.steps.paymentDetails, visible: true} },
            prev: () => { return {...this.state.steps.dateDetails, visible: true} },
            incomplete: () => { return  {...this.state.steps.timeDetails, visible: false, done: false}},
          },
          paymentDetails: {
            name: 'paymentDetails',
            visible: false,
            data: null,
            done: false,
            complete: () => { return  {...this.state.steps.paymentDetails, visible: false, done: true} },
            prev: () => { return {...this.state.steps.timeDetails, visible: true} },
            incomplete: () => { return  {...this.state.steps.paymentDetails, visible: false, done: false} },
          },
        },
      }
    }
  }

  constructor(props) {
    super(props);
    let staticState = this.stateData()
    this.state = {
      ...staticState
    };
  }

  reInitialize () {
    let staticState = this.stateData()
    this.setState({
      ...staticState
    }, () => {
      console.log('STATE ', this.state)
      this.getLocations()
    })
  }

  getLocations () {
    let user = EM.account.get();
    let company = _.findWhere(user.userCompanies, { defaultCompany: true });
    EM.locations.loadEntityByParent({companyId: company.companyId}, true).then(()=>{
      this.setState({locations: EM.locations.asOptionList(null, true)})
    });
  }

  componentDidMount() {
    if(!this.props.calendar)
    this.getLocations()
  }

  loadLocationDates() {
    let params = { locationId: this.state.locationId };
    EM.availableDays.searchEntity(params, {}, true).then(() => {
      this.setState({ dates: EM.availableDays.get() });
    });
  }

  getSelectedSlot(slot) {
    this.setState({selectedDateSlot: slot}, () => {
      this.next();
      this.loadSlots();
    })
  }

  loadSlots() {
    let date = this.state.selectedDateSlot;
    let self = this;
    let locationId = this.state.locationId;
    let params = { locationId: locationId, date: date.strdt };
    let qs = { date: date.strdt };
    console.log('date',date)
    EM.availableSlots.searchEntity(params, qs, true).then(() => {
      self.setState({ slots: EM.availableSlots.get() });
    });
  }

  next = async() => {
    try {
      
      let complete = this.state.selectedStep.complete()
      let next = await this.state.selectedStep.next()
      this.setState({
        steps : {...this.state.steps, [complete.name]: complete, [next.name]: next },
        selectedStep: next
      })
    } catch(error){
      console.log('error in next ', error)
    }
  }

  previous = () => {
    try {
      let incomplete = this.state.selectedStep.incomplete()
      let prev = this.state.selectedStep.prev()
      this.setState({
        steps : {...this.state.steps, [incomplete.name]: incomplete, [prev.name]: prev },
        selectedStep: prev
      })
    } catch(error){
      console.log('error in previous ', error)
    }
  }

  emailValidator(value) {
    if(value === '') return false;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(value)){
      return true;
    }   
    return false
  }

  schedule() {
    try {
      if (!this.validate()) {
          alert('Please fill in the required information.')
          return;
      }
  
      const { userFirstName, userLastName, userPhone, locationId, userEmail, isPaid } = this.state;
  
      let user = EM.account.get();
      
      const { selectedDateSlot, selectedTimeSlot } = this.state 
      let date = new Date(selectedDateSlot.strdt + ' ' + selectedTimeSlot);
      // let date = Dates.fromStr(selectedDateSlot.strdt + ' ' + selectedTimeSlot,`L/d/yyyy t`);
      // if(!date) date = Dates.fromStr(selectedDateSlot.strdt + ' ' + selectedTimeSlot,`L/d/yyyy HH:MM`);
      // console.log(Dates.fromStr(selectedDateSlot.strdt + ' ' + selectedTimeSlot,`L/d/yyyy HH:MM`));
  
      let req = {
        firstName: userFirstName,
        lastName: userLastName,
        phoneNumber: userPhone,
        backupPhoneNumber: userPhone,
        email: userEmail,
        notes: "",
        locationId: locationId,
        paid: isPaid,
        sales: `${user.firstName} ${user.lastName}`,
        salesId: `${user.userId}`,
        checkedIn: false,
        createdBy: user.userId,
        creationSourceId: 1,
        // slotDateTime: date.toISO().replace(':00.000Z','')
        slotDateTime: date.toLocaleString()
      }
  
      console.log('JSON ', req)
      EM.appointments.executePost(req, null, Routes.clientApi.appointmentPresale, {}, false)
        .then(() => {
          console.log('APPOINTMENT CREATED SUCCESSFULLY !')
            this.setState({isCreated: true})
        })
    } catch(error) {
      console.log('ERROR in Creating Appointment ', error)
    }
  }

  validate () {
    const { selectedDateSlot, selectedTimeSlot, userFirstName, userLastName, userPhone, isPaid } = this.state;
    return selectedDateSlot && selectedTimeSlot && userFirstName && userLastName && userPhone && isPaid !== null;
  }

  userInfoValidate () {
    const { userFirstName, userLastName, userPhone, userEmail } = this.state;
    if(userEmail && userEmail.length > 0) {
      return userFirstName && userLastName && userPhone && !this.emailValidator(userEmail)
    }
    return userFirstName && userLastName && userPhone
  }

  Header = () => {
    const { steps } = this.state;
    return (
      <React.Fragment>
        <ul className="progress-bar px-4 px-sm-5 px-md-3 d-flex align-items-center flex-row position-relative">
          <li className={"progress-bar__dot " + ((steps.locationsDetails.visible || steps.locationsDetails.done)  ? "full" : "")}>
              <span className="stepContent">Location</span>
          </li>
          <li className="progress-bar__connector"></li>
          <li className={"progress-bar__dot " + ((steps.contactInformation.visible || steps.contactInformation.done) ? "full" : "")}>
              <span className="stepContent">Contact Info</span>
          </li>
          <li className="progress-bar__connector"></li>
          <li className={"progress-bar__dot " + ((steps.dateDetails.visible || steps.dateDetails.done) ? "full" : "")}>
              <span className="stepContent">Date</span>
          </li>
          <li className="progress-bar__connector"></li>
          <li className={"progress-bar__dot " + ((steps.timeDetails.visible || steps.timeDetails.done) ? "full" : "")}>
              <span className="stepContent">Time</span>
          </li>
          <li className="progress-bar__connector"></li>
          <li className={"progress-bar__dot " + ((steps.paymentDetails.visible || steps.paymentDetails.done) ? "full" : "")}>
              <span className="stepContent">Payment</span>
          </li> 
        </ul>
      </React.Fragment>
    )
  }

  render() {
    const { steps } = this.state;
    return (
      <main className="heightFull">
        <div className="row heightFull">
          <div className="col-lg-10 mx-auto px-0">
            <div className="col-lg-9 offset-lg-1 pt-5 mt-3">
              <div className="form mt-3 mt-lg-0">

                  <this.Header />
               

                    {/* Form Steps */}
                    <div className="col-lg-10 mx-auto px-0 pl-3 pr-3">
                      {this.state.locationAddress && this.state.locationAddress.length > 0 ? (
                        <div className="step step2">
                          <div className="mr-auto">
                            <label className="mb-2 text-center">Selected Location</label>
                            <p className="text-center">
                              <i>
                              {this.state.locationAddress}

                              </i>
                              </p>
                          </div>
                        </div>
                      ) : null}
          
                        <div hidden={!steps.locationsDetails.visible}>
                            <div className="step step2">
                                <FormGroup className="col-  mt-4">
                                <Label>Please Select Location</Label>
                                <Select
                                    className="single-select"
                                    classNamePrefix="single-select"
                                    isSearchable={true}
                                    value={this.state.locationobj}
                                    // isMulti={false}
                                    isClearable={true}
                                    options={this.state.locations}
                                    placeholder={'Select Location'}
                                    onChange={({value, label}) => {   
                                      this.setState({
                                        locationId: value,
                                        locationAddress: label,
                                        locationobj: {value, label}
                                      }, () => {this.next() })                
                                    }}
                                />
                                </FormGroup>
                            </div>
                            <Button className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" onClick={() => this.reInitialize()}>Next</Button>
                        </div>

                        <div hidden={!steps.contactInformation.visible}>
                            <div className="step step2">
                              <div className="mr-auto">
                                <label className="mb-2">Please Provide Information</label>
                              </div>
                                <FormGroup className="col-  mt-4">
                                    <div className="step step5">
                                            <form id="user-info-form">
                                        <div className="mt-3">
                                            <div className="mb-3">

                                            <span className="form-label fw-bold d-block mb-1">First Name*</span>
                                            <input placeholder="first name" autoFocus className="form-control m-0 border border-2 border-primary p-2" value={this.state.userFirstName} onChange={(event) => {
                                              this.setState({userFirstName: event.target.value})
                                            }}></input>
                                            </div>
                                            <div className="mb-3">
                                            <span className="form-label fw-bold d-block mb-1">Last Name*</span>
                                            <input placeholder="last name" className="form-control m-0 border border-2 border-primary p-2" value={this.state.userLastName} onChange={(event) => {
                                                 this.setState({userLastName: event.target.value})
                                            }}></input>
                                            </div>
                                            <div className="mb-3">
                                            <span className="form-label fw-bold d-block mb-1">Phone*</span>
                                            <InputMask placeholder="(999) 999-9999" className="form-control m-0 border border-2 border-primary p-2" value={this.state.userPhone} mask="(999) 999-9999" maskChar=" " onChange={(event) => {
                                              this.setState({userPhone: event.target.value})
                                            }} />
                                            </div>
                                            <div className="mb-3">
                                            <span className="form-label fw-bold d-block mb-1">Email</span>
                                            <InputMask placeholder="test@test.com" className="form-control m-0 border border-2 border-primary p-2" value={this.state.userEmail} onChange={(event) => {
                                              this.setState({userEmail: event.target.value})
                                            }} />
                                            {
                                              this.emailValidator(this.state.userEmail) ? (
                                                <div style={{color: 'red'}}>
                                                  <p>Value is not a valid email</p>
                                                </div>
                                              ) : ''
                                            }
                                            </div>
                                        </div>
                                        <div>
                                            <Button className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" disabled={!this.userInfoValidate()} onClick={() => {this.next(); this.loadLocationDates()}}>Next</Button>
                                            <Button className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" 
                                              onClick={()=>{this.previous()}}
                                              >Back</Button>
                                        </div>
                                              </form>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>


                        <div hidden={!steps.dateDetails.visible}>
                          <div className="step step2">
                            <DateSelection 
                            disableEvent={true}
                            // prev={(data, current) => { this.prev(data, current); }} 
                            dates={this.state.dates} 
                            next={(data, current) => { this.getSelectedSlot(data); }} 
                            // step={this.state.steps.dateSelection}
                            ></DateSelection>
                            <div>
                                <Button className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" 
                                onClick={()=>{this.previous()}}
                                >Back</Button>
                            </div>
                          </div>
                        </div>

                        <div hidden={!steps.timeDetails.visible}>
                          <div className="step step2">
                            <TimeSelection 
                            disableEvent={true}
                            prev={(data, current) => { this.previous()}} 
                            date={this.state.selectedDateSlot ? this.state.selectedDateSlot.strdt:  ''} 
                            slots={this.state.slots} 
                            next={(data, current) => { this.setState({selectedTimeSlot: data}, () => {this.next()})}}
                            // step={this.state.steps.timeSelection}
                            ></TimeSelection>
                          </div>
                        </div>


                        <div hidden={!steps.paymentDetails.visible || this.state.isCreated}>
                            <div className="step step2">
                                <FormGroup className="col-  mt-4">
                                <Label>Please select a payment</Label>
                                <Select
                                    className="single-select"
                                    classNamePrefix="single-select"
                                    isSearchable={true}
                                    // isMulti={false}
                                    // isClearable={true}
                                    value={this.state.paymentObj}
                                    options={[
                                      {
                                        label: 'Credit',
                                        value: 'credit'
                                      },
                                      {
                                        label: 'Cash',
                                        value: 'cash'
                                      },
                                      {
                                        label: 'NP',
                                        value: 'np'
                                      }
                                    ]}
                                    placeholder={'Please Select'}
                                    onChange={({label, value}) => {     
                                      if(value === 'cash' || value === 'credit')
                                      this.setState({isPaid: true, paymentObj: {label, value}})
                                      else {
                                        this.setState({isPaid: false, paymentObj: {label, value}}) 
                                      }
                                    }}
                                />
                                 <div>
                                      <Button className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" 
                                      disabled={this.state.isPaid === null}
                                      onClick={()=>{this.schedule()}}
                                      >Create</Button>
                                  </div>
                                
                                  <div>
                                      <Button className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" 
                                      onClick={()=>{this.previous()}}
                                      >Back</Button>
                                  </div>

                             
                                </FormGroup>
                            </div>
                        </div>

                        <div hidden={!this.state.isCreated}>
                          <div className="step step1">
                            <div className="border border-primary border-2 text-dark text-uppercase p-3 d-flex w-100">
                              Thank you! Your Appointment Has Been Created.
                            </div>
                            <p className="my-4"></p>
                          </div>

                          <div style={{marginTop: "20px"}}>
                              <Button className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" 
                              onClick={()=>{this.reInitialize()}}
                              >Create Another Appointment</Button>
                          </div>
                        </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Sales;
