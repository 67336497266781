/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import Routes from '../app/Routes';
import {Tabs, Tab} from 'react-bootstrap';
import ActiveEditField from '../components/ActiveEditField';
import EventTypes from './EventTypes';
import compaign from "../assets/CollectionsCampaign.svg";

class Locations extends Component {
  constructor(props) {
    super(props);
    
    this.entity = EM.locations;
    this.eventTypesEntity = EM.eventTypes
    // let user = EM.account.get();
    let company = EM.getDefaultCompany();
    //  _.findWhere(user.userCompanies,{defaultCompany:true});
    this.state = {
      isGESKUS:false,
      company,
      parentRecordDetail:{companyId:company.companyId},
      isSuperAdmin:EM.isSuperAdmin(),
      companyName:null,
      tabKey:'eventsList'
    };
    
    this.eventTypesEntity.loadEntityByParent(this.state.parentRecordDetail,true);
    this.entity.loadEntityByParent(this.state.parentRecordDetail,true);
    this.eventCategory = {
      "null":null,
      "1": "Normal Event", 
      "2": "School Event",
      "3": "Sports Event"
    }
  }
  async componentDidMount(){
    
    const companyId = EM.getDefaultCompany().companyId
    this.setState({companyName:EM.getDefaultCompany().companyName})
    const parentRecordDetail = {id: companyId}  
    await EM.companyConfig.loadSingleEntity(parentRecordDetail).then((data) => {
      
    });
      
    const companyConfig = EM.companyConfig.get()
    
    if(companyConfig.printinglabId===2 && companyConfig.printing===true){
      this.setState({isGESKUS:true})
    }
    
  }
  toRedirect(locationId, toward){
    this.props.history.push(Routes.compose(Routes.client[toward],{locationId}));
    // EM.redirectTo(Routes.client.intervals,{locationId})
  }

  handleSelect = (tabKey) => {
    this.setState({ tabKey });
  }
  render() {
    let columns = [];
    let data = [];
    let self = this;
    if (EM.allLoaded(this.entity, this.eventTypesEntity)) {
      data = this.entity.get();
      columns = [     
        {
          dataField: "name",
          text: this.entity.columns("name"),
          validators: [ Validators.required,Validators.length100],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "expiryDate",
          text: this.entity.columns("expiryDate"),
          asDateTime: true,
          // hideInTable:true,
          // expandable:true,
          hideTime:true,
          // filterOptions: {
          //   text: true,
          // },
        },
        {
          dataField: "address1",
          text: this.entity.columns("address1"),
          hideInTable:true,
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
          expandable:true,
        },
        {
          dataField: "address2",
          text: this.entity.columns("address2"),
          hideInTable:true,
          filterOptions: {
            text: true,
          },
          expandable:true,
        },
        {
          dataField: "city",
          text: this.entity.columns("city"),
          hideInTable:true,
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
          expandable:true,
        },
        {
          dataField: "state",
          text: this.entity.columns("state"),
          hideInTable:true,
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
          expandable:true,
        },
        {
          dataField: "zip",
          text: this.entity.columns("zip"),
          hideInTable:true,
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
          expandable:true,
        },
        {
          dataField: "phone",
          text: this.entity.columns("phone"),
          hideInTable:true,
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
          expandable:true,
        },
        {
          dataField: "free810Promotion",
          text: this.entity.columns("free810Promotion"),          
          hideInTable:true,
          asActive: true,
          filterOptions: {
            text: true,
          },
          expandable:true
        },   
        {
          dataField: "freeImagePromotion",
          text: this.entity.columns("freeImagePromotion"),          
          hideInTable:true,
          asActive: true,
          filterOptions: {
            text: true,
          },
          expandable:true
        },   
        {
          dataField: "force810Promotion",
          text: this.entity.columns("force810Promotion"),          
          hideInTable:true,
          asActive: true,
          filterOptions: {
            text: true,
          },
          expandable:true
        }, 
        {
          dataField: "forceImagePromotion",
          text: this.entity.columns("forceImagePromotion"),          
          hideInTable:true,
          asActive: true,
          filterOptions: {
            text: true,
          },
          expandable:true
        },   
        {
          dataField: "onlyDirectoryUpload",
          text: this.entity.columns("onlyDirectoryUpload"),          
          hideInTable:true,
          asActive: true,
          filterOptions: {
            text: true,
          },
          expandable:true
        },   
        {
          dataField: "maziOrganizationCode",
          text: this.entity.columns("maziOrganizationCode"),
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "active",
          text: this.entity.columns("active"),
          asActive: true,
          creatable:false
        },
        {
          dataField: "eventTypeId",
          text: this.eventTypesEntity.t("title"),
          fromEntity: this.eventTypesEntity,
          filterOptions: {
            text: true
          },
        },
        {
          dataField: "eventCategory",
          text: this.entity.columns("eventCategory"),
          editor:{
            type: "select",
            options: [
              {value:"1", label: "Normal Event"},
              {value:"2",label:"School Event"},
              {value:"3",label:"Sports Event"}
          ]},
          validators: [ Validators.required ],
          formatter: (cell, row) => {
            return (<div> {this.eventCategory[row.eventCategory]}</div>)
          },
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "Intervals",
          text:"Intervals",          
          creatable:false,
          formatter: (cell,row) => {            
            return (
            <>
            <button className="btn btn-primary" onClick={(event)=>{self.toRedirect(row.locationId,"intervals");}}><i className="fas fa-clock"></i></button>
             </> 
            )
        },  
        },
        {
          dataField: "Compaigns",
          text:"Campaigns",          
          creatable:false,
          formatter: (cell,row) => {            
            return (
            <>
            <img src={compaign} role="button" alt="compaign" height={40} width={40} onClick={(event)=>{self.toRedirect(row.locationId,"compaign");}}/>
             </> 
            )
        },  
        },
      ];

      if(this.state.isGESKUS){
        columns.splice(1, 0, {
          dataField: "bookFront",
          text: this.entity.columns("bookFront"),
          filterOptions: {
            text: true,
          },
          hideInTable:true,
          expandable:true
        });
        columns.splice(2, 0, {
          dataField: "bookBack",
          text: this.entity.columns("bookBack"),
          filterOptions: {
            text: true,
          },
          hideInTable:true,
          expandable:true
        });
        columns.splice(3, 0, {
          dataField: "bookAd1",
          text: this.entity.columns("bookAd1"),
          filterOptions: {
            text: true,
          },
          hideInTable:true,
          expandable:true
        });
        columns.splice(4, 0, {
          dataField: "bookAd2",
          text: this.entity.columns("bookAd2"),
          filterOptions: {
            text: true,
          },
          hideInTable:true,
          expandable:true
        });
      }
    }
    const onUpdate=(toUpdate)=>{
      // console.log(toUpdate)
      const newRow= toUpdate.row
      newRow[toUpdate.dataField]=toUpdate.value

      this.entity.update(toUpdate.row, newRow,{companyId:toUpdate.row.companyId}).then((res) => {
    });
    }
    const chooseControls = (element, row)=>{
      if(element.asActive && !element.formatter && !element.editor){
        return <ActiveEditField row={row} dataField={element.dataField} onUpdate={onUpdate} defaultValue={row[element.dataField]!=null?row[element.dataField]:'false'} value={row[element.dataField]} />
      } else {
        return <input className="form-control" row={row} dataField={element.dataField} onBlur={(event)=>onUpdate({row:row,dataField:element.dataField,value:event.target.value})} defaultValue={row[element.dataField]} />
      }
    }
    const expandRow = {
      renderer: row => {
        const expandableColumns = columns.filter(column => (column.expandable===true))
        const render = expandableColumns.map((element,index) => {
          
            
            return (
              <div className="row  mt-4 form-group container" key={index}>
                <div className="col-3">
                <label className="">{element.text}</label>
                </div>
                <div className="col-3">
                  {chooseControls(element, row)}
                 
                </div>
            </div>
            )
          
        });
        return <div className="form-create">{render}</div>
      // console.log(this.expandableColumns)
      //   return  (
      //   <div>
      //     <p>{ `This Expand row is belong to rowKey ${row.id}` }</p>
      //     <p>You can render anything here, also you can add additional data on every row object</p>
      //     <p>expandRow.renderer callback will pass the origin row object to you</p>
      //   </div>
      // )
    },
      showExpandColumn: true,
      onExpand: (row, isExpand, rowIndex, e) => {
        // console.log(row);
        // console.log(isExpand);
        // console.log(rowIndex);
        // console.log(e);
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <i className="fa fa-minus" aria-hidden="true"></i>;
        }
        return <i className="fa fa-plus" aria-hidden="true"></i>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <i className="fa fa-minus" aria-hidden="true"></i>
          );
        }
        return (
          <i className="fa-solid fa-magnifying-glass-plus"></i>
        );
      }
    };
    
    return (
      <>
        <Tabs
          activeKey={this.state.tabKey}
          onSelect={this.handleSelect}
        >
            <Tab                   
              eventKey="eventsList"
              title="Events"/>
            <Tab eventKey="eventtypes" title="Event Types" />
        </Tabs>     
      <div key="contents" className="page container-fluid">
        {this.state.tabKey === "eventsList" ? (
          <EntityTable
            expandRow={expandRow}
            pageTitle={`Events ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`}
            entity={this.entity}
            data={data}
            columns={columns}
            className="table-overflow-container"
            hideImportExport={true}
            hideAudit ={true}
            parentRecordDetail={this.state.parentRecordDetail}
            onClose={() => {
              this.data = this.entity.get();
            }}
          />
        ) : <EventTypes tabKey={this.state.tabKey} company={this.state.company} {...this.props} eventTypes={this.eventTypesEntity}/>}
      </div>
      </>
    );
  }
}

export default Locations;
