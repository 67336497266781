/* eslint no-return-assign: 0 */
import React, { Component } from 'react';

export default class PercentageEditField extends Component {
    constructor(props){
        super(props);

        this.state = {
            value: null
        }
    }

    getValue(){
        return this.state.value;
    }

    render() {
        let dv = this.props.value ? (this.props.value * 100).toFixed(0) : (this.props.defaultPercentage);

        return (
            <input type="number" defaultValue={dv} className={"form-control editor " + (this.props.invalid ? 'is-invalid' : '')} onChange={event => {
                let newValue = event.target.value;
                if (newValue){
                    this.setState({ value: newValue / 100 });
                    if(this.props.onChange)this.props.onChange({ target: { value: newValue / 100 }});
                }
            }} onBlur={(event) => {
                let newValue = event.target.value;
                if (newValue){
                    if (this.props.onUpdate)this.props.onUpdate(newValue / 100);
                    this.setState({ value: newValue / 100 })
                    event.target.blur();
                }
            }}/>        
        );
    }
}