/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import PopeUpUpdate from '../components/Widgets/PopeUpUpdate'
class CompaignTypes extends Component {
  constructor(props) {
    super(props);
    this.entity = props.compaignTypeEntity;
    this.state = {
      isEditOpen:false,
      parentRecordDetail:{locationId:props.eventLocation.locationId},
      isSuperAdmin:EM.isSuperAdmin(),
      locationName:props.eventLocation.name,
    };
    this.openEdit = this.openEdit.bind(this);
    this.popUpRef = React.createRef()
    this.messageType = {
      "null":null,
      "1": "No Orders",
      "2":"In Complete Cart",
      "3":"Marketing Campaign",
      "4":"Appointment Reminder",
      "5":"Non Consumed Packages"
    }
  }

  openEdit(column,row,validators, entity){
    this.popUpRef.current.setConfig({
      key:column.dataField,
      label:column.text,
      value:row[column.dataField],
      validators,
      entity,
      row
    })
    this.setState({isEditOpen:true})
  }

  render() {
    let columns = [];
    let data = [];
    if (EM.allLoaded(this.entity)) {
      data = this.entity.get();
      columns = [     
        {
          dataField: "name",
          text: this.entity.columns("name"),
          validators: [ Validators.required,Validators.length100],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "messageType",
          text: this.entity.columns("messageType"),
          editor:{
            type: "select",
            options: [
              {value:"1", label: "No Orders"},
              {value:"2",label:"In Complete Cart"},
              {value:"3",label:"Marketing Campaign"},
              {value:"4",label:"Appointment Reminder"},
              {value:"5",label:"Non Consumed Packages"},
          ]},
          validators: [ Validators.required ],
          formatter: (cell, row) => {
            return (<div> {this.messageType[row.messageType]}</div>)
          },
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "messageToSent",
          text: this.entity.columns("messageToSent"),
          validators: [Validators.required],
          filterOptions: {
            text: true
          },
          editable:false,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) =>this.openEdit(column,row,[Validators.required],this.entity) 
          },
          type:'textarea'
        },
        {
          dataField: "active",
          text: this.entity.columns("active"),
          asActive: true,
          creatable:false
        },
        {
          dataField: "emailToSend",
          text: this.entity.columns("emailToSend"),
          // validators: [Validators.required],
          asEmail: true,
          filterOptions: {
            text: true
          },
          editable:false,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) =>this.openEdit(column,row,[Validators.required],this.entity) 
          },
        },
      ];
    }
    return (   
      <>
        <PopeUpUpdate ref={this.popUpRef} isOpen={this.state.isEditOpen} close={()=>this.setState({isEditOpen:false})} />
          <EntityTable
            pageTitle={`Marketing Campaign Types for ${this.state.locationName}`}
            entity={this.entity}
            data={data}
            columns={columns}
            customOverRide={[this.state.parentRecordDetail]}
            className="table-overflow-container"
            hideImportExport={true}
            hideAudit ={true}
            parentRecordDetail={this.state.parentRecordDetail}
            onCreate={(data) =>{
              return true;
            }}
            onClose={() => {
              this.data = this.entity.get();
            }}
          />
      </>
    );
  }
}

export default CompaignTypes;
