import * as types from '../actionTypes';
import * as errorActions from './error';

export function loadAccountSuccess(accountData) {
    return { type: types.LOAD_ACCOUNT, data: accountData };
}

export function loadAccount(loginMode) {
    return (dispatch, getState, api) => {
        let account = window.localStorage.getItem("fo.account");
        let data = JSON.parse(account);        
        if(data)
            return dispatch(loadAccountSuccess(data));
        else{
            dispatch(errorActions.globalError('User not found'));
            throw "User not found";
        }
    };
}
