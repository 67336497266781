import tinycolor from 'tinycolor2';

export default class ColorManager{
    constructor(){
        this.currentIndex = -1;
        this.set = ColorManager.COLORS;
    }

    reset(){
        this.currentIndex = -1;
    }

    next(){
        this.currentIndex++;
        if (this.currentIndex > this.set.length)this.currentIndex = 0;
        return this.current();
    }

    current(isAlt, isAlt2){
        if (isAlt){
            if(isAlt2){
                return tinycolor(this.at(this.currentIndex)).darken(20).toString();
            }else{
                return tinycolor(this.at(this.currentIndex)).darken().toString();
            }
        }else{
            return this.at(this.currentIndex);
        }
    }

    at(index){
        let newIndex = index >= this.set.length ? index % this.set.length : index;
        return this.set[newIndex];
    }

    static get COLORS(){
        return [
            //new colors
            '#e91e63', '#9f2db3', '#673ab7', '#3f51b4', '#2596f3', '#24bcd4', '#179688', '#8ac34a', 
            '#cddc39', '#e8d300', '#fdc108', '#fc9800', '#fc5722',

            //old colors
            '#0074c7', '#809693', '#11C700', '#B600C7', '#00C7B6', '#C70011', '#C7B600', '#0597FF',
            '#FF6D05', '#D157A0', '#5300C7', "#FF4A46", "#008941", "#006FA6", "#A30059", "#DBDB00",
            "#FFDBE5", "#7A4900", "#0000A6", "#63FFAC", "#B79762", "#004D43", "#8FB0FF", "#997D87",
            "#5A0007", "#C75300", "#1B4400", "#4FC601", "#3B5DFF", "#4A3B53", "#FF2F80", "#1CE6FF",
            "#61615A", "#BA0900", "#6B7900", "#00C2A0", "#FFAA92", "#FF90C9", "#B903AA", "#D16100",
            "#DDEFFF", "#000035", "#7B4F4B", "#A1C299", "#300018", "#0AA6D8", "#013349", "#00846F",
            "#372101", "#FFB500", "#C2FFED", "#A079BF", "#CC0744", "#C0B9B2", "#C2FF99", "#001E09",
            "#00489C", "#6F0062", "#0CBD66", "#EEC3FF", "#456D75", "#B77B68", "#7A87A1", "#788D66",
            "#885578", "#FAD09F", "#FF8A9A", "#00C753", "#BEC459", "#456648", "#0086ED", "#886F4C",
            "#34362D", "#B4A8BD", "#00A6AA", "#452C2C", "#636375", "#A3C8C9", "#FF913F", "#938A81",
            "#575329", "#00FECF", "#B05B6F", "#8CD0FF", "#3B9700", "#04F757", "#C8A1A1", "#1E6E00",
            "#7900D7", "#A77500", "#6367A9", "#A05837", "#6B002C", "#772600", "#D790FF", "#9B9700",
            "#549E79", "#FFF69F", "#201625", "#72418F", "#BC23FF", "#99ADC0", "#3A2465", "#922329",
            "#5B4534", "#FDE8DC", "#404E55", "#0089A3", "#CB7E98", "#A4E804", "#324E72", "#FF34FF"
        ];
    }

}