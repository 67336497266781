/* global EM */
import { Type } from 'react-bootstrap-table2-editor';
import ActiveEditField from './ActiveEditField';
import PercentageEditField from './PercentageEditField';
import DateEditField from './DateEditField';
import PopoutEditField from './PopoutEditField';
import WorkItemSelector from './WorkItemSelector';
import UserIcon from '../components/UserIcon';
import AssignmentStatusEditField from './Staffing/AssignmentStatusEditField';
import AssignmentLabelEditField from './Staffing/AssignmentLabelEditField';
import React from 'react';
import { Validate } from '../util/EntityValidators';
import Dates from '../util/Dates';
import { textFilter, selectFilter, numberFilter, dateFilter, Comparator } from 'react-bootstrap-table2-filter';
import CheckBoxEditField from './CheckBoxEditField';
import _ from 'underscore';

export default function (columns, registerFilter) {
    return columns.map((columnDef, columnIndex) => {
        var newDef = Object.assign({}, columnDef);
        
        if (!newDef.hasOwnProperty('sort')) {
            newDef.sort = true;
        }

        if (newDef.fromEntity && !newDef.formatter && !newDef.editor) {
            newDef.editor = {
                type: Type.SELECT,
                options: newDef.allowNulls ? [{ value: null, label: null }, ...newDef.fromEntity.asOptionList(newDef.optionLabelField, newDef.omitEntitySort ? false : true)] : newDef.fromEntity.asOptionList(newDef.optionLabelField, newDef.omitEntitySort ? false : true)
            };

            // Handling filter case of role for super admin
            if(columnDef.text === 'Role') {
                let roles  = newDef.editor.options;
                if(EM.isSuperAdmin()) {}
                else if(EM.isAdmin()) {
                    roles = newDef.editor.options.filter((el) => {
                        if(el.label !== 'SuperAdmin')
                            return el
                    })
                } else {
                    roles = newDef.editor.options.filter((el) => {
                        if(el.label !== 'SuperAdmin' && el.label !== 'Admin')
                            return el
                    })
                }
                newDef.editor.options = roles;
            }

            if(columnDef.text === 'User Type' && !EM.isAdmin() && !EM.isSuperAdmin()) {
                let types = newDef.editor.options;
                types = newDef.editor.options.filter((el) => {
                    if(el.label !== 'Associate')
                        return el
                })
                newDef.editor.options = types;
            }

            newDef.formatter = newDef.sortValue = newDef.csvFormatter = (cell, row) => {
                return newDef.fromEntity.lookupValue(cell);
            };

            if (registerFilter) {
                newDef.filterValue = (cell) => {
                    return cell;
                };

                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: newDef.allowNulls ? [{ value: null, label: null }, ...newDef.fromEntity.asOptionList(null, newDef.omitEntitySort ? false : true)] : newDef.fromEntity.asOptionList(null, newDef.omitEntitySort ? false : true)
                });
            }
        }

        if (newDef.asUser) {
            newDef.editor = {
                type: Type.SELECT,
                options: EM.users.asOptionList((row) => {
                    return row.Name + ' (' + row.Email + ')';
                }, true)
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: EM.users.asOptionList((row) => {
                        return row.Name + ' (' + row.Email + ')';
                    }, true)
                });
            }

            newDef.formatter = (cell) => {
                if (!cell) return null;
                let user = EM.users.byId(cell);
                if (!user) return <span className="text-muted">{EM.t('users.userRemoved')}</span>;
                return <span><UserIcon user={user} className="small mr-1" />{newDef.userText || user.Name}</span>
            };

            newDef.csvFormatter = (cell) => {
                let user = EM.users.byId(cell);
                return user ? user.Name : 'User has been removed.';
            };
        }

        if (newDef.asUserIcon) {
            newDef.editor = null;
            newDef.editable = false;
            newDef.formatter = (cell) => {
                let user = EM.users.byId(cell);
                return <span><UserIcon user={user} className="small mr-1" /></span>
            };
            newDef.csvFormatter = (cell) => {
                let user = EM.users.byId(cell);
                return user ? user.Name : 'Unknown: ' + cell;
            };
        }

        if (newDef.asPermission) {
            newDef.editor = {
                type: Type.SELECT,
                options: [
                    { value: 1, label: 'Subscriber (Read-Only)' },
                    { value: 50, label: 'Editor (Read/Write)' },
                    { value: 100, label: 'Domain Admin (Administrative)' }
                ]
            };
            newDef.formatter = newDef.csvFormatter = (cell) => {
                let permission = EM.permissionLookup.byId(cell);
                return permission ? permission.Name : cell;
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: [
                        { value: 1, label: 'Subscriber' },
                        { value: 50, label: 'Editor' },
                        { value: 100, label: 'Domain Admin' }
                    ]
                });
            }
        }

        if (newDef.asCheckbox && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <CheckBoxEditField options={column.options} {...editorProps} value={value} sticky={false} />
            };

            newDef.formatter = (cell,row) => {
                if (!cell) return;
                let output = [];
                _.each(cell,(item)=>{
                    let option = _.findWhere(newDef.options,{value:item});
                    if(option)
                        output.push(option.label);
                });
                return output.toString();
            };
        }

        if (newDef.asActive && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <ActiveEditField {...editorProps} value={value} />
            };
            newDef.formatter = newDef.csvFormatter = (cell) => {
                if (typeof cell === 'undefined' || cell === null) return '';
                return cell.toString().toLowerCase() === 'true' ? 'Yes' : 'No'
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: [
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                    ]
                });
            }
        }

        if (newDef.asWorkItem && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <WorkItemSelector {...editorProps} value={value} />
            };
            newDef.formatter = newDef.csvFormatter = (cell) => {
                return cell;
            };
            newDef.csvFormatter = newDef.csvFormatter = (cell) => {
                return cell ? cell : '';
            };
        }

        if (newDef.asPercentage && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <PercentageEditField {...editorProps} value={value} defaultPercentage={(newDef.defaultPercentage || 0)} />
            };
            newDef.formatter = newDef.csvFormatter = (cell) => {
                if (!cell) return '';
                return (cell * 100).toFixed(0);
            };
        }

        if (newDef.asDate && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <DateEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell) => {
                if (!cell) return;
                return Dates.toMonthYearStrShort(Dates.fromISO(cell));
            };
            newDef.csvFormatter = (cell) => {
                if (!cell) return '';
                return Dates.toDateShort(Dates.fromISO(cell));
            };
            newDef.filterValue = (cell) => {
                if (!cell) return null;
                return Dates.fromISO(cell).toJSDate();
            };

            if (registerFilter) {
                newDef.filter = dateFilter({
                    placeholder: 'Date',
                    withoutEmptyComparatorOption: true,
                    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter, 'date'); },
                });
            }
        }

        if (newDef.asDateTime && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <DateEditField {...editorProps} value={value} hideTime={newDef?.hideTime || false}/>
            };
            newDef.formatter = (cell) => {
                if (!cell) return;
                return Dates.toDateTime(Dates.fromISO(cell));
            };
            newDef.csvFormatter = (cell) => {
                if (!cell) return '';
                return Dates.toDateShort(Dates.fromISO(cell));
            };
            newDef.filterValue = (cell) => {
                if (!cell) return null;
                return Dates.fromISO(cell).toJSDate();
            };

            if (registerFilter) {
                newDef.filter = dateFilter({
                    placeholder: 'Date',
                    withoutEmptyComparatorOption: true,
                    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter, 'date'); },
                });
            }
        }

        if (newDef.asFeature && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                if (newDef.onStartEdit) {
                    newDef.onStartEdit(editorProps, value, row, column, rowIndex, columnIndex);
                }
                return <PopoutEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell) => {
                let jsonObj;
                try {
                    jsonObj = JSON.parse(cell);
                } catch (e) { }
                if (!jsonObj) {
                    return cell ? 'Invalid JSON' : '';
                }
                let output = '';
                Object.keys(jsonObj).forEach(key => {
                    let tmp = [];
                    Object.keys(jsonObj[key]).forEach(feat => {
                        if (jsonObj[key][feat]) {
                            tmp.push(feat.capitalize());
                        }
                    });
                    if (tmp.length > 0) {
                        output += (key === '*' ? 'All' : key) + ': ' + tmp.join(', ');
                    }
                });
                return output;
            };
            newDef.csvFormatter = (cell) => {
                return cell || '';
            };
            newDef.classes = 'column-pre';
        }

        if (newDef.asOptions && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                if (newDef.onStartEdit) {
                    newDef.onStartEdit(editorProps, value, row, column, rowIndex, columnIndex);
                }
                return <PopoutEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell) => {
                let jsonObj;
                try {
                    jsonObj = JSON.parse(cell);
                } catch (e) { }

                if (!jsonObj) {
                    return cell ? 'Invalid JSON' : <input type="button" className="btn btn-sm btn-secondary" value="Edit" />;
                } else {
                    return <>
                        <input type="button" className="btn btn-sm btn-secondary" value="Edit" />
                        {jsonObj.color ?
                            <input type="color" className="color" value={jsonObj.color} disabled />
                            : null}
                    </>
                }
            };
            newDef.csvFormatter = (cell) => {
                return cell || '';
            };
            newDef.classes = 'column-options';
        }

        if (newDef.asReferenceTable && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                if (newDef.onStartEdit) {
                    newDef.onStartEdit(editorProps, value, row, column, rowIndex, columnIndex);
                }
                return <PopoutEditField {...editorProps} value={value} />
            };
            newDef.formatter = (cell) => {
                return <button className="btn btn-sm btn-primary" disabled={newDef.hidden}>{EM.t('util.table.view')}</button>
            }
            newDef.csvFormatter = (cell) => {
                return cell || '';
            };
            newDef.headerClasses = 'column-functions';
            newDef.classes = 'column-functions';
            newDef.csvExport = false;
        }

        if (newDef.asAssignmentStatus && !newDef.formatter && !newDef.editor) {
            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <AssignmentStatusEditField {...editorProps} value={value} />
            };

            newDef.formatter = newDef.csvFormatter = (cell) => {
                return cell ? cell : 'Assigned';
            };

            if (registerFilter) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: [
                        { value: 'Assigned', label: 'Assigned' },
                        { value: 'Locked', label: 'Locked' },
                    ]
                });
            }
        }

        if (newDef.asAssignmentLabel) {            
            newDef.formatter = newDef.csvFormatter = (cell) => {
                return cell;
            };

            newDef.editorRenderer = (editorProps, value, row, column, rowIndex, columnIndex) => {
                return <AssignmentLabelEditField {...editorProps} value={value} />
            };
        }

        if (newDef.isReference) {
            newDef.isDummyField = true;
            newDef.headerClasses = 'column-reference';
            newDef.classes = 'column-reference';
            newDef.editable = false;
            newDef.csvExport = false;
        }

        if (newDef.isDateReference) {
            newDef.headerClasses = newDef.headerClasses || 'column-reference';
            newDef.classes = newDef.classes || 'column-reference';
            newDef.editable = false;
            if (typeof newDef.csvExport === 'undefined') newDef.csvExport = false;
            newDef.formatter = (cell) => {
                if (!cell) return;
                return Dates.isoToDateTime(cell);
            };
            newDef.csvFormatter = (cell) => {
                if (!cell) return;
                let rawDate = Dates.isoToDateTime(cell, Dates.DATETIME_LONG);
                return rawDate.replace(/(\d{4}),\s/gm, '$1 '); //This could cause trouble for ex-us folks. 
            };
            newDef.sortValue = (cell) => {
                if (!cell) return;
                return Dates.fromISO(cell).toMillis();
            };
            newDef.filterValue = (cell) => {
                if (!cell) return undefined;
                return Dates.fromISO(cell).toJSDate();
            };

            if (registerFilter) {
                newDef.filter = dateFilter({
                    placeholder: 'Date',
                    withoutEmptyComparatorOption: true,
                    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter, 'date'); },
                });
            }
        }

        if (newDef.asFunctions) {
            newDef.isDummyField = true;
            newDef.dataField = 'functions' + columnIndex;
            newDef.headerClasses = 'column-functions';
            newDef.classes = 'column-functions';
            newDef.editable = false;
            newDef.csvExport = false;
        }

        if (newDef.asMeta) {
            newDef.headerClasses = 'column-meta';
            newDef.classes = 'column-meta';
            newDef.editable = false;
            newDef.creatable = false;
            newDef.isMeta = true;
            newDef.unhideable = true;
            if (typeof newDef.csvExport === 'undefined') newDef.csvExport = false;
        }

        if (newDef.width) {
            newDef.classes = (newDef.classes ? newDef.classes + ' ' : '') + 'width-' + newDef.width;
            newDef.headerClasses = (newDef.headerClasses ? newDef.headerClasses + ' ' : '') + 'width-' + newDef.width;
        }

        if (newDef.validators) {
            newDef.validator = (newValue, row, column) => {
                let valResult = Validate(newValue, newDef.validators,null,null,row);
                if (valResult === null) {
                    return true;
                } else {
                    window.setTimeout(() => { EM.setError(valResult) }, 100);
                    return { valid: false, message: valResult }
                }
            }
        }

        if (newDef.editable === false) {
            newDef.classes = (newDef.classes ? newDef.classes + ' ' : '') + 'readonly';
        }

        if (!newDef.csvFormatter) {
            newDef.csvFormatter = (cell) => {
                if (cell) {
                    return cell;
                } else {
                    if (cell === 0) {
                        return '0';
                    } else {
                        return '';
                    }
                }
            };
        }

        if (!newDef.hasOwnProperty('filter') && newDef.filterOptions && registerFilter) {
            if (newDef.filterOptions.dynamicEntity) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: newDef.filterOptions.dynamicEntity.asOptionList('Name', false, 'Name')
                });
            }
            if (newDef.filterOptions.number) {
                newDef.filter = numberFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter, 'number'); },
                    withoutEmptyComparatorOption: true,
                    comparators: [Comparator.EQ, Comparator.GT, Comparator.LT],
                    placeholder: 'Value'
                });
            }
            if (newDef.filterOptions.text) {
                newDef.filter = textFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Value'
                });
            }
            if (newDef.filterOptions.bool) {
                newDef.filter = selectFilter({
                    getFilter: (filter) => { if (registerFilter) registerFilter(filter); },
                    placeholder: 'Select',
                    options: [
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                    ]
                });
            }
        }

        
        if (!newDef.editor && !newDef.editorRenderer && newDef.editable !== false && newDef.characterLimit){
            newDef.editor = {
                maxLength: newDef.characterLimit
            }
        }
        return newDef;
    });
}