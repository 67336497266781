/* global EM */
import React, { Component } from "react";
class Summary extends Component {
    constructor(props) {
        super(props);
        console.log("🚀 ~ Summary ~ constructor ~ props:", props)
    }
    
    render() {
        
        if (EM.allLoaded(EM.products, EM.companyConfig)) {
            return (
                <div>
                    {<div className="row">
                        <div className="col-6">
                            <div style={{ backgroundColor: '#f5f6fa' }}><h5>Summary</h5></div>
                            <table className="table table-bordered">
                                <thead>
                                    <tr style={{ backgroundColor: "#ecf0f1" }}>
                                        <th style={{width:'200px'}}>Total Print Orders</th>
                                        <th>Total Sales</th>
                                        <th>Total Lab Cost</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.props.summary.totalOrders}</td>
                                        <td>${this.props.summary.totalSales.toFixed(2)}</td>
                                        <td>${this.props.summary.totalLabCost.toFixed(2)}</td>
                                        <td>{this.props.summary.startDate}</td>
                                        <td>{this.props.summary.endDate}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    }
                    {<div className="row">
                        <div className="col-8">
                            <table className="table table-bordered">
                                <thead>
                                    <tr style={{ backgroundColor: "#ecf0f1" }}>
                                        <th style={{width:'200px'}}>Total Package Orders</th>
                                        <th>Total Package Sales</th>
                                        <th>Consumed</th>
                                        <th>Consumed Price</th>
                                        <th>Non Consumed Price</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.props.packageOrdersSummary.totalOrders}</td>
                                        <td>${this.props.packageOrdersSummary.totalSales.toFixed(2)}</td>
                                        <td>{this.props.packageOrdersSummary.consumed}</td>
                                        <td>${this.props.packageOrdersSummary.consumedPrice.toFixed(2)}</td>
                                        <td>${this.props.packageOrdersSummary.nonConsumedPrice.toFixed(2)}</td>
                                        <td>{this.props.summary.startDate}</td>
                                        <td>{this.props.summary.endDate}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    }
                    {/* {<div className="row">
                        <div className="col-6">
                            <table className="table table-bordered">
                                <thead>
                                    <tr style={{ backgroundColor: "#ecf0f1" }}>
                                        <th style={{width:'200px'}}>Total Orders</th>
                                        <th>Total Sales</th>
                                        <th>Total Lab Cost</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.props.packageOrdersSummary.totalOrders + this.props.summary.totalOrders}</td>
                                        <td>${(this.props.packageOrdersSummary.totalSales + this.props.summary.totalSales).toFixed(2)}</td>
                                        <td>${this.props.summary.totalLabCost.toFixed(2)}</td>
                                        <td>{this.props.summary.startDate}</td>
                                        <td>{this.props.summary.endDate}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    } */}
                </div>
            )
        }
        return (<div>Loading...</div>);
    }
}
export default Summary;