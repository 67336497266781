/* global EM */
import React, { Component } from 'react';
import CardStats from "../components/CardStats";
import _ from 'underscore';
import {
    Card,
    CardBody,
    CardHeader,
    Button,
} from 'reactstrap'

class DashboardCount extends Component {
    constructor(props) {
        super(props);
        let user = EM.account.get();
        this.entity = EM.dashboard
        let company = _.findWhere(user.userCompanies, { defaultCompany: true });
        this.state = {
            parentRecordDetail: { id: company.companyId },
        };
        this.entity.loadEntityByParent(this.state.parentRecordDetail);
    }

    getColorForValue = (value) => {
        return 'green';
    };

    render() {
        let data = {};
        if (this.entity.isLoaded()) {
            data = this.entity.get();
            if (data.totalCompanies) delete data.totalCompanies
        }
        return (
            <div className="container pb-4">
                <h4>Events</h4>
                <div className="row">
                    <div className="col-md-3">
                        <CardStats label={"Total Events"} value={data.totalEvents} color={this.getColorForValue(data.totalEvents)} />
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Total Events Days"} value={data.totalEventDays} color={this.getColorForValue(data.totalEventDays)} />
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Past Events Days"} value={data.pastEventCounts} color={this.getColorForValue(data.pastEventCounts)} />
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Future Events Days"} navigate={this.props.history} to="/futureEvents" value={data.futureEventCounts} color={this.getColorForValue(data.futureEventCounts)} />
                    </div>
                </div>
                <h4 className='mt-4'>User Orders</h4>
                <div className="row">
                    <div className="col-md-3">
                        <CardStats label={"Total Orders"} value={data.totalOrders} color={this.getColorForValue(data.totalOrders)} />
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"No Purchases"} navigate={this.props.history} to="/noOrderUsers" value={data.noOrderUsers} color={this.getColorForValue(data.noOrderUsers)} />
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Incomplete Cart Users"} navigate={this.props.history} to="/incompleteOrders" value={data.inCompleteCartUsers} color={this.getColorForValue(data.inCompleteCartUsers)} />
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Complete Cart Users"} navigate={this.props.history} to="/totalCompletedOrders" value={data.completeCartUsers} color={this.getColorForValue(data.completeCartUsers)} />
                    </div>
                    <div className="col-md-6 mt-2">
                        <Card className="" style={{ maxHeight: "170px", height: "170px" }}>
                            <CardHeader className="d-flex justify-content-center" style={{ backgroundColor: 'transparent' }}>
                                <h6>Packages</h6>
                            </CardHeader>
                            <CardBody>
                                <div className="d-flex" style={{justifyContent: 'space-evenly'}}>
                                    <div className="d-flex flex-column align-items-center">
                                        <h6>Consumed</h6>
                                        <h3 style={{ color: 'green' }}>{data?.studioPackageSales?.length ? `$${data.studioPackageSales[0].totalConsumedPrepaidPackagesPrice}` : ''}</h3>

                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <h6>Non Consumed</h6>
                                        <h3 style={{ color: 'green' }}>{data?.studioPackageSales?.length ? `$${data.studioPackageSales[0].totalNonConsumedPrepaidPackagesPrice}` : ''}</h3>

                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <i className='fas fa-eye' style={{ color: '#007bff', cursor: "pointer" }} onClick={() => this.props.history.push("/eventsWithPackagesSales")} />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <h4 className='mt-4'>Users</h4>
                <div className="row">
                    <div className="col-md-3">
                        <CardStats label={"Total Users"} value={data.totalUsers} color={this.getColorForValue(data.totalUsers)} />
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Total Active Users"} value={data.totalActiveUsers} color={this.getColorForValue(data.totalActiveUsers)} />
                    </div>
                    <div className="col-md-3">
                        <CardStats label={"Total InActive Users"} value={data.totalInActiveUsers} color={this.getColorForValue(data.totalInActiveUsers)} />
                    </div>
                    <div className="col-md-3 mb-4">
                        <CardStats label={"Non App Users"} value={data.nonSignUpUsers} color={this.getColorForValue(data.nonSignUpUsers)} />
                    </div>
                </div>
                
            </div>
        );
    }
}

export default DashboardCount;
