/* global EM */
import React, { Component } from "react";
import Spinner from "../Spinner";
import _ from 'underscore';
import InputMask from 'react-input-mask';
import searchIcon from "../../assets/search.svg";

export default class LocationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: EM.event.get(),
      location: this.props.location,
      locations: [],
      zipCode: ''
    };
    this.locationSelected = this.locationSelected.bind(this);
  }
  componentDidMount() {
  }

  search() {
    let self = this;
    let params = {};
    let qs = { zip: this.state.zipCode, companyId: this.state.location.companyId };
    EM.locations.searchEntity(params, qs, true).then(() => {
      self.setState({ locations: EM.locations.get() });
    });
  }

  locationSelected(location) {
    this.props.next(location, this.props.step);
  }

  render() {
    if (EM.allLoaded(EM.event)) {
      return (
        <div className="step step2">
          {/* {this.ShowError()} */}
          <h5 className="text-dark fw-bolder">
            Let's find the closest location for your photos, please
            enter your zipcode
          </h5>
          <div className="input-group my-3 flex-column">
            <label className="mb-2">Please enter your zipcode</label>
            <div className="d-flex align-items-center border border-primary border-2">
              <InputMask placeholder="zipcode"
                aria-label="zipcode"
                aria-describedby="zipcode" className="form-control my-0 border-0 p-3" value={this.state.zipCode} onChange={(event) => { this.setState({ zipCode: event.target.value }); }} mask="99999" maskChar=" " />
              <button
                onClick={() => this.search()}
                className="input-group-text bg-transparent my-0 border-0"
              >
                <img
                  src={searchIcon}
                  alt="search"
                  width="24px"
                />
              </button>
            </div>
          </div>
          {
            _.map(this.state.locations, (location) => {
              console.log(location);
              return (
                <div className="border p-3 d-flex gap-2 flex-column align-items-start flex-md-row row">
                  <div className="mr-auto">
                    <h5>{location.name}</h5>
                    <p className="fw-normal">
                      {location.address1}, {location.city}, {location.state}
                    </p>
                    <div className="d-flex gap-4">
                      <span>
                        {location.distance ? location.distance.toFixed(2) : ''} miles
                      </span>
                      <span></span>
                    </div>
                  </div>
                  {location.fullyBooked === true
                    ? <div className="ml-auto booked p-2 text-nowrap fw-bolder ms-auto">FULLY BOOKED</div>
                    : <div onClick={() => { if (location.fullyBooked !== true) this.locationSelected(location); }} className="ml-auto available py-2 px-3 text-nowrap fw-bolder text-uppercase ms-auto" id="next">Available</div>
                  }


                </div>
              )
            })
          }
        </div>


      );
    }
    return (
      <div className={this.props.className}>
        <Spinner text={EM.t("util.loading")} classes="small" />
      </div>
    );
  }
}
