/* eslint no-return-assign: 0 */
import React, { Component } from "react";
// import MonthPicker from "./MonthPicker";
import Dates from "../util/Dates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class DateEditField extends Component {
    constructor(props) {
      super(props);
      this.state={
        selected : Dates.fromISO(this.props.value)
      }
    }
  getValue() {
    let date = this.state.selected;
    return date && date.invalid ==null ? date.toISO().replace(':00.000Z','') : '';
  }

  render() {
    let selected =this.state.selected;     
    let self = this;
    return (
      <DatePicker
        innerRef={this.props.innerRef}
        selected={selected && selected.isValid ? Dates.toJS(selected) : ''}
        onChange={(date) => {
            selected = date ? Dates.fromDate(date).toISO() : '';
            self.setState({selected: selected ? Dates.fromISO(selected):null});
            if(this.props.onUpdate)
                this.props.onUpdate(selected);
        }}
        className="form-control"
        timeInputLabel={this.props.hideTime?false:"Time:"}
        showTimeSelect={this.props.hideTime?false:true}
        dateFormat={`${this.props.hideTime?'MM/dd/yyyy':'MM/dd/yyyy h:mm aa'} `}
      />    
    );
  }
}
