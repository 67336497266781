/* global EM */
import React, { Component } from "react";
import EntityTable from "./EntityTables/EntityTable";
import Dates from '../util/Dates'
import PrintOrdersDetails from "./PrintOrdersDetails";
import Routes from '../app/Routes'
class PrintOrders extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.printOrders;
    this.state = {
      parentRecordDetail: { userId: this.props.data.id, companyId: this.props.data.companyId },
      orderDetailsHidden:true,
      selectedOrder:null
    }
    this.entity.loadEntityByParent(this.state.parentRecordDetail, true);
    this.showDetails = this.showDetails.bind(this);
    this.handleRequeue = this.handleRequeue.bind(this);
  }

  showDetails(order){    
    this.setState({orderDetailsHidden:false,selectedOrder:order});
  }

  handleRequeue(row){   
    var request = {
      orderId:row.printOrderId      
    }
    this.entity.executePost(request,null,this.entity.entityTypeDef.requeueUrl, {},false);
  }


  render() {
    let self = this;
    let columns = [];
    let data = [];
    if (EM.allLoaded(this.entity)) {
      data = this.entity.get();
      columns = [
        {
          dataField: "createdDate",
          text: this.entity.columns("createdDate"),
          asDate: true,
          editable: false,
          filterOptions: {
            text: true,
          },
          formatter: (cell, row) => {
            return (Dates.isoToDateTime(cell, "MM/dd/yyyy"));
          }
        },
        {
          dataField: "gross",
          text: this.entity.columns("gross"),
          editable: false,
          filterOptions: {
            text: true,
          },
          formatter: (cell, row) => {
            return (<div>$ {row.gross.toFixed(2)}</div>)
          }
        },
        {
          dataField: "package",
          text: this.entity.columns("package"),
          editable: false,
          filterOptions: {
            text: true,
          },
          formatter: (cell, row) => {
            return (<div><b>$ {row.package.toFixed(2)}</b> Studio Package</div>)
          }
        },
        {
          dataField: "requeue",
          text: this.entity.columns("requeue"),
          creatable: false,
          editable: false,
          hidden: EM.isAdmin() !== true && EM.isSuperAdmin() !== true,
          formatter: (cell, row) => {
            return (<div>
              <button title="View Details" className="btn btn-primary m-2" onClick={() => { self.showDetails(row); }}><i class="fa fa-eye"></i></button>
              {
                EM.isAdmin() === true || EM.isSuperAdmin() === true ?
                  <button className="btn btn-danger m-2" title="Requeue" onClick={() => { this.handleRequeue(row)}}>Requeue</button>
                  : null}
                </div>);
          }
        }
      ];
    }
    return (
      <div key="contents" className="page container-fluid mt-4">
        <EntityTable
          entity={this.entity}
          data={data}
          columns={columns}
          className="table-overflow-container-auto"
          collapsible={true}
          collapsed={true}
          hideImportExport={true}
          hideAudit={true}
          hideAddRecord={true}
          hideDelete={true}
          hideSearch={true}
          hideFilters={true}
        />
        { this.state.selectedOrder ?
        <div hidden={this.state.orderDetailsHidden}>
          <PrintOrdersDetails show={!this.state.orderDetailsHidden}  onClose={()=>{this.setState({orderDetailsHidden:true})}} order={this.state.selectedOrder} />
          </div>
        :null
      }
      </div>
    );
  }
}

export default PrintOrders;
