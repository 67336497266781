/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import {Tabs, Tab} from 'react-bootstrap';
import CompaignTypes from './CompaignTypes';
import timezones from "../util/timezones.json"
import analytics from "../assets/analytics.svg";
import Routes from '../app/Routes';
class Compaigns extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.compaign;
    this.compaignTypeEntity = EM.compaignType
    this.eventTypesEntity = EM.eventTypes
    this.eventPromotion = EM.eventPromotion
    let company = EM.getDefaultCompany();
    let locationId = this.props.match.params.locationId;

    //  _.findWhere(user.userCompanies,{defaultCompany:true});
    this.state = {
      location:{name:''},
      parentRecordDetail:{locationId},
      isSuperAdmin:EM.isSuperAdmin(),
      tabKey:'marketingCompaigns'
    };
    
    this.entity.loadEntityByParent(this.state.parentRecordDetail);
    this.compaignTypeEntity.loadEntityByParent(this.state.parentRecordDetail,true);
    this.eventTypesEntity.loadEntityByParent({companyId:company.companyId},true);
    this.eventPromotion.loadEntityByParent(this.state.parentRecordDetail,true);
  }

  async componentDidMount(){
    this.getLocation()
  }

  getLocation(){  
    let location = _.findWhere(EM.locations.get(),{locationId:parseInt(this.props.match.params.locationId)});
    if(!location)
    {
      let user = EM.account.get();
      let company = _.findWhere(user.userCompanies, { defaultCompany: true });
      EM.locations.loadEntityByParent({companyId:company.companyId}).then(()=>{
        let location = _.findWhere(EM.locations.get(),{locationId:parseInt(this.props.match.params.locationId)});
        this.setState({location:location});
      });
    }
    else{
      this.setState({location});
    }
  }

  handleSelect = (tabKey) => {
    this.setState({ tabKey });
  }
  getTitle(){
    return `Marketing Campaigns for ${this.state.location.name}`;
  }

  timezoneOptions() {
      return timezones.map(timezone => {
        return {
          label: `${timezone.value} ${timezone.text}`,
          value: timezone.value
        };
      });
  }
  toRedirect(row){
    if(this.state.location.name) {
      this.props.history.push(Routes.compose(Routes.client.sendGridStats,{eventName:this.state.location.name,campaignName:row.name,campaignId:row.marketingCompaignId, startDate:row.startDate?.split('T')[0]}));
    }
  }
  render() {
    let columns = [];
    let data = [];
    let self = this;
    if (EM.allLoaded(this.entity, this.compaignTypeEntity, this.eventPromotion)) {
      data = this.entity.get();
      columns = [     
        {
          dataField: "name",
          text: this.entity.columns("name"),
          validators: [ Validators.required,Validators.length100],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "startDate",
          asDateTime: true,
          text: this.entity.columns("startDate"),
          // validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "interval",
          text: this.entity.columns("interval"),
          editor:{
            type: "select",
            options: [
              {value:"1", label: "Everyday"},
              {value:"2",label:"2 days"},
              {value:"4",label:"4 days"},
              {value:"7",label:"7 days"},
              {value:"15",label:"15 days"},
              {value:"30",label:"30 days"},
          ]},
          validators: [ Validators.required ],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "endDate",
          asDateTime: true,
          text: this.entity.columns("endDate"),
          // validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "marketingComaignTypeId",
          text: this.compaignTypeEntity.t("title"),
          fromEntity: this.compaignTypeEntity,
          filterOptions: {
            text: true
          },
        },
        {
          dataField: "eventTypeId",
          text: this.eventTypesEntity.t("title"),
          fromEntity: this.eventTypesEntity,
          filterOptions: {
            text: true
          },
        },
        {
          dataField: "eventId",
          text: this.eventPromotion.t("title"),
          fromEntity: this.eventPromotion,
          optionLabelField:(row)=> `${row.deal.substring(0,25)}.....`,
          filterOptions: {
            text: true
          },
        },
        {
          dataField: "timezone",
          text: this.entity.columns("timezone"),
          editor:{
            type: "select",
            options: this.timezoneOptions()
          },
          validators: [ Validators.required ],
          filterOptions: {
            text: true,
          },
        },        {
          dataField: "active",
          text: this.entity.columns("active"),
          asActive: true,
          creatable:false
        },
        {
          dataField: "Analytics",
          text:"Analytics",          
          creatable:false,
          formatter: (cell,row) => {            
            return (
            <>
            <img src={analytics} role="button" alt="analytics" height={40} width={40} onClick={(event)=>{self.toRedirect(row)}}/>
             </> 
            )
        },  
        },
      ];
    }
    
    
    return (
      <>
        <Tabs
          activeKey={this.state.tabKey}
          onSelect={this.handleSelect}
        >
            <Tab                   
              eventKey="marketingCompaigns"
              title="Marketing Campaigns"/>
            <Tab eventKey="compaignTypes" title="Campaign Types" />
        </Tabs>     
      <div key="contents" className="page container-fluid">
        {this.state.tabKey === "marketingCompaigns" ? (
          <EntityTable
            pageTitle={`${this.getTitle()}`}
            entity={this.entity}
            data={data}
            columns={columns}
            customOverRide={[this.state.parentRecordDetail]}
            className="table-overflow-container"
            hideImportExport={true}
            hideAudit ={true}
            parentRecordDetail={this.state.parentRecordDetail}
            onCreate={(field) =>{
              let proceed = true
              for (const record of data) {
                if (
                  ((field.startDate >= record.startDate && field.startDate <= record.endDate) ||
                  (field.endDate >= record.startDate && field.endDate <= record.endDate) ||
                  (record.startDate >= field.startDate && record.startDate <= field.endDate) ||
                  (record.endDate >= field.startDate && record.endDate <= field.endDate)) &&
                  record.marketingComaignTypeId == field.marketingComaignTypeId
                ) {
                  EM.setError("Another campaign is already added in selected Dates and marketing campaign type");
                  proceed = false;
                  return
                }
              }
              return proceed;
            }}
            onClose={() => {
              this.data = this.entity.get();
            }}
          />
        ) : <CompaignTypes tabKey={this.state.tabKey} eventLocation={this.state.location} {...this.props} compaignTypeEntity={this.compaignTypeEntity}/>}
      </div>
      </>
    );
  }
}

export default Compaigns;
