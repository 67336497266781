/* global EM */
import React, { Component } from "react";
import _ from 'underscore';
import AppointmentCalender from "../components/Calendar";
import TimeSlot from "../components/TimeSlot";
import Routes from "../app/Routes";
import InputMask from 'react-input-mask';
import { Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import Dates from "../util/Dates";

class RescheduleAppointment extends Component {
    constructor(props) {
        super(props);
        this.entity = EM.rescheduleAppointment;
        this.state = {
            parentRecordDetail: { companyId: this.props.match.params.companyId, userId: this.props.match.params.userId, appointmentId: this.props.match.params.appointmentId },
            selectedTime: "",
            selectedDate: new Date(),
            locationId: 0,
            user: {},
            showMyComponent: false,
            locations: [],
            appointments: [],
            showContactInfo: false,
            appointment: null,
            timeSlotHidden:true
        };
        this.locationChange = this.locationChange.bind(this);
        this.timeChange = this.timeChange.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.redirectToUserDetail =this.redirectToUserDetail.bind(this);
    }

    componentDidMount() {
        var self = this;
        EM.locations.loadEntityByParent(self.state.parentRecordDetail).then(() => {
            self.setState({ locations: EM.locations.get() });
        });
        EM.users.executeGet(null, null, Routes.clientApi.byId, { id: this.state.parentRecordDetail.userId }, true).then((res) => {
            this.setState({ user: res });
        });
        EM.appointments.executeGet(null, null, Routes.clientApi.byId, { id: this.state.parentRecordDetail.appointmentId }, true).then((res) => {
            this.setState({ appointment: res });
        });
    }

    locationChange(event) {
        let locId = event.target.value;
        if (locId == 0) { this.setState({ showMyComponent: false, locationId: 0, appointments: null }); }
        else {
            this.setState({ showMyComponent: true, locationId: locId }, () => {
                EM.appointments.executeGet(null, { locationId: locId }, Routes.clientApi.appointmentsByLocation, {}, true).then((res) => {
                    this.setState({ appointments: res, showContactInfo:false,timeSlotHidden:true});
                });
            });
        }
    }
    calendarChange = (strdt) => {
        this.setState({ selectedDate: strdt,timeSlotHidden:false });
    }

    timeChange(e) {
        if (e.target.value.toLowerCase() != "please select")
            this.setState({ showContactInfo: true, selectedTime: e.target.value });
    }
    updateUser(event, field) {
        let uUser = this.state.user;
        uUser[field] = event.target.value;
        this.setState({ user: uUser });
    }
    validate() {
        return this.state.selectedDate
            && this.state.selectedTime
            && this.state.locationId
            && this.state.user.firstName
            && this.state.user.lastName
            && this.state.user.phoneNumber;
    }
    redirectToUserDetail() {
        let url = Routes.compose(Routes.client.userDetails, { userId: this.state.parentRecordDetail.userId, companyId: this.state.parentRecordDetail.companyId });
        this.props.history.push(url);
    }
    reschedule() {
        if (!this.validate()) {
            alert('Please fill in the required information.')
            return;
        }
        // var date = Dates.fromStr(this.state.selectedDate + ' ' + this.state.selectedTime,`L/d/yyyy t`);
        let date = new Date(this.state.selectedDate + ' ' + this.state.selectedTime).toLocaleString();
        let request = {
            // slotDateTime: date.toISO().replace(':00.000Z',''),
            slotDateTime: date,
            locationId: this.state.locationId,
            paid: true,
            checkedIn: false,
            userId: this.state.parentRecordDetail.userId,
            companyId: this.state.parentRecordDetail.companyId,
            creationSourceId: 1,
            appointmentId: this.state.appointment.appointmentId
        };
        EM.appointments.executePost(request, null, Routes.clientApi.rescheduleAppointment, {}, false).then(() => {
            this.redirectToUserDetail();
        });
    }
    render() {
        return (
            <div key="contents" className="page container-fluid">
                <div className="row">
                    <div className="col-12">
                        <label>Location</label>
                        <select className="form-control" onChange={this.locationChange}>
                            <option value="0">Please Select</option>
                            {this.state.locations.map(loc => <option value={loc.locationId}>{loc.name}</option>)}
                        </select>
                    </div>
                </div>
                <div hidden={!this.state.showMyComponent} className="row mt-3">
                    <div className='col-12'>
                        <AppointmentCalender aptCalender={{ locationId: this.state.locationId }} calendarChange={this.calendarChange} />
                    </div>
                </div>
                <div hidden={this.state.timeSlotHidden===true} className="row">
                    <div className='col-12'>
                        <TimeSlot locationId={this.state.locationId} appointments={this.state.appointments} date={this.state.selectedDate} selectedTime={this.state.selectedTime} timeChange={this.timeChange} />
                    </div>
                </div>
                <Form hidden={!this.state.showContactInfo} className="col-12">
                    <FormGroup>
                        <Row>
                            <Col className="alert alert-secondary mt-2" role="alert"> Contact information</Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>First Name</Label>
                                <Input disabled={true} autoFocus className="form-control" defaultValue={this.state.user.firstName}></Input>
                            </Col>
                            <Col>
                                <Label> Last Name</Label>
                                <Input disabled={true} className="form-control" defaultValue={this.state.user.lastName}></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Phone</Label>
                                <Input disabled={true} type="tel" mask="(999) 999-9999" value={this.state.user.phoneNumber} tag={InputMask}></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Email</Label>
                                <Input disabled={true} className="form-control" defaultValue={this.state.user.emailAddress}></Input>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>

                <div className="row mt-3 mb-3">
                    <div className='col-12'>
                        <button onClick={() => this.reschedule()} className="btn btn-success buttonwidth">Reschedule</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default RescheduleAppointment;