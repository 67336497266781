import React from 'react';

const Spinner = ({text, classes}) => {
    return (
        <div className={'spinner clearfix ' + classes}>
            <div className="loader-container">
                <div className="loader">Loading...</div>
            </div>
            <span id="SpinnerText">{text}</span>
        </div>
    );
};

export default Spinner;