/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import EntityTable from "../components/EntityTables/EntityTable";
import Routes from '../app/Routes';
import utils from '../util/utils'
import PhoneEditor from '../components/PhoneEditor';

class Companies extends Component {
  constructor(props) {
    super(props);    

    this.entity = EM.companies;
    this.state = {
      pageLoad:true
    };   
  }

  componentDidMount(){    
    this.entity.load(true);
  }

  render() {
    let columns = [];
    let data = [];
    if (EM.allLoaded(EM.companies)) {
      data = this.entity.get();
      columns = [     
        {
          dataField: "name",
          text: this.entity.columns("name"),
          validators: [ Validators.required,Validators.length100],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "emailAddress",
          text: this.entity.columns("email"),
          validators: [ Validators.required,Validators.email,Validators.length200],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "address",
          text: this.entity.columns("address"),
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "address2",
          text: this.entity.columns("address2"),
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "phone",
          text:"Phone",
          validators:[Validators.required,Validators.phone],           
          asPhone:true,
          filterOptions: {
            text: true,
          },
          formatter: (cell,row) => {            
            return utils.formatPhoneNumber(cell);
          },
          editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex, onKeyDown, onBlur) => {
            return <PhoneEditor {...editorProps} value={value} column={column} rowIndex={rowIndex} />;
          }
        },
        {
          dataField: "city",
          text: this.entity.columns("city"),
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "state",
          text: this.entity.columns("state"),
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "zip",
          text: this.entity.columns("zip"),
          validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "active",
          text: this.entity.columns("active"),
          asActive: true,
          creatable:false
        },
        {
          dataField: "Config",
          text:"Config",          
          creatable:false,
          formatter: (cell,row) => {            
            return <button className="btn btn-primary" onClick={(event)=>{EM.redirectTo(Routes.client.companyConfig,{companyId:row.companyId});}}><i className="fas fa-cog"></i></button>
        }
      }
      ];
    }
    return (
      <div key="contents" className="page container-fluid">
        <EntityTable
          entity={this.entity}
          data={data}
          columns={columns}
          className="table-overflow-container"
          hideImportExport={true}
          hideAudit ={true}
          onClose={() => {
            this.data = this.entity.get();
          }}
        />
      </div>
    );
  }
}

export default Companies;
