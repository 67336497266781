/* global EM */
import React, { Component } from "react";
import Spinner from "../Spinner";
import { Button} from "reactstrap";
import ConfirmMaziCode from './ConfirmMaziCode'
import Routes from "../../app/Routes";
const initialState= {
  loading: false,
  isPopOpen:false,
  time:false, 
  reservedTime:false,
  blockShow:false,
  slotId:false,
  selectedSlotBlock:[],
  selectedSlotDelete:[]
}
export default class TimeSelection extends Component {
  constructor(props) {
    super(props);    
    this.state = initialState
    this.closePopUp = this.closePopUp.bind(this);
    this.handleValidCode = this.handleValidCode.bind(this);
    this.timeSelected = this.timeSelected.bind(this);
    this.pushSlots = this.pushSlots.bind(this);
    this.isSelectedSlot = this.isSelectedSlot.bind(this);
    this.handleBlockUnblock = this.handleBlockUnblock.bind(this);
    this.handleBlockButton = this.handleBlockButton.bind(this);
  }
  pushSlots(data){
    if(data.available)
    {
      if(!this.state.selectedSlotBlock.includes(data.timeSlot)){
        this.setState({
          selectedSlotBlock: [...this.state.selectedSlotBlock, data.timeSlot]
        })
      } else {
        const selectedSlotBlock = this.state.selectedSlotBlock.filter((prevItem) => prevItem !== data.timeSlot)
        this.setState({
          selectedSlotBlock
        })
      }
    } else {
      if(!this.state.selectedSlotDelete.includes(data.timeSlotId)){
        this.setState({
          selectedSlotDelete: [...this.state.selectedSlotDelete, data.timeSlotId]
        })
      } else {
        const selectedSlotDelete = this.state.selectedSlotDelete.filter((prevItem) => prevItem !== data.timeSlotId)
        this.setState({
          selectedSlotDelete
        })
      }
    }
    
  }
 
  

  closePopUp(){
    this.setState({isOpen:false})
  }
  handleValidCode(){
    this.setState({blockShow:true})
    this.closePopUp()
  }
  timeSelected(time) {
    
    this.props.next(time, this.props.step);
    this.setState(initialState)
  }
  

  isSelectedSlot(data){
    if(this.state.blockShow===false)return false
    const foundInBlockSlot = this.state.selectedSlotBlock.find(el => el === data.timeSlot);
    const foundInDeleteSlot = this.state.selectedSlotDelete.find(el => el === data.timeSlotId);
    if(foundInBlockSlot || foundInDeleteSlot){
      return true
    } else {
      return false
    }
  }
  async handleBlockUnblock(){    
    const self= this
    this.setState({loading:true})
    const selectedSlotBlock = this.state.selectedSlotBlock
    const selectedSlotDelete = this.state.selectedSlotDelete
    if(selectedSlotBlock.length){
      var timeSlots = []
      for(const value of selectedSlotBlock){
        timeSlots.push({
          "locationId": EM.location.get().locationId,
          "timeSlot": `${this.props.dateSelected.strdt} ${value}`
        });
      }
      await EM.availableSlots.executePost({
        timeSlots:timeSlots
      },{},Routes.clientApi.blocktimeslotsMultiple,{}).then((result)=>{
        self.setState({loading:false})
      });
    }
    if(selectedSlotDelete.length){
      await EM.availableSlots.executeDelete({
        timeSlotIds:selectedSlotDelete
      },{},Routes.clientApi.blocktimeslotsMultiple,{}).then((result)=>{
        self.setState({loading:false})
      });
    }

    this.props.loadSlots(this.props.dateSelected.strdt)
    this.setState({
      selectedSlotBlock:[],
      selectedSlotDelete:[],
      blockShow:false
    })
  }
  handleBlockButton(){
    console.log(this.state.blockShow)
    if(this.state.blockShow===false){
      this.setState({isOpen:true})
    } else {
      this.handleBlockUnblock()
    }
    
  }

  render() {
    const { disableEvent = null } = this.props;
    if(this.state.loading) 
    return <Spinner text={EM.t('util.loading')} classes="small"/>
    if (disableEvent || EM.allLoaded(EM.event)) {
      return (
        <div className="step step4">
          <div className="input-group my-3 flex-column">
            <label className="mb-2">Please Select Time ({this.props.date})</label>
          </div>
          
          
          
          {this.props.slots.map((time,index) =>{
            if(time.timeSlotId!==undefined){
             
              // console.log(time.timeSlot)
              return (<div className={`pointer  p-3 d-flex gap-2 align-items-center row ${this.isSelectedSlot(time)?'timeReserved':'border'}`} key={index}  
              onClick={
                this.state.blockShow===true?(event) => { this.pushSlots(time) }:(event) =>{if(time.available === true)this.timeSelected(time.timeSlot)}
              }
              >
                <div className="mr-auto align-items-center ">
              <h6 className="mb-0" >{time.timeSlot}</h6>
            </div>
            {this.state.blockShow===true && <div className="ml-auto">
                  {
                    (time.available === false)
                      ? <div className="booked py-2 px-3 text-nowrap ms-auto fw-bolder text-uppercase">Unblock</div>
                      : <div onClick={() => {
                      }} className="available py-2 px-3 text-nowrap ms-auto fw-bolder text-uppercase" >Block</div>
                  }
                </div>}
                {this.state.blockShow===false && time.available===false && <div className="ml-auto">
                  {
                    <div className="booked py-2 px-3 text-nowrap ms-auto fw-bolder text-uppercase">Not Available</div>                    
                  }
                </div>}
                
            </div>)
            } else {
              return <div key={index} className="border p-3 d-flex gap-2 align-items-center pointer" onClick={(event) => { this.timeSelected(time); }}>
              <h6 className="mb-0" >{time}</h6>
            </div>
            }
            
          })}
          <ConfirmMaziCode prefix={'88'} isValid={(data)=>{if(data==true)this.handleValidCode()}} isOpen={this.state.isOpen} onClose={this.state.closePopUp} close={this.closePopUp}/>
          <Button className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" onClick={()=>{this.props.prev({},this.props.step)}}>Back</Button>
          {this.props.mazibit &&<div className="input-group my-3 flex-column">
            <button className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2 btn btn-secondary" onClick={()=>this.handleBlockButton()}>{this.state.blockShow===false?'Block/UnBlock':'Submit Selected'}</button>
          </div>}
        </div>
      );
      
    }
    return (
      <div className={this.props.className}>
        <Spinner text={EM.t("util.loading")} classes="small" />
      </div>
    );
  }
}
