/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import EntityTable from "./EntityTables/EntityTable";
import { Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { FaShareAlt, FaUnlockAlt, FaEye, FaRegListAlt, FaDownload } from "react-icons/fa";
import PanelModal from './PanelModal';
import InputMask from 'react-input-mask';
import Dates from '../util/Dates'
import _ from 'underscore';
import Routes from '../app/Routes'

class UserSessions extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.userSessions;
    this.rootContainer = 'https://flashopstorage.blob.core.windows.net/images/';
    this.state = {
      parentRecordDetail: { userId: this.props.data.id, companyId: this.props.data.companyId },
      sessions : [],
      showView: false,
      showUnlock: false,
      showImages: false,
      phoneNumber: '',
      price: '',
      userImages: [],
      selectedSession:null
    }
   
    this.shareSession = this.shareSession.bind(this);
    this.unlockSession = this.unlockSession.bind(this);
    this.clearAllSessions = this.clearAllSessions.bind(this);
  }

  componentDidMount(){
    let self = this;
    self.entity.loadEntityByParent(this.state.parentRecordDetail,true).then(()=>{this.setState({sessions:self.entity.get()})});
  }

  handleShowView(session) {
    this.setState({ showView: !this.state.showView,selectedSession:session });
  }
  handleShowUnlock(session) {
    this.setState({ showUnlock: !this.state.showUnlock,selectedSession:session });
  }
  handleViewImage() {
    this.setState({ showImages: !this.state.showImages });
  }
  imageCount() {
    return this.state.userImages?.length;
  }
  shareSession(session) {
    var self = this;
    var request = {
      fromUserId:self.props.user.userId,
      toPhone: self.state.phoneNumber,
      userSessionId: this.state.selectedSession.userSessionId,
      companyId: this.state.selectedSession.companyId
    }
    EM.userSessions.executePost(request,null,Routes.clientApi.shareSession, {},false).then(()=>{self.setState({showView:false})});
  }
  unlockSession() {
    var self = this;
    var request = {
      sessionId:self.state.selectedSession.userSessionId,
      price:self.state.price
    }
    EM.userSessions.executePost(request,null,Routes.clientApi.unlockSession, {},false).then(()=>{
      let sessions = [...self.state.sessions];
      var index = _.indexOf(sessions,self.state.selectedSession);
      sessions[index] = Object.assign({},self.state.selectedSession,{boughtDigitalSession:true})
      self.setState({showUnlock:false,sessions});
    });   
  }

  textSession(session){
    var self = this;
    var request = {
      sessionId:session.userSessionId,
      userId:self.props.user.userId
    }
    EM.userSessions.executePost(request,null,Routes.clientApi.textSession, {},false);   
  }

  downloadSession(data) {
    window.location.href = "https://fcaf.azurewebsites.net/api/session/" + data.blobId;
  }

  clearAllSessions() {
    let self =this;
    let sessions = _.pluck(_.filter(this.state.sessions,(session)=>{return session.published;}),'userSessionId');
    var updateSession = {
      published: false,
      userSessionIds: sessions
    }
    this.entity.update(updateSession, updateSession, this.state.parentRecordDetail).then(() => {
      self.setState({sessions:[]});
    });
  }

  clearSession(session) {
    let self =this;
    let sessionIds = [];
    sessionIds.push(session.userSessionId);
    var updateSession = {
      published: false,
      userSessionIds: sessionIds
    }
    this.entity.update(updateSession, updateSession, this.state.parentRecordDetail).then(() => {
      self.entity.loadEntityByParent(this.state.parentRecordDetail,true).then(()=>{this.setState({sessions:self.entity.get()})});

      // let sessions = _.filter([...self.state.sessions],(ses)=>{return ses.userSessionId !== session.userSessionId;});     
      // console.log('sessions filtered :>> ', sessions);
      // this.setState({sessions});
    });
  }

  getSessionImages(data) {
    EM.userSessionImage.loadEntityByParent({ id: data.userSessionId }).then(() => {
      let userImages = EM.userSessionImage.get();
      this.setState({ userImages: userImages, showImages: !this.state.showImages });
    });
  }

  render() {
    let self = this;
    let columns = [];
    let data = [];
    if (EM.allLoaded(this.entity)) {
      // data = _.filter([...this.state.sessions],(session)=>{return session.published ===true;});
      data = _.filter([...this.entity.get()],(session)=>{return session.published ===true;});
      columns = [
        {
          dataField: "createdDate",
          text: this.entity.columns("createdDate"),
          editable: false,
          validators: [Validators.required],
          creatable: false,
          filterOptions: {
            text: true,
          },
          formatter: (cell, row) => {
            return Dates.fromISO(cell).toFormat("MM/dd/yyyy")
          }
        },
        {
          dataField: "name",
          text: this.entity.columns("name"),
          editable: false,
          validators: [Validators.required, Validators.length100],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "companyId",
          text: this.entity.columns("createdBy"),
          editable: false,
          validators: [Validators.required],
          creatable: false,
          formatter:(cell,row)=>{
            var company = _.findWhere(self.props.user.userCompanies,{companyId:cell});
            return company ? company.companyName : '';
          },
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "promoSelected",
          text: this.entity.columns("promoSelected"),
          editable: false,
          asActive:true,
          validators: [Validators.required],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "boughtDigitalSession",
          text: this.entity.columns("boughtDigitalSession"),
          asActive: true,
          editable: false,
          filterOptions: {
            text: true,
          },
        },       
        {
          dataField: "expiryDate",
          asDateTime: true,
          text: this.entity.columns("expiryDate"),
          // validators: [ Validators.required],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "unlock",
          text: "",
          creatable: false,
          editable: false,
          width:300,
          formatter: (cell, row) => {
            return (<div>
                      <button title="Share" onClick={() => self.handleShowView(row)} className="btn btn-primary"><FaShareAlt /></button>
                      <button title="Unlock Session" onClick={(event) => { self.handleShowUnlock(row); }} className="btn btn-danger"><FaUnlockAlt></FaUnlockAlt></button>
                      <button title="Text Session" onClick={(event) => { self.textSession(row); }} className="btn btn-danger"><FaRegListAlt /></button>
                      <button title="View" onClick={(event) => { self.getSessionImages(row); }} className="btn btn-primary"><FaEye /></button>
                      { EM.isSuperAdmin() || EM.isAdmin() ?
                      <button title="Download" onClick={(event) => { self.downloadSession(row); }} className="btn btn-primary"><FaDownload /></button>
                      :null
                    }
                      <button title="Delete" onClick={(event) => { self.clearSession(row); }} className="btn btn-danger"><i className="fa fa-remove"></i></button>
                  </div>);
          }
        }
      ];
    }
    return (
      <div key="contents" className="page container-fluid mt-4">
        <EntityTable
          collapsible={true}
          collapsed = {false}
          entity={this.entity}
          data={data}
          parentRecordDetail={this.state.parentRecordDetail}
          columns={columns}
          className="table-overflow-container-auto"
          hideImportExport={true}
          hideAudit={true}
          hideAddRecord={true}
          hideDelete={true}
          hideSearch={true}
          hideFilters={true}
        />
        <PanelModal isOpen={this.state.showView}>
          <ModalHeader>Share</ModalHeader>
          <ModalBody>
            <Form className="form-create">
              <FormGroup className="form-group">
                <Label>
                  Phone Number:
                </Label>
                <Input key="ctrl" type="tel" mask="(999) 999-9999" tag={InputMask} onChange={(event) => {
                  this.setState({ phoneNumber: event.target.value })
                }}>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
          <Button color="primary" onClick={() => { self.shareSession() }}>Share Session</Button>
            <Button color="secondary" onClick={() => { self.handleShowView() }}>Cancel</Button>            
          </ModalFooter>
        </PanelModal>

        <PanelModal isOpen={this.state.showUnlock}>
          <ModalHeader>Unlock</ModalHeader>
          <ModalBody>
            <Form className="form-create">
              <FormGroup className="form-group">
                <h3>Are you sure you would like to unlock this session?</h3>
              </FormGroup>
              <FormGroup className="form-group">
                <Label>
                  Please enter what was charged for this session
                </Label>
                <Input type="number" onChange={(event) => { this.setState({ price: event.target.value }) }}>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
          <Button color="primary" onClick={() => { self.unlockSession() }}>Yes</Button>
            <Button color="secondary" onClick={() => { self.handleShowUnlock() }}>No</Button>           
          </ModalFooter>
        </PanelModal>

        <PanelModal isOpen={this.state.showImages}>
          <ModalHeader>Session ({this.imageCount()} Images)</ModalHeader>
          <ModalBody>
            <Form className="form-create">
              <FormGroup className="form-group userImages">
                {_.sortBy(self.state.userImages,'orderNumber').map(image => {
                  return (
                    <div className="m-1"><img src={image.thumbnailUrl ? image.thumbnailUrl : (image.originalImageUrl ?image.originalImageUrl : '')} className='img-fluid shadow-4' alt='...' /></div>
                  );
                })}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => { self.handleViewImage() }}>Close</Button>
          </ModalFooter>
        </PanelModal>

        <button hidden={_.filter(this.state.sessions,(session)=> session.published ).length<=0}  className="btn btn-danger" title="Clear Sessions" onClick={() => { self.clearAllSessions() }}>Clear Sessions</button>
      </div>
    );
  }
}

export default UserSessions;
