import React, { Component } from 'react';

export default class CalendarDay extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            slots: []
        };
    }

    BuildComponent(slot)
    {
        return (<tr><td>{slot.slot} ({slot.firstName} {slot.lastName})</td></tr>);
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.slots !== props.slots) {

          return {
            slots: props.slots
          }
        }
        return null
      }

    render() {   
        return (
            <div>
            <table className="table table-bordered">
              <tr>
                  <th>
                      Date
                  </th>
              </tr>
              {this.state.slots.map(loc => this.BuildComponent(loc))}
            </table>
            </div>
          );     
    }
}