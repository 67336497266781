/* global EM */
/* eslint jsx-a11y/anchor-is-valid: "off" */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Routes from "../app/Routes";
import { matchPath } from 'react-router';
import UserIcon from './UserIcon';
import _ from 'underscore';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink as RsNavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';



class Header extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            allowAnonymous: this.props.allowAnonymous,
            companies: []
        };

        this.closeMenus = this.closeMenus.bind(this);
        if (!EM.history) EM.setHistory(this.props.history);
    }

    componentDidMount() {
        // Regular expression pattern to match "/booking/" followed by a number
        const pattern = /\/booking\/(\d+)/;
        const patternRescheduleAppointment = /\/rescheduleAppointmentBooking\/(\d+)/;
        // Check if the string matches the pattern
        const match = this.props.location.pathname.match(pattern) || this.props.location.pathname.match(patternRescheduleAppointment)
        if(match){
            return
        }
        var companies = EM.companies.get();
        if (companies && companies.length > 0) this.setState({ companies });
        else {
            EM.companies.load().then(() => {
                this.setState({ companies: EM.companies.get() });
            })
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    closeMenus() {
        var elem = document.getElementById('root');
        if (elem) elem.click();
        this.setState({ isOpen: false });
    }

    getCompanyId() {
        let user = EM.account.get();
        let company = _.findWhere(user.userCompanies, { defaultCompany: true }) || user.userCompanies[0];
        return company.companyId;
    }

    getDefaultCompany() {
        let user = EM.account.get();
        let company = _.findWhere(user.userCompanies, { defaultCompany: true }) || user.userCompanies[0];
        return company;
    }

    changeCompany(companyId) {
        console.log(`change company : ${companyId}`);
        let user = EM.account.get();
       EM.account.executePut({ userId: user.userId, companyId: companyId }, null, Routes.clientApi.changeDefaultCompany, {},true).then((account) => {
            EM.storage.set('account',account);
            if(window.location.pathname.indexOf('/companyConfig/')===0){
                EM.redirectTo(Routes.client.companyConfig,{companyId:companyId})
                window.location.reload();
            } else {
                window.location.reload();
            }
        });
    }

    render() {
        const isDashboard = !!matchPath(this.props.location.pathname, Routes.client.dashboard);
        const allowAnonymous = this.state.allowAnonymous;
        if (allowAnonymous) return "";
        const companyId = this.getCompanyId();
        const defaultCompany = this.getDefaultCompany();
        const isSuperAdmin = EM.isSuperAdmin();
        const isAdmin = EM.isAdmin();
        const isSales = EM.isSales();
        const isPhotographer = EM.isPhotographer();

        const companies = this.state.companies;
        return (
            <Navbar className={`mb-3 admin ${isSuperAdmin?'expandNavHeightAdmin':''}`} color="dark" dark expand="md">
                <NavLink exact={true} className={"navbar-brand " + (this.props.domain ? 'small' : '')} to="/" onClick={() => {
                    // EM.clearActiveDomain();
                }}>
                    {/* <img src={Logo} alt="Logo" /> */}
                </NavLink>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    {this.props.domain ?
                        <div className="domain-header">
                            <NavLink to="/" onClick={() => {
                                // EM.clearActiveDomain();
                            }}>{this.props.account.Tenant.DisplayName}</NavLink>
                            <div>{this.props.domain.DisplayName}</div>
                        </div>
                        : null}

                    <Nav className="navbar-nav mr-auto nav-main domain-menu" hidden={this.state.allowAnonymous}>
                        <NavItem hidden={!isSuperAdmin && !isAdmin} >
                            <NavLink exact={true} className={({ isActive }) =>
                                isActive ? "nav-link active" : "nav-link"
                            } to={Routes.compose(Routes.client.home)}>
                                {EM.t('header.dashboard')}
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isSuperAdmin} >
                            <NavLink exact={true} className={({ isActive }) =>
                                isActive ? "nav-link active" : "nav-link"
                            } to={Routes.compose(Routes.client.companies)}>
                                {EM.t('header.companies')}
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isSuperAdmin && !isAdmin} >
                            <NavLink exact={true} className={({ isActive }) =>
                                isActive ? "nav-link active" : "nav-link"
                            } to={Routes.compose(Routes.client.eventGroups)}>
                                Groups
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isSuperAdmin && !isAdmin} >
                            <NavLink exact={true} className={({ isActive }) =>
                                isActive ? "nav-link active" : "nav-link"
                            } to={Routes.compose(Routes.client.products)}>
                                Products
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isAdmin && !isSuperAdmin}>
                            <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.locations)}>
                                Events
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isAdmin && !isSuperAdmin}>
                            <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.events, { companyId })}>
                                Promotions
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={isSales}>
                            <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.calendar)}>
                                Calendar
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isAdmin && !isSuperAdmin}>
                            <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.companyConfig, { companyId })}>
                                Services
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink hidden={isSales} exact={true} className={"nav-link "} to={Routes.compose(Routes.client.downloads)}>
                                Downloads
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink hidden={isSales || isPhotographer} exact={true} className={"nav-link "} to={Routes.compose(Routes.client.users)}>
                                {EM.t('header.users')}
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isAdmin && !isSuperAdmin}>
                            <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.printOrders, { userId: 8 })}>
                                {EM.t('header.orderFulfillment')}
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isSuperAdmin}>
                            <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.presets)}>
                                {EM.t('header.presets')}
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isAdmin && !isSuperAdmin}>
                            <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.export)}>
                                {EM.t('header.export')}
                            </NavLink>
                        </NavItem>
                        <NavItem hidden={!isSales}>
                            <NavLink exact={true} className={"nav-link "} to={Routes.compose(Routes.client.sales)}>
                                {'Pre-Sales'}
                            </NavLink>
                        </NavItem>

                    </Nav>

                    <Nav className="navbar-nav flex-row nav-util tighter" hidden={this.state.allowAnonymous}>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                <UserIcon user={this.props.account} />
                                <span className="mobile-only">{this.props.account?.firstName + ' ' + this.props.account?.lastName ?? ''}</span>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {isSuperAdmin===true && companies && companies.length > 0
                                    ? <UncontrolledDropdown className={"widthp100"} key={"companyChange"} >
                                        <DropdownToggle className={"widthp100"}>
                                            <div>
                                                <span>{defaultCompany ? defaultCompany.companyName : ''}</span>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {
                                                companies.map((company) => {
                                                    return (
                                                        <a
                                                            key={company.companyId}
                                                            className="dd-link"
                                                            onClick={() => { this.changeCompany(company.companyId) }}
                                                        >
                                                            {company.name}
                                                        </a>);
                                                })
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    : ''
                                }

                                <a key="" className="dd-link" onClick={() => { }}>
                                    {this.props.account ? `${this.props.account.firstName ? this.props.account.firstName : ''} ${this.props.account.lastName ? this.props.account.lastName : ''}` : ''}
                                </a>
                                <a className="dd-link" onClick={EM.authenticator.logout}>
                                    {EM.t('header.logout')}
                                </a>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }


}

export default Header;