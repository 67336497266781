/* global EM */
import React, { Component } from "react";
import _ from 'underscore';
import addDays from 'date-fns/addDays'
import Routes from '../app/Routes';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CSV from '../util/CSV';
import PageTitle from '../components/PageTitle';
import { Button, FormGroup, Label } from "reactstrap";
import {Tabs, Tab} from 'react-bootstrap';
import LabBilling from '../components/LabBilling';
import Select from 'react-select';

class Export extends Component {
    constructor(props) {
        super(props);
        this.entity = EM.export;
        let user = EM.account.get();
        let company = _.findWhere(user.userCompanies, { defaultCompany: true });

        this.state = {
            parentRecordDetail: { companyId: company.companyId },
            company:company,
            aptEndDate: '',
            aptStartDate: '',
            endDate: new Date(),
            startDate: new Date(),
            locations: [],
            maxDate: new Date(),
            minDate: addDays(new Date(), -10000),
            locationIds:[],
            isSuperAdmin:EM.isSuperAdmin(),
            tabKey:'appointmentsExport'
        }
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.export = this.export.bind(this);
        this.locationChange = this.locationChange.bind(this);
    }
    componentDidMount() {
        this.setState({companyName:EM.getDefaultCompany().companyName})  
        var self = this;
        EM.locations.loadEntityByParent(this.state.parentRecordDetail).then(() => {
            self.setState({ locations: EM.locations.get() });
        });
    }
    formattedDateLong(date) {
        let strReturn = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
        return strReturn;
    }
    endDateChange(date) {
        var self = this;
        self.setState({ endDate: date, maxDate: date });
    }
    startDateChange(date) {
        var self = this;
        self.setState({ startDate: date, minDate: date });
    }
    // locationChange = (event) => {        
    //     var self = this;
    //     self.setState({locationId:event.target.value});       
    // }

    export(){
        var self = this;
        var request = {
            locationIds: self.state.locationIds,
            startDate: self.state.startDate.toISOString().split('T')[0],
            endDate: self.state.endDate.toISOString().split('T')[0]
        }
        EM.export.executePost(request, null, Routes.clientApi.exportwithEventsList, {}, true).then((res) => {
            // this.setState({locationIds:[]})
            CSV.save('exportData', CSV.fromString(res));
        });
    }

    handleSelect = (tabKey) => {
        this.setState({ tabKey });
    }

    locationChange(values){
        let items = values ? values.map(item => { return item.value }) : [];
        this.setState({locationIds:items})
    }

    render() {
        let self = this;
        return (
            <div key="contents" className="page container-fluid">              
                <PageTitle title={`Export ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`} bar={true}></PageTitle>    
                <Tabs
                  activeKey={this.state.tabKey}
                  onSelect={this.handleSelect}
                >
                    <Tab                   
                    eventKey="appointmentsExport"
                    title="Appointments Export"/>
                    <Tab eventKey="salesLabCostExport" title="Sales/Lab Cost Export" />
                </Tabs>      
                
                 {this.state.tabKey === "appointmentsExport" &&                          
                    <div>
                        <div className="row">
                    <div className="col-2">
                        <FormGroup>
                            <Label>Start Date</Label>
                            <DatePicker
                                selected={self.state.startDate}
                                onChange={self.startDateChange}
                                dateFormat="MM/dd/yyyy"
                                minDate={addDays(new Date(), -10000)}
                                maxDate={self.state.maxDate}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup>
                            <Label>End Date</Label>
                            <DatePicker
                                selected={self.state.endDate}
                                onChange={self.endDateChange}
                                dateFormat="MM/dd/yyyy"
                                minDate={self.state.minDate}
                                maxDate={new Date()}
                            />
                        </FormGroup>
                    </div>

                        </div>
                        <div className="row">
                    <div className="col-4">
                        <FormGroup>
                            <Label>Event</Label>
                            <Select
                                className="multi-select"
                                classNamePrefix="multi-select"
                                isSearchable={true}
                                isMulti={true}
                                options={EM.locations.asOptionList(null, true)}
                                onChange={this.locationChange}
                            />
                            {/* <select className="form-control" onChange={self.locationChange}>
                                <option>Please Select</option>
                                {self.state.locations.map(loc => <option value={loc.locationId}>{loc.name}</option>)}
                            </select> */}
                        </FormGroup>
                    </div>
                        </div>
                        <div className="row">
                    <div className="col-2">
                        <Button value="Export" onClick={()=>{this.export();}} className="btn btn-primary">Export</Button>                    
                    </div>
                        </div>
                    </div>
                }
                {this.state.tabKey === "salesLabCostExport" &&
                    <div>
                        <LabBilling locations={self.state.locations} company={this.state.company}></LabBilling>
                    </div>
                        }
            </div>
        )
    }
}
export default Export;