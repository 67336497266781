/* global EM */
import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default class EntityColumnConfig extends Component {
    constructor(props) {        
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    onClose() {
        this.props.onClose();
    }

    render() {
        let { columns, onColumnToggle, toggles } = this.props.columnToggleProps;
        return (
            <Modal fade={false} isOpen={this.props.isOpen} toggle={this.onClose} className={'modal-overflow'} key="column-modal">
                <ModalHeader toggle={this.onClose}>{EM.t('util.table.configureColumns')}</ModalHeader>
                <ModalBody>
                    <div className="table-toggle btn-group" data-toggle="buttons">
                        {
                        columns
                            .map(column => ({
                                ...column,
                                toggle: toggles[column.dataField]
                            }))
                            .map(column => {
                                return (
                                    <button
                                        type="button"
                                        key={ column.dataField }
                                        className={ `btn btn-light ${column.toggle ? 'active' : ''}` }
                                        data-toggle="button"
                                        aria-pressed={ column.toggle ? 'true' : 'false' }
                                        disabled={column.unhideable}
                                        onClick={event => {
                                            if (column.unhideable)return;
                                            onColumnToggle(column.dataField);
                                            event.target.blur();
                                            this.props.onColumnToggle(toggles);
                                        }}
                                    >
                                        { column.text }
                                    </button>
                                )
                            })
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
