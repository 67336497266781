/* eslint no-extend-native: "off" */

export default {
    formatPhoneNumber: function (phoneNumberString) {
       // Remove all non-digit characters
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  
  // Extract the necessary parts
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
  // If the phone number doesn't match the expected format, return original
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  
  return phoneNumberString;
    }
}