/* global EM */
import React, { Component } from "react";
import Dates from '../util/Dates';

class FutureEvents extends Component {
  constructor(props) {
    super(props);    
    this.entity = EM.futureEvents;

    let company = EM.getDefaultCompany();

    this.state = {
      parentRecordDetail:{companyId:company.companyId},
    };
    this.entity.loadEntityByParent(this.state.parentRecordDetail);
  }

  render() {
    let data = [];
    let errMsg = ''
    
    if (EM.allLoaded(EM.companies)) {
      data = this.entity.get();
      if(!data?.length) errMsg= "No Record Found"
    }
    return (
      <div key="contents" className="page container-fluid">
        <div className="d-flex align-items-center justify-content-center mb-2">
          <button className="mr-auto btn btn-primary buttonwidth" onClick={()=>this.props.history.goBack()}><i className="fas fa-light fa-chevron-left"></i></button>
          <h2 className="mr-auto mb-0">Future Events Details</h2>
        </div>
        {data?.length > 0 ? data.map((row) => (
        <div className="row mt-2" key={row.locationId}>
          <div className="col-12 mb-2">
              {/* <div style={{ backgroundColor: '#f5f6fa' }}><h5>Order {row.name}</h5></div> */}
              <div className="card" style={{ border: '1px solid'}}>
              <div className="card-body">
              <table className="table table-bordered mb-0">
                  <thead>
                      <tr style={{ backgroundColor: "#ecf0f1" }}>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Free 8x10 Promotion	</th>
                        <th>Free Image Promotion</th>
                        <th>Force 8x10 Promotion</th>
                        <th>Force Image Promotion</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td style={{ paddingTop: '1rem' }}>{row.locationId}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.name}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.free810Promotion?"Yes":"No"}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.freeImagePromotion?"Yes":"No"}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.force810Promotion?"Yes":"No"}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.forceImagePromotion?"Yes":"No"}</td>
                      </tr>

                      <tr>
                        <th colSpan="6" className="border-0"  style={{ textAlign: 'center' }}>Time Slot By Day</th>
                      </tr>
                    <tr>
                      <th colSpan="4" style={{ textAlign: 'center' }}>
                        Date
                      </th>
                      <th colSpan="3" style={{ textAlign: 'center' }}>
                        Counts
                      </th>
                    </tr>

                      {row.timeSlotsCounts.map((value, index) => (
                      <tr key={index}>
                          <td colSpan="4" style={{ textAlign: 'center' }}>{Dates.convertDateStringWithT(value.timeSlot)}</td>
                          <td colSpan="3" style={{ textAlign: 'center' }}>{value.counts}</td>
                      </tr>
                        ))}   
                  </tbody>
              </table>
          </div>
          </div>
          </div>
      </div>
       )):<div className="col-md-12 text-center">
       <h6 className="text-danger">{errMsg}</h6>
     </div>}
        {/* <table className="table table-bordered mt-4">
        <thead>
          <tr>
              <th>#</th>
              <th>Name</th>
              <th>Free 8x10 Promotion	</th>
              <th>Free Image Promotion</th>
              <th>Force 8x10 Promotion</th>
              <th>Force Image Promotion</th>
          </tr>
          </thead>
          {data.length > 0 && data.map((row, index) => (
            <tbody key={row.locationId}>
            <tr>
              <th style={{ paddingTop: '1rem' }}>{index+1}</th>
              <td style={{ paddingTop: '1rem' }}>{row.name}</td>
              <td style={{ paddingTop: '1rem' }}>{row.free810Promotion?"Yes":"No"}</td>
              <td style={{ paddingTop: '1rem' }}>{row.freeImagePromotion?"Yes":"No"}</td>
              <td style={{ paddingTop: '1rem' }}>{row.force810Promotion?"Yes":"No"}</td>
              <td style={{ paddingTop: '1rem' }}>{row.forceImagePromotion?"Yes":"No"}</td>
            </tr>
            {row.timeSlotsCounts.length?(
              <>
                <tr style={{border: "2px solid"}}>
                  <th colSpan="6" className="border-0"  style={{ textAlign: 'center' }}>Time Slot By Day</th>
                </tr>
              <tr style={{border: "2px solid"}}>
                <th colSpan="3" style={{ textAlign: 'center' }}>
                  Date
                </th>
                <th colSpan="3" style={{ textAlign: 'center' }}>
                  Count
                </th>
              </tr>
              {row.timeSlotsCounts.map((value, index) => (
              <tr key={index} style={{border: "2px solid"}}>
                  <th colSpan="3" style={{ textAlign: 'center' }}>{Dates.isoToDateTime(value.timeSlot, "YYYY-MM-DD hh:mm A")}</th>
                  <th colSpan="3" style={{ textAlign: 'center' }}>{value.counts}</th>
              </tr>
                ))}
              </>
            ):null}
            </tbody>
          ))}
      </table> */}
      </div>
    );
  }
}

export default FutureEvents;
