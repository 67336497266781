import { CKEditor } from 'ckeditor4-react';

function CKEditorInput(props) {
      const initializeEditor = (editor) => {
        if (props.value) {
          editor.setData(props.value);
        }
      };

    return (
        <div>
            <CKEditor
            
            onChange={(e) => props.onChange(e.editor.getData())}
            editorUrl="https://cdn.ckeditor.com/4.18.0/standard-all/ckeditor.js"
            config={{
              versionCheck: false,
                on: {
                  instanceReady: (event) => {
                    initializeEditor(event.editor);
                  },
                },
              }}
            />
            <p>Add Mobile app url: https://flashop.page.link/openapp</p>
            <h6>Add tags in the email body</h6>
            <div>
              <strong>Promotion Link:</strong> <span style={{ marginLeft: '6px' }}>{"{promotion_link}"}</span> <em>This tag will be replaced by actual promotion link in the email</em><br/>
              <strong>Cart first image Link:</strong> <span style={{ marginLeft: '6px' }}>{"{cart_image_link}"}</span> <em>This tag will be replaced by actual first image link in the email</em><br/>
              <strong>Event Name:</strong> <span style={{ marginLeft: '6px' }}>{"{event_name}"}</span> <em>This tag will be replaced by actual event name in the email</em><br/>
              <strong>Add First Name of User:</strong> <span style={{ marginLeft: '6px' }}>{"{first_name}"}</span> <em>This tag will be replaced by actual first name of the actual user in the email</em><br/>
              <strong>Add Last Name of User:</strong> <span style={{ marginLeft: '6px' }}>{"{last_name}"}</span> <em>This tag will be replaced by actual last name of the actual user in the email</em><br/>
              <strong>Add Time slot of Appointment:</strong> <span style={{ marginLeft: '6px' }}>{"{time_slot}"}</span> <em>This tag will be replaced by actual time slot of the actual user's appointment in the email</em>
            </div>
        </div>
    );
}

export default CKEditorInput;
