/* global EM */
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Button } from "reactstrap";
import Routes from '../app/Routes';
import { Link } from 'react-router-dom';

export default class AppointmentOptions extends Component {
    constructor(props) {
        super(props);

        this.IDhange = this.IDhange.bind(this);
        this.TypeChange = this.TypeChange.bind(this);

        this.state =
        {
            m_bPleaseWait: false,
            m_bCanceled: false,
            m_bShowConfirm: false,
            m_strID: "",
            m_strType: "",
            m_strAddress: "",
            data: this.props.data,
            m_strErrorMessage: ""
        };
    }

    IDhange(event) {
        this.setState({ m_strID: event.target.value });
    }

    TypeChange(event) {
        this.setState({ m_strType: event.target.value });
    }

    CancelChecking() {
        this.setState({ m_bShowConfirm: false });
    }

    FirstNameChange = (event) => {
        let capt = this.state.data;
        capt.firstName = event.target.value;
        this.setState({ data: capt });
    }

    LastNameChange = (event) => {
        let capt = this.state.data;
        capt.lastName = event.target.value;
        this.setState({ data: capt });
    }

    PhoneChange = (event) => {
        let capt = this.state.data;
        capt.phoneNumber = event.target.value;
        this.setState({ data: capt });
    }

    BackupPhoneChange = (event) => {
        let capt = this.state.data;
        capt.backupNumber = event.target.value;
        this.setState({ data: capt });
    }

    AddressChange = (event) => {
        let capt = this.state.data;
        capt.address1 = event.target.value;
        this.setState({ data: capt });
    }

    CityChange = (event) => {
        let capt = this.state.data;
        capt.city = event.target.value;
        this.setState({ data: capt });
    }

    StateChange = (event) => {
        let capt = this.state.data;
        capt.state = event.target.value;
        this.setState({ data: capt });
    }

    ZipChange = (event) => {
        let capt = this.state.data;
        capt.zip = event.target.value;
        this.setState({ data: capt });
    }

    EMailChange = (event) => {
        let capt = this.state.data;
        capt.eMail = event.target.value;
        this.setState({ data: capt });
    }

    ShowError() {
        if (this.state.m_strErrorMessage != "") {
            return <div className="row">
                <div className="col">
                    <div className="alert alert-danger" role="alert">
                        {this.state.m_strErrorMessage}
                    </div>
                </div>
            </div>
        }
    }

    CheckinView() {
        if (this.state.m_bShowConfirm)
            return <div className="card">
                <div className="card-body">

                    {this.ShowError()}

                    <div className="row">

                        <table>
                            <tr>
                                <td>Phone</td>
                            </tr>
                            <tr>
                                <td><InputMask className="form-control" value={this.state.data.phoneNumber} onChange={this.PhoneChange.bind(this)} mask="(999) 999-9999" maskChar=" " /></td>                               
                            </tr>
                        </table>

                    </div>
                    <div className="row">
                        <div className="col-*-*">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-*-*"><button onClick={() => this.patchCheckIn(true)} className="btn btn-success button m-1">Save</button></div>
                    </div>
                </div>
            </div>
    }

    patchCheckIn(checkedIn) {
        let self = this;
        EM.calendar.executePut({ appointmentId: this.state.data.appointmentID, propertyName: "CheckedIn", checkedIn: checkedIn,phoneNumber:this.state.data.phoneNumber }, 
        null, Routes.clientApi.patchAppointment, {})
            .then((result) => {
                if(result) {
                    let data = Object.assign({}, self.state.data, { checkedIn: checkedIn });
                    self.setState({ data: data });
                    self.props.onCheckInChange(this.state.data,checkedIn);
                }
            });
    }

    RenderRescheduleButton() {
        if (this.state.data.checkedIn !== true) {
            let url=''
            if(this.props.bookingPage) {
                url = Routes.compose(Routes.client.rescheduleAppointmentBooking, { userId: this.state.data.userID, companyId: this.state.data.location?.companyID, appointmentId: this.state.data.appointmentID, locationId: this.props.data.location.id});
            } else {
                url = Routes.compose(Routes.client.rescheduleAppointment, { userId: this.state.data.userID, companyId: this.state.data.location?.companyID, appointmentId: this.state.data.appointmentID });
            }
            return (<Button onClick={() => {
                EM.redirectTo(url); }} 
                className="btn btn-danger button Width100 m-1">Reschedule</Button>)
        }
    }

    RenderConfirmButton() {
        if (!this.state.data.confirmed)
            return (<button onClick={() => this.confirm()} className="btn btn-success button Width100 m-1">Confirmed</button>);
    }

    RenderSitting() {
        if (this.state.data.sitting != "")
            return <div><b>SN:</b> {this.state.data.sitting}</div>;
    }

    RenderCancelButton() {
        // if(EM.isAdmin() || EM.isSuperAdmin())
        return (<button onClick={() => this.cancel()} className="btn btn-danger button Width100 m-1">Cancel</button>);
    }

    confirm() {      
        let self = this;
        EM.calendar.executePut({ appointmentId: this.state.data.appointmentID, propertyName: "Confirmed", confirmed: true }, null, Routes.clientApi.patchAppointment, {})
            .then((result) => {   
                if(result) {
                    let data = Object.assign({}, self.state.data, { confirmed: true });
                    self.setState({ data: data });
                }
            })
    }

    cancel() {
        let self = this;
        EM.calendar.executePut({ appointmentId: this.state.data.appointmentID, propertyName: "Cancelled", cancelled: true }, null, Routes.clientApi.patchAppointment, {})
            .then((result) => {
                if(result) {
                    let data = Object.assign({}, self.state.data, { canceled: true });
                    self.setState({ data: data });
                }
            });       
    }


    render() {
        console.log(`canceled:${this.state.data.canceled}`)
        if (this.state.data.canceled) {
            return (
                <div className="container btn btn-danger">
                    This has been canceled!
                </div>
            )
        }

        if (this.state.data.checkedIn) {
            return (
                // <div className="container">
                //     <div className="row">
                //         <div className="col-*-*">{this.RenderSitting()} 
                        <button onClick={() => this.patchCheckIn(false)} className="btn btn-primary button Width100 m-1">Walkout</button>
                //         </div>
                //     </div>
                // </div>
            )
        }
        else
            return (
                <span>
                        {this.RenderRescheduleButton()}
                        {!this.props.bookingPage ? (
                            <button onClick={() => this.patchCheckIn(true)} className="btn btn-success button Width100 m-1">Checkin</button>
                        ):null}
                        {this.RenderConfirmButton()}
                        {this.RenderCancelButton()}
                    <div className="row">
                        {this.CheckinView()}
                    </div>
                </span>
            );
    }
}