/* global EM */
import React, { Component } from 'react';
import Creatable from 'react-select/creatable';

export default class WorkItemSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            currentValue: this.props.value || ''
        }
    }

    componentDidMount(){
        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (!defaultSchedule)return;
            EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                if (fileContents){
                    let filters = fileContents.getFilterValues();
                    let workitems = filters.find((item) => { return item.name === 'workitem' });
                    let tasks = workitems.values.map((wi) => {
                        return { label: wi, value: wi };
                    });

                    let addTaskList = EM.getSetting('AdditionalTasks');  
                    if (addTaskList){
                        addTaskList.split(',').map((task) => {
                            return tasks.push({ value: task.trim(), label: task.trim() });
                        });
                    }

                    this.setState({ tasks });
                }
            });
        });
    }

    render() {
        let cv = this.state.currentValue;
        let value = cv ? { value: cv, label: cv } : this.props.value;
        return (
            <Creatable
                className={"single-select " + (this.props.className) + (this.props.invalid?' is-invalid':'')}
                classNamePrefix={"single-select"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                options={this.state.tasks}
                placeholder={EM.t('util.filtering.placeholderDefault')}
                formatCreateLabel={(input) => { return EM.t('util.select-create', null, [input]) }}
                value={value} 
                ref={this.props.innerRef}        
                onChange={(selected) => {
                    this.setState({ currentValue: selected ? selected.value.trim() : null });
                    if (this.props.onChange)this.props.onChange(selected);
                    if (this.props.onUpdate)this.props.onUpdate(selected ? selected.value.trim() : null);
                }}  
            />
        );
    }
};