/* global EM */
import React from 'react';
import InputMask from "react-input-mask";
import { Input } from "reactstrap";
import utils from '../util/utils';

class PhoneEditor extends React.Component {    
        constructor(props) {
          super(props);
          this.value = '';
    }
  getValue() {
    return this.value;
  }

  getCtrlRef(column, ref) {
    return (this.ctrlRefs = ref || React.createRef());
  }

  render() {
    let self =this;
    const { updateData, value, column, rowIndex, ...rest } = this.props;
    let formattedValue = utils.formatPhoneNumber(value);
    return (<Input
          key="ctrl"
          type="tel"
          mask="(999) 999-9999"
          tag={InputMask}
          ref='inputNode'
          {...rest}
          defaultValue={formattedValue}
          onChange={(event) => {
            self.value = event.target.value;
          }}
        //   invalid={valMsg ? true : false}
        ></Input>);
  }
}

export default PhoneEditor;
