/* global EM */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Routes from '../../app/Routes';
import Dates from '../../util/Dates';
import Spinner from '../Spinner';

export default class SmartImage extends Component{
    constructor(props){
        super(props);    
                
        
        this.state = {
            loading: true,
            src:""
        };        
        this.imageLoaded = this.imageLoaded.bind(this);
    }
    
    

    imageLoaded(){
        this.setState({loading:false})
    }

    render(){
        return (
            <>
            {(this.state.loading) && <Spinner text={EM.t('util.loading')} classes="small"/> }
             <img onLoad={this.imageLoaded} {...this.props}  style={{display: this.state.loading ? "none" : "block"}}/>
            </>
        );
    }
};