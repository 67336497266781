/* global EM */
import CSV from './CSV';
import saver from 'file-saver';

const File = {
    read: (ctrl) => {
        var reader = new FileReader();
        return new Promise(function (resolve, reject) {
            reader.onload = function () {
                let fileContents = reader.result;

                //remove zero-width spaces and various other control characters
                fileContents = fileContents.replace(/[\u200B-\u200D\uFEFF\u200E]/g, '');//\u200F\u0000-\u001F\u007F-\u009F

                //remove non-breaking spaces from text
                fileContents = fileContents.replace(/\u00A0/g, ' ');

                resolve(fileContents);
            };

            reader.onerror = function (event) {
                reject(new Error('File could not be read.'));
            };

            let fileSizeLimitSetting = parseInt(EM.getTenantOption('fileSizeLimit'));
            let sizeLimit = isNaN(fileSizeLimitSetting) ? 10485760 : fileSizeLimitSetting;    
            let sizeLimitInMb = (sizeLimit/1048576).toFixed(2);

            if (ctrl.files[0].size > sizeLimit){
                reject(new Error('File is too large. Maximum file size is ' + sizeLimitInMb + ' MB.'));
                return;
            }

            reader.readAsText(ctrl.files[0]);
        });
    },

    save: (filename, data, mime) => {
        saver.saveAs(new Blob([data], { type: (mime || "text/plain") + ';charset=utf-8' }), filename);
    },

    readCSV: async (ctrl, allowBlankFiles) => {
        let fileContents = await File.read(ctrl);
        if (!fileContents) {
            throw new Error(EM.t('error.csv-no-data'));
        }

        let csvContents = CSV.fromString(fileContents.trim());
        if (csvContents.length < 2 && !allowBlankFiles) {
            throw new Error(EM.t('error.csv-no-data'));
        }

        return csvContents;
    },

    readCsvOrXml: async (ctrl, conversionFunc, allowBlankFiles) => {
        let fileContents = await File.read(ctrl);
        if (!fileContents) {
            throw new Error(EM.t('error.csv-no-data'));
        }

        let contents = fileContents.trim();
        if (contents.indexOf('<?') === 0) {
            let csvContents = await conversionFunc(fileContents.trim());
            if (csvContents.length < 2 && !allowBlankFiles) {
                throw new Error(EM.t('error.csv-no-data'));
            }
            return csvContents;
        } else {
            let csvContents = CSV.fromString(fileContents.trim());
            if (csvContents.length < 2 && !allowBlankFiles) {
                throw new Error(EM.t('error.csv-no-data'));
            }
            return csvContents;
        }
    },

    saveHtmlAsExcelTable(filename, html) {        
        if (!html){
            console.log('No table html contents to export.');
            return;
        }
        
        const mime = 'application/vnd.ms-excel';
        const output =
            '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
            'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
            'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
            'lWorksheet><x:Name>Exported</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
            '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
            'xml><![endif]--></head><body>' + html + '</body></html>';

        File.save(filename + '.xls', output, mime);
    }
}

export default File;
