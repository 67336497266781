/* global EM */
import React, { Component } from "react";
import { Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import PanelModal from '../components/PanelModal';
import UserSessions from "../components/UserSessions";
import Appointments from "../components/Appointments";
import Prepaids from "../components/Prepaids";
import PrintOrders from "../components/PrintOrders";
import Routes from '../app/Routes';
import Spinner from '../components/Spinner'
class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.userDetails;
    this.state = {
      parentRecordDetail: { id: this.props.match.params.userId, companyId: this.props.match.params.companyId },
      userData: {},
      show: false,
      includeDS: false,
      sheetCount: 0,
      imageCount:0,
      largePrint_11_14: 0,
      largePrint_16_20: 0,
      largePrint_20_30: 0,
      price: 0,
      data:false
    };
    
    this.redirectTouserScheduler = this.redirectTouserScheduler.bind(this);
    this.redirectToReschedule = this.redirectToReschedule.bind(this);
    this.sendApp = this.sendApp.bind(this);
    this.saveUserDetail = this.saveUserDetail.bind(this);
  }

  componentDidMount(){
    const self=this
    this.setState({data:false})    
    this.entity.loadEntityByParent(this.state.parentRecordDetail,false).then((data)=>{
      if(EM.allLoaded(this.entity)){   
                  
        data = self.entity.get(true);
        self.setState({data:data})
        console.log(data)
      }
    })
    // this.entity.load();
  }

  componentWillUnmount(){
    this.setState({data:false})
  }
  
  handModal() {
    this.setState({ show: !this.state.show });
  }

  createPackage() {
    var newRecord = {
      allDigital: this.state.includeDS,
      sheetCount: this.state.sheetCount,
      imageCount: this.state.imageCount,
      largePrint1114: this.state.largePrint_11_14,
      largePrint1620: this.state.largePrint_16_20,
      largePrint2030: this.state.largePrint_20_30,
      price: this.state.price,
      userId: this.state.parentRecordDetail.id,
      companyId: this.state.parentRecordDetail.companyId
    };
    EM.prepaid.create(newRecord).then(()=>{this.setState({show:false, includeDS: false,
      sheetCount: 0,
      imageCount:0,
      largePrint_11_14: 0,
      largePrint_16_20: 0,
      largePrint_20_30: 0,
      price: 0})});
  }
  redirectTouserScheduler() {
    let url = Routes.compose(Routes.client.userSchdeuler, { userId: this.state.parentRecordDetail.id, companyId: this.state.parentRecordDetail.companyId });
    this.props.history.push(url);
  }
  redirectToReschedule(apt) {
    let url = Routes.compose(Routes.client.rescheduleAppointment, { userId: this.state.parentRecordDetail.id, companyId: this.state.parentRecordDetail.companyId, appointmentId: apt.appointmentId });
    this.props.history.push(url);
  }

  deleteUser(){
      var user = this.entity.get();
      EM.users.delete([user.userId]).then(()=>{EM.redirectTo(Routes.client.users);});
  }

  sendApp(){
    let user = this.entity.get(); 
    var companyId = EM.getDefaultCompany(user).companyId
    EM.calendar.executeGet(null,{phoneNumber:user.phoneNumber,companyId},Routes.clientApi.sendApp, {});
  }
  saveUserDetail(){
    var user = this.entity.get();
    const data = this.state.data
    this.entity.executePut(data,null,Routes.clientApi.updateUser, {},true)    
  }
  onTextChange(event, field) {
    let data = Object.assign(this.state.data, {});
    
    data[field] = event.target.value;
    
    this.setState({ data });
  }

  render() {
    let data = [];
    // if (!this.state.data) { 
    //   // debugger
    //   if(EM.allLoaded(this.entity)){   
                  
    //     data = this.entity.get(true);
    //     this.setState({data:data})
    //     console.log(data)
    //   }
    //   // debugger
    // }
    
    if(!this.state.data){      
      return <Spinner text={EM.t('util.loading')} classes="small"/>
    }
    if(this.state.data)
    return (
      <div key="contents" className="page container-fluid">
        <div className="row ml-1 mr-1 p-1 mb-2 pt-2  border-bottom" style={{ backgroundColor: "#f9f9f9" }}>
          <h5 className="ml-2">User Details</h5>
        </div>
        <div className="row">
          <div className="col-lg-6 col-12 d-flex align-items-center">
            <label style={{ width: '127px' }}><b>First Name: </b></label>
            <input onChange={(event) => { this.onTextChange(event, 'firstName') }}  placeholder="First Name"  className="form-control m-0 border border-2 border-primary p-2" value={this.state.data.firstName}/>
          </div>
          <div className="col-lg-6 col-12 d-flex align-items-center">
            <label style={{ width: '127px' }}><b>Last Name: </b></label>
            <input  onChange={(event) => { this.onTextChange(event, 'lastName') }}  placeholder="Last Name"  className="form-control m-0 border border-2 border-primary p-2" value={this.state.data.lastName}/>            
          </div>
          <div className="col-lg-6 col-12 d-flex align-items-center">
            <label style={{ width: '127px' }}><b>Email: </b></label>
            <input  onChange={(event) => { this.onTextChange(event, 'emailAddress') }} placeholder="Email"  className="form-control m-0 border border-2 border-primary p-2" value={this.state.data.emailAddress}/>                        
          </div>
          <div className="col-lg-6 col-12 d-flex align-items-center">
            <label style={{ width: '127px' }}><b>Phone Number: </b></label>
            <input  placeholder="Phone Number"  className="form-control m-0 border border-2 border-primary p-2" value={this.state.data.phoneNumber} onChange={(event) => { this.onTextChange(event, 'phoneNumber') }}/>
           </div>
          <div className="col-lg-6 col-12 d-flex align-items-center">
            <label style={{ width: '127px' }}><b>Flashop User: </b></label>
            <input className="form-control m-0 border border-2 border-primary p-2" value={this.state.data.signedUp === true ? "Yes" : "No"} readOnly={true}/>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button className="btn btn-primary m-2" onClick={() => { this.redirectTouserScheduler() }}>Schedule</button>
            <button hidden={EM.isSuperAdmin()!==true || this.state.data.active!==true} onClick={() => this.deleteUser()} className="btn btn-danger m-2">Delete</button>
            <button className="btn btn-success m-2" title="Add Package" onClick={() => { this.handModal() }}>Add Package</button>
            <button className="btn btn-primary m-2" onClick={()=>{this.sendApp();}}>Send App</button>
            <button className="btn btn-primary m-2" onClick={()=>{this.saveUserDetail();}}>Update User Detail</button>
          </div>
        </div>

        <UserSessions user={this.state.data} data={this.state.parentRecordDetail} />
        <Appointments data={this.state.parentRecordDetail} redirectToReschedule={this.redirectToReschedule} />
        <Prepaids data={this.state.parentRecordDetail} />
        <PrintOrders data={this.state.parentRecordDetail} />

        <PanelModal isOpen={this.state.show}>
          <ModalHeader>Enter the Studio Package</ModalHeader>
          <ModalBody>
            <Form className="form-create">
              <FormGroup className="form-group" check inline>
                <Input type="checkbox" value={this.state.includeDS} onChange={evt => this.setState({ includeDS: evt.target.checked })} />
                <Label check>
                  Include Digital Session
                </Label>
              </FormGroup>
              <FormGroup className="form-group" >
                <Label>
                  Sheets:
                </Label>
                <Input key="ctrl" type="number" value={this.state.sheetCount} onChange={evt => this.setState({ sheetCount: evt.target.value })} />
              </FormGroup>
              <FormGroup className="form-group" >
                <Label>
                  Images:
                </Label>
                <Input key="ctrl" type="number" value={this.state.imageCount} onChange={evt => this.setState({ imageCount: evt.target.value })} />
              </FormGroup>
              <FormGroup className="form-group" >
                <Label>
                  11x14:
                </Label>
                <Input key="ctrl" type="number" value={this.state.largePrint_11_14} onChange={evt => this.setState({ largePrint_11_14: evt.target.value })} />
              </FormGroup>
              <FormGroup className="form-group" >
                <Label>
                  16x20:
                </Label>
                <Input key="ctrl" type="number" value={this.state.largePrint_16_20} onChange={evt => this.setState({ largePrint_16_20: evt.target.value })} />
              </FormGroup>
              {/* <FormGroup className="form-group" >
                <Label>
                  20x30:
                </Label>
                <Input key="ctrl" type="number" value={this.state.largePrint_20_30} onChange={evt => this.setState({ largePrint_20_30: evt.target.value })} />
              </FormGroup> */}
              <FormGroup className="form-group" >
                <Label>
                  Price:
                </Label>
                <Input key="ctrl" type="number" value={this.state.price} onChange={evt => this.setState({ price: evt.target.value })} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>            
            <Button color="btn btn-secondary" onClick={() => { this.handModal() }}>Cancel</Button>
            <Button color="btn btn-primary" onClick={() => { this.createPackage() }}>Add Record</Button>
          </ModalFooter>
        </PanelModal>
      </div>
    );
  }
}

export default UserDetails;
