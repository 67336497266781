import React, { Component } from 'react';

export default class PopoutEditField extends Component {
    getValue(){
        return this.props.value;
    }

    render() {
        return <input type="text" defaultValue={this.props.value} className="form-control editor" disabled />
    }
}