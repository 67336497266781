import React, { Component } from 'react';
import Dates from '../util/Dates';

export default class CalendarView extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            months: ["January","February","March","April","May","June","July","August","September","October", "November","December"],
            data: this.props.data,
            date: new Date()
        };
        this.Foward = this.Foward.bind(this);
        this.Backward = this.Backward.bind(this);
        this.GetDay = this.GetDay.bind(this);
        this.DayClick = this.DayClick.bind(this);
    }

    GetDay(ny, nx) {
        let rmvc = this.state.data[nx][ny];        
        rmvc.css = "table-light sortable pt-3 pb-3";
        if(rmvc.enabled)
            rmvc.css = "table-success sortable pt-3 pb-3";

        return rmvc;
    }

    Backward() {
        let dt = this.state.date;
        dt.setMonth(dt.getMonth() - 1);
        this.setState({ date: dt,data: this.props.onCalendarChange(dt) });
        
    }

    Foward() {
        let dt = this.state.date;
        dt.setMonth(dt.getMonth() + 1);
        this.setState({ date: dt,data: this.props.onCalendarChange(dt) });
    }

    DayClick(key, enabled, strdt, Day, Week) {
        var date = Dates.fromStr(key.split('-')[0],'LLddyyyy');
        this.props.onDayClick(Dates.toJS(date));
    }

    GetWeek(Week) {
        return <tr>
            <td align="center" onClick={() => this.DayClick(this.GetDay(0, Week).key, this.GetDay(0, Week).enabled, this.GetDay(0, Week).strdt, 0, Week)} className={this.GetDay(0, Week).css}>{this.GetDay(0, Week).title}&nbsp;<span className="badge badge-success">{this.GetDay(0, Week).aptCount}</span></td>
            <td align="center" onClick={() => this.DayClick(this.GetDay(1, Week).key, this.GetDay(1, Week).enabled, this.GetDay(1, Week).strdt, 1, Week)} className={this.GetDay(1, Week).css}>{this.GetDay(1, Week).title}&nbsp;<span className="badge badge-success">{this.GetDay(1, Week).aptCount}</span></td>
            <td align="center" onClick={() => this.DayClick(this.GetDay(2, Week).key, this.GetDay(2, Week).enabled, this.GetDay(2, Week).strdt, 2, Week)} className={this.GetDay(2, Week).css}>{this.GetDay(2, Week).title}&nbsp;<span className="badge badge-success">{this.GetDay(2, Week).aptCount}</span></td>
            <td align="center" onClick={() => this.DayClick(this.GetDay(3, Week).key, this.GetDay(3, Week).enabled, this.GetDay(3, Week).strdt, 3, Week)} className={this.GetDay(3, Week).css}>{this.GetDay(3, Week).title}&nbsp;<span className="badge badge-success">{this.GetDay(3, Week).aptCount}</span></td>
            <td align="center" onClick={() => this.DayClick(this.GetDay(4, Week).key, this.GetDay(4, Week).enabled, this.GetDay(4, Week).strdt, 4, Week)} className={this.GetDay(4, Week).css}>{this.GetDay(4, Week).title}&nbsp;<span className="badge badge-success">{this.GetDay(4, Week).aptCount}</span></td>
            <td align="center" onClick={() => this.DayClick(this.GetDay(5, Week).key, this.GetDay(5, Week).enabled, this.GetDay(5, Week).strdt, 5, Week)} className={this.GetDay(5, Week).css}>{this.GetDay(5, Week).title}&nbsp;<span className="badge badge-success">{this.GetDay(5, Week).aptCount}</span></td>
            <td align="center" onClick={() => this.DayClick(this.GetDay(6, Week).key, this.GetDay(6, Week).enabled, this.GetDay(6, Week).strdt, 6, Week)} className={this.GetDay(6, Week).css}>{this.GetDay(6, Week).title}&nbsp;<span className="badge badge-success">{this.GetDay(6, Week).aptCount}</span></td>
        </tr>;
    }


    static getDerivedStateFromProps(props, current_state) {
        if (current_state.data !== props.data) {

          return {
            data: props.data
          }
        }
        return null
      }

    render() {   
        console.log(this.state);
             return (
                 <div>                   
                     <div className="container">
                         <div className="row">
                             <div className="col">
                                 <table className="table table-bordered">
                                     <tr>
                                         <td><button onClick={() => this.Backward()} className="btn btn-primary buttonwidth">Back</button></td>
                                         <td align="center" colSpan={5}><b>{this.state.months[this.state.date.getMonth()]}</b></td>
                                         <td align="right"><button onClick={() => this.Foward()} className="btn btn-primary buttonwidth">Forward</button></td>
                                     </tr>
                                 </table>

                                 <table className="table table-bordered">


                                     <tr>
                                         <th>Mon</th>
                                         <th>Tues</th>
                                         <th>Wed</th>
                                         <th>Thu</th>
                                         <th>Fri</th>
                                         <th>Sat</th>
                                         <th>Sun</th>
                                     </tr>

                                     {this.GetWeek(0)}
                                     {this.GetWeek(1)}
                                     {this.GetWeek(2)}
                                     {this.GetWeek(3)}
                                     {this.GetWeek(4)}
                                     {this.GetWeek(5)}
                                 </table>


                             </div>

                         </div>

                     </div>








                 </div>
             );        
    }
}