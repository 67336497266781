/* global EM */
import React, { Component } from "react";
import { Validators } from "../util/EntityValidators";
import EntityTable from "./EntityTables/EntityTable";
import {FaTrashAlt } from "react-icons/fa";
import Dates from '../util/Dates'

class Prepaids extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.prepaid;
    this.state = {
      parentRecordDetail: { userId: this.props.data.id, companyId: this.props.data.companyId }
    }
    this.entity.loadEntityByParent(this.state.parentRecordDetail,true);
    this.deletePrepaid = this.deletePrepaid.bind(this);
  }
  deletePrepaid(prepaid){
    let self =this;
      this.entity.delete([prepaid.prepaidId])
      .then(()=>{
        let data = self.entity.get();
      });
  }
  render() {
    let self = this;
    let columns = [];
    let data = [];
    if (EM.allLoaded(this.entity)) {
      data = this.entity.get();
      columns = [
        {
          dataField: "createdDate",
          text: this.entity.columns("createdDate"),
          asDate: true,
          editable:false,
          filterOptions: {
            text: true,
          },
          formatter: (cell, row) => {
            return (Dates.isoToDateTime(cell, "YYYY-MM-DD hh:mm A"));
          }
        },
        {
          dataField: "allDigital",
          text:"Include Digital",
          editable:true,
          asActive:true,
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "price",
          text: this.entity.columns("price"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        {
          dataField: "sheetCount",
          text: this.entity.columns("sheetCount"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        {
          dataField: "imageCount",
          text: this.entity.columns("imageCount"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        {
          dataField: "largePrint1114",
          text: this.entity.columns("largePrint1114"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        {
          dataField: "largePrint1620",
          text: this.entity.columns("largePrint1620"),
          editable:true,
          filterOptions: {
            text: true,
          },
          validators: [ Validators.requiredNumber,Validators.positiveNumber,Validators.numberOnly],
        },
        // {
        //   dataField: "largePrint2030",
        //   text: this.entity.columns("largePrint2030"),
        //   editable:true,
        //   filterOptions: {
        //     text: true,
        //   },
        // },
        {
          dataField: "consumed",
          text: this.entity.columns("consumed"),
          asActive: true,
          editable:true,
          filterOptions: {
            text: true,
          },
        },       
        {
          dataField: "delete",
          text: this.entity.columns("delete"),
          creatable: false,
          editable: false,
          formatter: (cell, row) => {
            if(row.consumed!==true && (EM.isSuperAdmin() ===true ||EM.isAdmin() ===true) )
               return (<button onClick={() => { self.deletePrepaid(row) }}  className="btn btn-danger"><FaTrashAlt/></button>)
            else return '';
          }
        }
      ];
    }
    return (
      <div key="contents" className="page container-fluid mt-4">
        <EntityTable
        collapsible={true}
        collapsed = {true}
          entity={this.entity}
          data={data}
          columns={columns}
          className="table-overflow-container-auto"
          hideImportExport={true}
          hideAudit={true}
          hideAddRecord={true}
          hideDelete={true}
          hideSearch={true}
          hideFilters={true}
        />
      </div>
    );
  }
}

export default Prepaids;
