/* global EM */
import _ from 'underscore';
import * as entityActions from '../entities/actions/entities';
import * as fileActions from '../entities/actions/files';

export default class Entity {
    constructor(store, entityTypeDef) {
        this.store = store;
        this.dispatch = store.dispatch;

        this.entityTypeDef = entityTypeDef;
        this.idField = entityTypeDef.idField;
        this.apiName = entityTypeDef.apiName;
        this.createUrl = entityTypeDef.createUrl;
        this.editUrl = entityTypeDef.editUrl;
        this.deleteUrl = entityTypeDef.deleteUrl;
        this.getUrl = entityTypeDef.getUrl;
        this.uploadUrl = entityTypeDef.uploadUrl;
        this.getSingleUrl = entityTypeDef.getSingleUrl;
        this.singleName = entityTypeDef.singleName;
        this.name = entityTypeDef.name;
        this.allowDuplicates = entityTypeDef.allowDuplicates || false;
        this.modelName = (entityTypeDef.modelName || this.name).capitalize();
        this.modelNameSingular = entityTypeDef.modelNameSingular || (this.modelName.slice(-1) === 's' ? this.modelName.slice(0, -1) : this.modelName);
        this.single = false;
        this.searchUrl = entityTypeDef.searchUrl;
        this.uniqueness = entityTypeDef.uniqueness || ['Name'];
        this.isFileListingTable = entityTypeDef.isFileListingTable ? true : false;

        if (this.isFileListingTable) {
            this.fileType = entityTypeDef.fileTypeClass;
        }

        this.optionList = null;
        this.valueList = null;
        this.idList = null;
        this.keyList = null;
    }

    resetCachedLists() {
        this.optionList = null;
        this.valueList = null;
        this.idList = null;
        this.keyList = null;
    }

    async load(force, isSilent) {
            await this.dispatch(entityActions.loadDomainEntity(this, isSilent));
            this.resetCachedLists();
    }

    async loadEntityByParent(parentDetail, force, isSilent) {
            await this.dispatch(entityActions.loadEntityByParent(this, isSilent, parentDetail));
            this.resetCachedLists();
    }
    async loadSingleEntity(params, force, isSilent) {
            await this.dispatch(entityActions.loadSingleEntity(this, isSilent, params));
            this.resetCachedLists();
    }
    async searchEntity(params, qs, force, isSilent) {
            await this.dispatch(entityActions.searchEntity(this, isSilent, params, qs));
            this.resetCachedLists();
    }
    async create(newItem, parentRecordDetail) {
        let state = this.store.getState();
        let created = await this.dispatch(entityActions.createDomainEntity(this, newItem, parentRecordDetail));
        this.resetCachedLists();
        // EM.markDomainDirty(state.account.userId, this);
        return created;
    }

    async createBulk(newItems, entityId) {
        let state = this.store.getState();
        if (!state.domain) return;
        let created = await this.dispatch(entityActions.createBulkDomainEntity(state.domain.DomainId, this, newItems, entityId));
        this.resetCachedLists();
        // EM.markDomainDirty(state.domain.DomainId, this);
        return created;
    }

    async delete(ids, parentRecordDetail) {
        let state = this.store.getState();
        let deleted = await this.dispatch(entityActions.deleteDomainEntity(this, ids, parentRecordDetail));
        this.resetCachedLists();
        // EM.markDomainDirty(state.domain.DomainId, this);
        return deleted;
    }

    async update(oldItem, newItem, parentRecordDetail) {
        let state = this.store.getState();
        let updated = await this.dispatch(entityActions.updateDomainEntity(this, newItem, oldItem, parentRecordDetail));
        this.resetCachedLists();
        // EM.markDomainDirty(state.account.userId, this);
        return updated;
    }

    async import(items, isSilent, modsOnly) {
        let state = this.store.getState();
        if (!state.domain) return;
        let imported = this.dispatch(entityActions.importDomainEntity(state.domain.DomainId, this, items, isSilent, modsOnly));
        this.resetCachedLists();
        // EM.markDomainDirty(state.domain.DomainId, this);
        return imported;
    }

    async audit(itemId) {
        let state = this.store.getState();
        if (!state.domain) return;
        let audit = await this.dispatch(entityActions.auditDomainEntity(state.domain.DomainId, this, itemId));
        return audit.data;
    }

    async clearAll() {
        if (EM.debugMode) {
            let state = this.store.getState();
            if (!state.domain) return;
            await this.dispatch(entityActions.clearAllDomainEntity(state.domain.DomainId, this));
            this.resetCachedLists();
            // EM.markDomainDirty(state.domain.DomainId, this);
        }
    }

    makeFileName(id, type) {
        return this.name + '-' + id + (type || '');
    }

    async loadFile(id, type, force) {
        if (!this.isFileListingTable) return;
        let state = this.store.getState();
        if (!state.domain) return;
        if (!state.files[this.makeFileName(id, type)] || force) {
            return await this.dispatch(fileActions.loadDomainEntityFile(state.domain.DomainId, this, id));
        }
    }

    async saveFile(id, data) {
        if (!this.isFileListingTable) return;
        let state = this.store.getState();
        if (!state.domain) return;
        return await this.dispatch(fileActions.updateDomainEntityFileBackground(state.domain.DomainId, this, id, data));
    }
    async upload(newFile, entityId) {
        let state = this.store.getState();
        let created = await this.dispatch(fileActions.uploadDomainEntityFile(this, newFile, entityId));
        this.resetCachedLists();
        return created;
    }
    async invalidateFile(id) {
        if (!this.isFileListingTable) return;
        let state = this.store.getState();
        if (!state.domain) return;
        return await this.dispatch(fileActions.invalidateFile(state.domain.DomainId, this, id));
    }

    async loadDefaultItem() {
        let self = this;
        await self.load();
        return this.findDefaultItem();
    }

    clear() {
        this.resetCachedLists();
        this.dispatch(entityActions.clearDomainEntity(this));
    }

    isLoaded() {
        return this.get() ? true : false;
    }

    get() {
        let state = this.store.getState();
        return state[this.name];
    }

    getFile(id, type) {
        let state = this.store.getState();
        return state.files[this.makeFileName(id, type)];
    }

    asOptionList(labelField, sortByLbl, valueField) {
        let state = this.get();
        if (!state) return [];
        if (!this.optionList) {
            this.optionList = state.map((row) => {
                let lbl = row[labelField || 'name'];
                if (labelField && typeof labelField === 'function') {
                    lbl = labelField(row);
                }
                return { value: row[valueField || this.idField], label: lbl };
            });
            if (sortByLbl) {
                this.optionList = _.sortBy(this.optionList, (item) => {
                    return item.label;
                });
            }
        }
        return this.optionList;
    }

    asIndexed() {
        let state = this.get();
        if (!state) return [];
        if (!this.idList) {
            this.idList = _.indexBy(state, this.idField.toString());
        }
        return this.idList;
    }

    asKeyed() {
        let state = this.get();
        if (!state) return [];
        if (!this.keyList) {
            this.keyList = _.indexBy(state, (item) => {
                return this.makeKeyFromItem(item);
            });
        }
        return this.keyList;
    }

    asValueList(labelField) {
        let state = this.get();
        if (!state) return [];
        if (!this.valueList) {
            this.valueList = {};
            state.forEach((row) => {
                this.valueList[row[labelField || 'Name']] = row[this.idField];
            });
        }
        return this.valueList;
    }

    makeKeyFromItem(item) {
        let fieldValues = this.uniqueness.map((objKey) => {
            return item[objKey] || '';
        });

        return fieldValues.join('.');
    }

    findItem(item) {
        let key = this.makeKeyFromItem(item);
        if (!key) {
            console.log('Key could not be created for entity. Check that the uniqueness field is set in entityTypes: ' + this.name);
            return null;
        }
        return this.findByKey(key);
    }

    findByKey(key) {
        return this.asKeyed()[key];
    }

    hasDuplicates() {
        let state = this.get();
        if (!state) return false;
        let dupes = {};
        for (var i = 0; i < state.length; i++) {
            let item = state[i];
            if (typeof item.Active !== 'undefined' && item.Active === false) continue;
            let key = this.makeKeyFromItem(item);
            if (dupes[key]) {
                EM.warn('First duplicate found: ', item);
                return true;
            } else {
                dupes[key] = true;
            }
        };
        return false;
    }

    findDuplicates() {
        let state = this.get();
        if (!state) return false;
        let keys = {};
        let dupes = {};
        for (var i = 0; i < state.length; i++) {
            let item = state[i];
            if (typeof item.Active !== 'undefined' && item.Active === false) continue;
            let key = this.makeKeyFromItem(item);
            if (keys[key]) {
                dupes[item[this.idField]] = true;
            }
            keys[key] = true;
        };
        return dupes;
    }

    findDefaultItem() {
        let self = this;
        let currentSet = self.get();
        if (!currentSet) return null;

        let defaultItem = currentSet.find(item => { return item.IsDefault });
        if (!defaultItem) {
            console.warn('No default. Returning the newest item instead.');
            defaultItem = currentSet[currentSet.length - 1];
        }

        return defaultItem;
    }

    lookupValue(id, fld) {
        let found = this.asIndexed()[id];
        return found ? found[fld || 'name'] : '';
    }

    lookupId(value, fld) {
        return this.asValueList(fld)[value];
    }

    byId(id) {
        return this.asIndexed()[id];
    }

    //Translation helpers
    t(key, exact) {
        return EM.t(this.name + '.' + key, exact);
    }

    columns(key, exact) {
        return EM.t(this.name + '.columns.' + key, exact);
    }

    isOverridden() {
        return EM.isOverridden(this);
    }

    async executePost(data,qs,requestUrl, parentRecordDetail,executeDispatch){                
        executeDispatch = typeof executeDispatch === 'undefined' ? true : executeDispatch;
        let created = await this.dispatch(entityActions.execute(this,data,requestUrl,qs,parentRecordDetail,'POST',executeDispatch));      
        this.resetCachedLists();        
        return created ? created.data : {};
    }

    async executePut(data,qs,requestUrl, parentRecordDetail,executeDispatch){                
        executeDispatch = typeof executeDispatch === 'undefined' ? true : executeDispatch;
        let created = await this.dispatch(entityActions.execute(this,data,requestUrl,qs,parentRecordDetail,'PUT',executeDispatch));        
        
        this.resetCachedLists();        
        if(created===undefined)return
        return created.data ? created.data : created;
    }

    async executeGet(data,qs,requestUrl, parentRecordDetail,executeDispatch){                
        executeDispatch = typeof executeDispatch === 'undefined' ? true : executeDispatch;
        let result = await this.dispatch(entityActions.execute(this,data,requestUrl,qs,parentRecordDetail,'GET',executeDispatch));      
        this.resetCachedLists();        
        return result && result.data ?  result.data : result;
    }

    async executeDelete(data,qs,requestUrl, parentRecordDetail,executeDispatch){                
        executeDispatch = typeof executeDispatch === 'undefined' ? true : executeDispatch;
        
        let result = await this.dispatch(entityActions.execute(this,data,requestUrl,qs,parentRecordDetail,'DELETE',executeDispatch));                   
        return result;
    }

}