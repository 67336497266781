/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import EntityTable from "../components/EntityTables/EntityTable";
import _ from "underscore";
import Routes from '../app/Routes';
import PanelModal from '../components/PanelModal';
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { textFilter,Comparator  } from 'react-bootstrap-table2-filter';

class Users extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.users;
    this.state = {
      userTypeId:"",
      showAssignCompany:false,
      selectedUser:null,
      companiesOptions:[],
      selectedCompanies:[],
      users:[],
      isSuperAdmin:EM.isSuperAdmin(),
      dynamicLabel:this.entity.columns("emailAddressUserName")
    };
    this.entity = EM.users;
    this.userTypeChange = this.userTypeChange.bind(this);
    this.redirectTouserDetail = this.redirectTouserDetail.bind(this);
    this.reset = this.reset.bind(this);
    this.showAssignCompany = this.showAssignCompany.bind(this);
    this.onChange = this.onChange.bind(this);
    this.assignCompanies = this.assignCompanies.bind(this);    
    this.mapUsers = this.mapUsers.bind(this);
  }

  componentDidMount(){       
    this.setState({companyName:EM.getDefaultCompany().companyName})    
    let company = EM.getDefaultCompany();
    let body = {
      currentUserId:EM.account.get().userId,
      companyId:
        EM.isSuperAdmin() !== true && company
          ? company.companyId
          : company.companyId,
      userTypeId: !EM.isSuperAdmin() && !EM.isAdmin() ? 1 : null,
    };
    this.entity.executePost(body,{},Routes.clientApi.userSearch,{},true)
    .then((result)=>{
      let users = EM.users.get();
      if(!EM.isSuperAdmin() && !EM.isAdmin())
        users = _.where(users,{userTypeId:1});
              
      this.setState({users});
    });
    EM.userTypes.load();
    EM.role.load();
    //only if superadmin
    // EM.companies.load().then(()=>{
    //  this.setState({companiesOptions:EM.companies.asOptionList('name',true,'companyId')});
    // });
    EM.companies.executeGet(null, null, 'company/active', {})
  }

  mapUsers(){
    return  (this.state.users ||[]).map((user)=>{
      return Object.assign({},user,{phonenumber1:user.phoneNumber ?  user.phoneNumber.replace(/\D/g, ''):user.phoneNumber});
    });
  }

  static getDerivedStateFromProps(props, current_state) {
    let storeUsers =  EM.users.get();
    if (storeUsers && current_state.users !== storeUsers) {
      return {
        users: storeUsers
      }
    }
    return null
  }

  userTypeChange(event,userTypeId){
    this.setState({userTypeId});
  }

  redirectTouserDetail =(userId,row) =>{
    //should use logged in users company as the desired user can belong to multiple companies
    var company = EM.getDefaultCompany();
    let url=Routes.compose(Routes.client.userDetails,{userId,companyId:company.companyId});
    this.props.history.push(url);
  }

  reset(user){
    //debugger
    const UserTypeId = user.userTypeId;
    var companyId = EM.getDefaultCompany().companyId
    if(UserTypeId === 2){
      EM.user.executePost(null,{companyId,email:user.emailAddress,isAssociate:true},Routes.clientApi.resetUser, {});  
    } else {      
      EM.user.executePost(null,{companyId,phone:user.phonenumber1,isAssociate:false},Routes.clientApi.resetUser, {});  
    }
    
  }

  showAssignCompany(user){    
    this.setState({showAssignCompany:true,selectedUser:user});
  }

  getColumns(){
    let self =this;
    return [ 
      {
        dataField: "userTypeId",
        text: "User Type",
        validators: [ Validators.required],
        fromEntity:EM.userTypes,
        onChange:self.userTypeChange,
      },    
      {
        dataField: "roleId",
        text: "Role",
        validators: [Validators.required],
        fromEntity:EM.role,
        creatable:this.state.userTypeId === '2',
      }, 
      {
        dataField: "companyId",
        text: "Company",
        hideInTable:true,
        validators: [ Validators.required],
        fromEntity:EM.companies,
        creatable:EM.isSuperAdmin(),
        // formatter: (cell, row) => {
        //   let company = '';
        //   if(row.userCompanies && row.userCompanies.length > 0) {
        //     row.userCompanies.forEach((el) => {
        //       if(el && el.defaultCompany === true)
        //         company = el.companyName
        //     })
        //   }
        //   return company;
        // }
      },    
      {
        dataField: "firstName",
        text: this.entity.columns("firstName"),
        validators: [ Validators.required,Validators.length100],
        filter: textFilter({
          placeholder: 'Exact match',
          defaultValue: '',
          comparator: Comparator.EQ
        }),
        filterOptions: {
          text: true,
        },
      },
     
      {
        dataField: "lastName",
        text: this.entity.columns("lastName"),
        validators: [ Validators.required],
        filter: textFilter({
          placeholder: 'Exact match',
          defaultValue: '',
          comparator: Comparator.EQ
        }),
        filterOptions: {
          text: true,
        }
      },
      {
        dataField: "phoneNumber",
        text: this.entity.columns("phoneNumber"),
        validators:[Validators.required,Validators.phone,(value, context, mode,row)=>{
          return (typeof row !='undefined' && row.userTypeId === 1) ? "Not allowed" : '';
        }],           
        asPhone:true,
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "emailAddress",
        text: this.state.userTypeId == ''?this.entity.columns("emailAddressUserName"):(this.state.userTypeId === '2')?this.entity.columns("userName"):this.entity.columns("emailAddress"),
        validators: [Validators.required,Validators.length200,(value, context, mode,row)=>{
          return (typeof row !='undefined' && row.userTypeId === 1) ? Validators.email(value) : '';
        }],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "active",
        text: this.entity.columns("active"),
        asActive: true,
        creatable:false
      },      
      {
        dataField: "userId",
        // text: this.entity.columns("userId"),
        text: "Actions",
        creatable: false,
        editable:false,
        width:200,
        formatter: (cell, row) => {
          //check super admin role for different options available/removed.          
          return (<div>
              <button hidden={row.userTypeId !==1} title="User Details" className="btn btn-primary" onClick={(event)=>{self.redirectTouserDetail(cell,row);}}><i className="fa fa-light fa-memo-circle-info"/></button>
              <button title="Send Reset" className="btn btn-danger" onClick={(event)=>{self.reset(row);}}><i className="fa fa-recycle"/></button>
              <button hidden={!EM.isSuperAdmin()} title="Assign Company" className="btn btn-primary" onClick={(event)=>{self.showAssignCompany(row);}}><i className="fa fa-link"/></button>
            </div>);
        }
      },
      {
        dataField: "phonenumber1",
        text: 'd',
        creatable:false,
        editable:false,
        hidden:true,        
        classes: 'hidden',
        headerClasses :'hidden',
        sort:false
      }                        
    ];
  }

  onChange(values){
    let items = values ? values.map(item => { return item.value }) : [];
    this.setState({selectedCompanies:items})
  }

  assignCompanies(){
    let self =this;
    EM.user.executePost({userId:this.state.selectedUser.userId,companyIds:this.state.selectedCompanies},null,Routes.clientApi.assignCompanies, {}).then(()=>{
      let users = [...this.state.users]
      let user = _.findWhere(users,{userId:self.state.selectedUser.userId});
      _.each(this.state.selectedCompanies,(companyId)=>{
          let company = _.findWhere(user.userCompanies,{companyId:companyId});
          if(!company)
            user.userCompanies.push({userId:self.state.userId,companyId:companyId});
      });
      self.setState({selectedUser:null,selectedCompanies:[],showAssignCompany:false});
    });
  }

  render() {
    let columns = [];
    let data = [];
    if (EM.allLoaded(EM.users, EM.userTypes, EM.role)) {      
      data = this.mapUsers();      
      columns = this.getColumns();
    }
    return (
      <div key="contents" className="page container-fluid">
        <EntityTable
          entity={this.entity}
          pageTitle={`Users ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`}
          data={data}
          columns={columns}
          className="table-overflow-container"
          hideImportExport={true}
          hideAudit ={true}      
          onClose={() => {
            this.data = this.entity.get();
          }}
          onCreateClose={() => {            
            this.setState({dynamicLabel:this.entity.columns("emailAddressUserName")})
            this.setState({userTypeId:''});
          }}
          onCreate={(data) =>{
            let proceed=true;
            // if(_.isNull(data.phoneNumber) && data.userTypeId === '1') 
            // {
            //   EM.setError("Phone number is required.");
            //   proceed=false;
            // }
            if(_.isEmpty(data.emailAddress) && data.userTypeId === '2'){
              EM.setError("Email address is required.");
              proceed=false;
            }
            if(!EM.isSuperAdmin()) {
              data.companyId = EM.getDefaultCompany()?.companyId
            }
            return proceed;
          }}
        />
        <PanelModal isOpen={this.state.showAssignCompany} className={'panel-full'} >
            <ModalHeader>Assign Companies</ModalHeader>
            <ModalBody>                   
                <FormGroup>
                  <label>Companies</label>
                  <Select
                    className="multi-select"
                    classNamePrefix="multi-select"
                    isSearchable={true}
                    isMulti={true}
                    options={this.state.companiesOptions}
                    placeholder={EM.t('util.filtering.placeholder')}
                    onChange={this.onChange}
                    isDisabled={this.props.disabled}                   
                />
                </FormGroup>
            </ModalBody>
            <ModalFooter>    
                <Button className="pull-left" onClick={()=>{this.assignCompanies()}}>
                    Save
                </Button>           
                <Button className="pull-left" onClick={()=>{this.setState({showAssignCompany:false})}}>
                    {EM.t('util.closeButton')}
                </Button>
            </ModalFooter>
        </PanelModal>
      </div>
    );
  }
}

export default Users;
