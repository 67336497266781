/* global EM */
import React, { Component } from 'react';
import PageTitle from  '../components/PageTitle';
import _ from 'underscore';
import DashboardCount from './DashboardCount'
class Home extends Component {
    
    alpha(set){
        return _.sortBy(set, item => {
            return (item.order || 'z') + item.DisplayName;
        });
    }
    render() {
        const isAdmin = EM.isAdmin();
        const isSuperAdmin = EM.isSuperAdmin();
        let pageTitle = ""
        if(!isAdmin && !isSuperAdmin) {
            pageTitle = `Welcome ${this.props.account.firstName} ${this.props.account.lastName}!`
        } else {
            pageTitle = `Welcome to ${EM.getDefaultCompany().companyName}!`
        }
        return (
            <div key="contents" className="page page-bg container-fluid">  
                <div className="page-header">
                    <div className="container" style={{ zIndex: 3 }}>
                        <PageTitle icon="far fa-cube" title={pageTitle} text={''}>                           
                        </PageTitle>                       
                    </div>                
                </div>
                {isAdmin || isSuperAdmin ? (
                    <DashboardCount {...this.props}/>
                ): null}                   
            </div> 
        );
    }
}

export default Home;
