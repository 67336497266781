import * as types from '../actionTypes';
import * as errorActions from './error';

export function loadTranslationSuccess(lng) {
    return { type: types.LOAD_TRANSLATION, data: lng };
}

export function loadTranslation(lng, fallback) {
    return (dispatch, getState, api) => {
        return api.loadTranslation(lng).then(data => {
            data.name = lng;
            return dispatch(loadTranslationSuccess(data));
        }).catch(error => {
            return api.loadTranslation(fallback).then(data => {
                data.name = fallback;
                return dispatch(loadTranslationSuccess(data));
            }).catch(error => {
                console.log(error);
                dispatch(errorActions.globalError({ message: 'Translation file could not be loaded.' }));
                throw error;
            });
        });
    };
}