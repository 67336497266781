import * as types from '../actionTypes';

const intialState = {
    dateUpdated : null
}
export default function release(state = intialState, action) {
    switch (action.type) {
        case types.LOAD_RELEASE:
            return action.data;
        default:
            return state;
    }
}