/* global EM */
import React, { Component } from "react";

class UserInCompleteOrders extends Component {
  constructor(props) {
    super(props);    
    this.entity = EM.userIncompleteOrders;

    let company = EM.getDefaultCompany();
    this.state = {
      parentRecordDetail:{companyId:company.companyId, locationId: this.props.match.params.locationId},
    };
    this.entity.loadEntityByParent(this.state.parentRecordDetail);
  }

  componentWillUnmount() {
    this.entity.clear()
  }

  render() {
    let data = [];
    let errMsg = ''
    
    if (EM.allLoaded(EM.companies)) {
      data = this.entity.get();
      if(!data?.length) errMsg= "No Record Found"
    }
    return (
      <div key="contents" className="page container-fluid">
        <div className="d-flex align-items-center justify-content-center mb-2">
          <button className="mr-auto btn btn-primary buttonwidth" onClick={()=>this.props.history.goBack()}><i className="fas fa-light fa-chevron-left"></i></button>
          <h5 className="mr-auto mb-0">{data?.length?`Incompleted Orders: ${data[0].locationName}`:"Inompleted Orders"}</h5>
        </div>
        <div className="row mt-2">
          <div className="col-12">
              <table className="table table-bordered mb-0">
                  <thead>
                      <tr style={{ backgroundColor: "#ecf0f1" }}>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Address</th>
                      </tr>
                  </thead>
                  <tbody>
                    {data?.length ? data.map((row) => (
                      <tr key={row.userId}>
                        <td style={{ paddingTop: '1rem' }}>{`${row.firstName||''} ${row.lastName||''}`}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.phoneNumber}</td>
                        <td style={{ paddingTop: '1rem' }}>{row.emailAddress}</td>
                        <td style={{ paddingTop: '1rem' }}>{`${row.address?`${row.address}, ${row.city}, ${row.state}, ${row.zip}`:''}`}</td>
                      </tr>
                    )): <tr className="text-center">
                    <td colSpan="4" className="text-danger"><h6>{errMsg}</h6></td>
                  </tr>}
                  </tbody>
              </table>
          </div>
      </div>
      </div>
    );
  }
}

export default UserInCompleteOrders;
