/* global EM */
import React, { Component } from 'react';
import PanelModal from '../PanelModal';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Routes from '../../app/Routes';
import Spinner from '../Spinner';
import CKEditorInput from "../CKEditorInput";
import { Validators, Validate } from '../../util/EntityValidators'
const initialState={
    loading: false,
    key:"",
    label:"",
    value:"",
    popUpError:"",
    validators:false,
    entity:null,
    route:"",
    row:false
}
export default class PopeUpUpdate extends Component{
    constructor(props){
        super(props);    
                
        this.state = initialState;        
        this.onTextChange = this.onTextChange.bind(this);
        this.ShowError = this.ShowError.bind(this);
        this.setConfig = this.setConfig.bind(this);
        this.validate = this.validate.bind(this);
        this.updateEntity = this.updateEntity.bind(this);
        this.reset = this.reset.bind(this);        
    }
    reset(){
        this.setState(initialState);
    }
    closeSelf(){
        this.props.close()
        this.reset()
    }
    validate(value, column) {
        // console.log(this.state.entity)
        let currentValue = value;
        if (this.state.validators) {
          let valResult = Validate(
            currentValue,
            this.state.validators,
            this.state.getValidationContext ? this.state.getValidationContext() : null,
            "creation"
          );
          let errs = {};
          if (valResult !== null) {
            this.setState({ popUpError: valResult });
          } else {
            this.setState({ popUpError: "" });
          }
        }
      }
    updateEntity(){
        if(this.state.popUpError===""){
            const request = this.state.row  
            request[this.state.key]=this.state.value                                 
            this.state.entity.executePut(request,null,this.state.entity.editUrl, {},true)
            this.props.close()
        }
    }  
    ShowError() {
        if (this.state.popUpError != "") {
            return <div className="row">
                <div className="col">
                    <div className="alert alert-danger" role="alert">
                        {this.state.popUpError}
                    </div>
                </div>
            </div>
        }
    }
    setConfig(conf){
        this.setState({
            key:conf.key,
            label:conf.label,
            value:conf.value,
            validators:conf.validators,
            entity:conf.entity,
            row:conf.row
        })
    }
    

    onTextChange(value) {
        this.setState({ value:value });
        this.validate(value, this.state.key)
    }

    render(){
        return <PanelModal isOpen={this.props.isOpen} className={'panel-full'} toggle={(event) => { this.props.close() }}>
        <ModalHeader>Update {this.state.label}</ModalHeader>
        <ModalBody> 
        {this.ShowError()}
        <div className="">
        <div className="row">
        <div className="mb-3 col-sm">
            <span className="form-label fw-bold d-block mb-1">{this.state.label}</span>
            {this.state.key === "emailToSend" ? (
                <CKEditorInput onChange={(value) => { this.onTextChange(value) }} value={this.state.value}/>
            ):(    
                <textarea placeholder={this.state.label} autoFocus className="form-control m-0 border border-2 border-primary p-2" value={this.state.value} onChange={(event) => { this.onTextChange(event.target.value) }}></textarea>
            )}
          </div>
        </div>
        </div>
        </ModalBody>
        <ModalFooter>
            <Button color="light" className="mr-auto" onClick={()=>this.updateEntity()}>Save</Button>
            <Button className="pull-left" onClick={()=>this.closeSelf()}>
                {EM.t('util.closeButton')}
            </Button>
        </ModalFooter>
    </PanelModal>
    }
};