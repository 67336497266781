/* global EM */
/* eslint no-return-assign: 0 */
import React, { Component } from "react";
import _ from "underscore";
import { Form, FormGroup, Input, Label } from "reactstrap";

export default class CheckBoxEditField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: this.getGroupName(),
      hidden: false,
      selected: this.props.value || [],
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onChangeOption = this.onChangeOption.bind(this);
  }
  componentDidMount() {
    if (this.props.sticky === false)
      document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    if (this.props.sticky === false)
      document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    let self = this;
    if (this.props.sticky === false) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        //close this
        this.setState({ hidden: true }, () => {
          if (this.props.onUpdate) this.props.onUpdate(self.getValue());
        });
        return;
      }
      if (this.state.hidden === true) this.setState({ hidden: false });
    }
  }

  getGroupName() {
    return `${Math.random() * (999999999 - 111111111) + 111111111}`;
  }

  getValue() {
    return this.state.selected;
  }

  onChangeOption(event, value) {
    let selected = [...this.state.selected];
    if (event.target.checked === true && !_.contains(selected, value))
      selected.push(value);
    else if (event.target.checked === false)
      selected = _.filter(selected, (item) => {
        return item !== value;
      });

    this.setState({ selected });
  }

  render() {
    return (
      <div className="checkList-container">
        <div
          hidden={this.state.hidden}
          ref={this.wrapperRef}
          className="checkList"
        >
          <Form>
            {_.map(this.props.options, (option) => {
              return (
                <FormGroup key={`FG${this.state.groupName}-${option.value}`}>
                  <Label>
                    <Input
                      className="cellEditCheck"
                      type="checkbox"
                      key={`${this.state.groupName}-${option.value}`}
                      name={this.state.groupName}
                      value={option.value}
                      checked={_.contains(this.state.selected, option.value)}
                      onChange={(event) =>
                        this.onChangeOption(event, option.value)
                      }
                    />
                    <span>{option.label}</span>
                  </Label>
                </FormGroup>
              );
            })}
          </Form>
        </div>
      </div>
    );
  }
}
