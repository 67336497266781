const { DateTime, Settings } = require("luxon");

// Settings.defaultZoneName = "utc";
let localOffset = - (new Date().getTimezoneOffset() / 60);

const Dates = {
    isoCache: {},
    arbCache: {},
    arbCacheDayJs: {},
    DATETIME_MED: DateTime.DATETIME_MED,
    DATETIME_LONG: {  month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', literal: '' },
    DATE_SHORT: 'L/d/y',
    DATE_SHORT_FULL_YEAR: 'L/d/yyyy',
    SLOT_FORMAT:'LL/dd/yyyy hh:mm a',
    DATE_PREF: 'L/y',
    MONTH_YEAR: { month: 'long', year: 'numeric' },
    MONTH_SHORT: { month: 'short' },
    MONTH_YEAR_SHORT: { month: 'short', year: 'numeric' },
    epicBegin: DateTime.utc(1970, 1, 1).startOf('year'),
    epicEnd: DateTime.utc(2100, 12, 31).startOf('year'),
    epicDayRange: null, 
    epicMonthRange: null,
    epicYearRange: null,    
    usableBegin: DateTime.fromMillis(946702800000),
    usableEnd: DateTime.fromMillis(2082690000000), 
    visualizableBegin: DateTime.local().plus({ years: -5 }).startOf('year'),
    visualizableEnd: DateTime.local().plus({ years: 10 }).endOf('year'), 
    now: () => {
        return DateTime.local();
    },
    fromISO: (str, zone=null) => {
        if (!Dates.isoCache[str]) {
            Dates.isoCache[str] = DateTime.fromISO(str, { zone: zone });
        }
        return Dates.isoCache[str];
    },
    fromStr: (str, format) => {
        let formatStr = format || Dates.DATE_SHORT;
        let key = str + ':' + formatStr;

        if (!Dates.arbCache[key]) {            
            Dates.arbCache[key] = DateTime.fromFormat(str, formatStr);
        }
        return Dates.arbCache[key];
    },
    fromDate: (jsDate) => {        
        return DateTime.fromJSDate(jsDate);
    },    
    fromMs: (ms) => {
        return DateTime.fromMillis(ms);
    },
    fromObject: (obj) => {
        return DateTime.fromObject(obj);
    },
    toMonthYearStr(dt) {
        return dt.toLocaleString(Dates.MONTH_YEAR);
    },
    toMonthYearStrShort(dt) {
        return dt.toLocaleString(Dates.MONTH_YEAR_SHORT);
    },
    toMonthStrShort(dt) {
        return dt.toLocaleString(Dates.MONTH_SHORT);
    },
    toDateShort(dt){
        return dt.toLocaleString(Dates.DATE_SHORT);
    },
    toDateString(dt){
        return dt.toLocaleString("LL/dd/yyyy");
    },
    toDateTime(dt){
        return dt.toFormat(Dates.SLOT_FORMAT);
    },    
    toJS(dt){
        return new Date(dt.toISO().replace(':00.000Z',''));
    },
    toGroupedStr(dt, grouping) {
        let g = grouping.toLowerCase();
        if (g === 'year') {
            return dt.year;
        } else if (g === 'quarter') {
            return 'Q' + dt.quarter + ' ' + dt.year;
        } else {
            return dt.toLocaleString(Dates.MONTH_YEAR);
        }
    },
    isValidDateStr: (str) => {
        try{            
            let attempt1 = Dates.fromStr(str, Dates.DATE_SHORT_FULL_YEAR);
            if (!attempt1.isValid){
                return false;
            }else{
                return true;
            }
        }catch(e){
            return false;
        }
    },
    isoToDateTime: (str, format) => {
        if (!str) return null;   
        let dt = Dates.fromISO(str).plus({ hours: localOffset });
        return dt.toLocaleString(format || Dates.DATETIME_MED);
    },
    convertDateStringWithT(dateString) {
        // Split the date string into its components
        
        let [datePart, timePart] = dateString.split('T');
       
        // Further split the date part into month, day, and year
        let [year, month, day] = datePart.split('-');

        // Remove leading zero from the month
        month = parseInt(month, 10).toString();

        // Reassemble the date string
        return `${month}/${day}/${year}`;
    },
    _getArrayOfDatesInternal(unit, begin, end) {
        let output = [];
        let counter = begin;
        while (counter <= end) {
            output.push(counter);
            counter = counter.plus({ [unit]: 1 });
        }
        return output;
    },
    getArrayOfDays(begin, end) {
        if (!Dates.epicDayRange) {            
            Dates.epicDayRange = Dates._getArrayOfDatesInternal('day', Dates.epicBegin, Dates.epicEnd);
        }        
        let beginIndex = Math.floor(begin.diff(Dates.epicBegin, 'days').days);
        let endIndex = Math.ceil(end.diff(Dates.epicBegin, 'days').days);

        return { dates: Dates.epicDayRange.slice(beginIndex, endIndex), beginIndex, endIndex };
    },    
    getArrayOfMonths(begin, end, isNewStyle) {
        if (!Dates.epicMonthRange) {
            Dates.epicMonthRange = Dates._getArrayOfDatesInternal('month', Dates.epicBegin, Dates.epicEnd);
        }

        let beginIndex = ((begin.year - 1970) * 12) + begin.month - 1;
        let endIndex = ((end.year - 1970) * 12) + end.month;
        
        return { dates: Dates.epicMonthRange.slice(beginIndex, endIndex), beginIndex, endIndex };
    },
    getArrayOfYears(begin, end) {
        if (!Dates.epicYearRange) {
            Dates.epicYearRange = Dates._getArrayOfDatesInternal('year', Dates.epicBegin, Dates.epicEnd);
        }        
        let beginIndex = begin.year - 1970;
        let endIndex = end.year - 1970 + 1;

        return { dates: Dates.epicYearRange.slice(beginIndex, endIndex), beginIndex, endIndex };
    },
    currentMonthPlusYears(years) {
        let today = DateTime.local();
        return [today, today.plus({ years: years })];
    },
    getMonthRangeFromMonthYearStrs(begin, end, years) {
        let output = [];        
        if (begin) {
            output[0] = Dates.fromStr(begin, Dates.DATE_PREF);
        } else {
            output[0] = DateTime.local().startOf('month');
        }

        if (end) {
            output[1] = Dates.fromStr(end, Dates.DATE_PREF);
        } else {
            output[1] = output[0].plus({ months: (12 * (years || 3)) - 1 });
        }
        return output;
    },
    doRangesOverlap(a, b, c, d) {
        return (a >= c && a <= d) || (b >= c && b <= d) || (a >= c && b <= d);
    },
    doMonthRangesOverlap(range1, range2) {
        if (range1.beginIndex < range2.beginIndex && range2.beginIndex < range1.endIndex) {        
            return true;
        }
        if (range1.beginIndex < range2.endIndex && range2.endIndex < range1.endIndex) {
            return true;
        }
        if (range2.beginIndex <= range1.beginIndex && range1.endIndex <= range2.endIndex) {
            return true;
        }
        return false;
    },
};

export default Dates;