/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState } from 'react'
import { Form, FormGroup, Label, Button, Card,CardBody,CardHeader} from 'reactstrap'
import { ValidationError } from 'xml2js'
// import logo from '../images/logo.svg';
import MetaInfo from './MetaInfo'
import Authenticator from '../app/Authenticator'
import logo from '../assets/logo.svg'
import Spinner from '../components/Spinner'
import Signup from './SignUp'
import companyIcon from "../assets/companySignup.svg";

export default function LoginSelector(props) {
  let loginModes = props.instance.loginModes
  let showEnterprise = loginModes.indexOf('Enterprise') > -1
  const authenticator = new Authenticator(props.instance)
  const [signUpFormValues, setSignUpFormValues] = useState({
    firstName: '',
    lastName: '',
    name:'',
    emailAddress:'',
    address:'',
    address2:'',
    phone:'',
    city:'',
    state:'',
    zip:'',
  })
  const [currentPage, setCurrentPage] = useState('Login')
  const [email, setEmail] = useState('')
  const [loading, setloading] = useState(false)
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const [responseMessage, setResponseMessage] = useState('')
  function validate() {
    setLoginError('')
    let valid = true
    // if(isForgetPassword===false) {
    if (currentPage === 'Login') {
      valid = email.trim().length > 0 && password.trim().length > 0
      if (email.trim().length === 0 || password.trim().length === 0)
        setLoginError('Email/Username and Password are required')
    } else if (currentPage === 'Forgot Password') {
      valid = email.trim().length > 0
      if (email.trim().length === 0) setLoginError('Email required')
    } else {
      Object.keys(signUpFormValues).forEach(key => {
        if (signUpFormValues[key].length === 0 && key !== "address2") {
          valid = false
          setLoginError('Please fill out all the fields')
        }
      })
    }

    return valid
  }
  const resetValidate = () => {
    setLoginError('')
    setResponseMessage('')
  }
  const handleSubmit = async () => {
    const isValid = validate()
    if (isValid === true) {
      setloading(true)
      // if(isForgetPassword===false) {
      if (currentPage === 'Login') {
        await authenticator
          .login({ email, password })
          .then(() => {
            window.localStorage.setItem('rm.login_mode', 'Enterprise')
            window.location.reload()
          })
          .catch((error) => {
            setLoginError(error.message)
          })
        setloading(false)
        // } else {
      } else if (currentPage === 'Forgot Password') {
        await authenticator
          .forgetPassword({ email })
          .then((data) => {
            setloading(false)
            setResponseMessage(
              'New Password has been sent on your registered phone number.',
            )
          })
          .catch((error) => {
            setloading(false)
            setLoginError(error.message)
          })
        } else {
          try {
            const company = await authenticator.registerCompany(signUpFormValues)
            setloading(false)
            setResponseMessage(`User has been created successfully for your company ${company.name}. The Password has been sent to your mobile number`)
            setCurrentPage("Login")
          } catch (error) {
            setloading(false)
            setLoginError(error.message)            
          }
          // try {
          //   const company = await authenticator.register(signUpFormValues)
          //   setloading(false)
          //   if(company) {
          //     const fullName = company.name.split(' ')
          //     const params = {
          //       companyId:company.companyId,
          //       firstName: fullName[0],
          //       lastName: fullName.length > 1 ? fullName[1] : "",
          //       emailAddress: company.emailAddress,
          //       phoneNumber: company.phone,
          //       userTypeId: 2,
          //       roleId: 3,
          //     }
          //     await authenticator.createUser(params)
          //     setResponseMessage(`User has been created successfully for your company ${company.name}. The Password has been sent to your mobile number`)
          //     setCurrentPage("Login")
          //   }
          // } catch (error) {
          //   setloading(false)
          //   setLoginError(error.message)
          // }
          }
    }
  }

  // console.log(loginDetails);
  return (
    <div>
      <header className="header py-3" hidden={false}>
        <section className="container-fluid d-flex">
          <a href="/" className="">
            <img src={logo} alt="Flashops" />
          </a>
        </section>
      </header>
      <main hidden={false} className="heightFull1">
        <section className="container-fluid heightFull">
          <div className="row heightFull">
            <div className="col-lg-5 aboutBG d-none d-lg-flex">
              <div className="row pt-5 px-4 h-100">
                <div className="col-11 px-0 mt-5"></div>
                <div className="col-12 px-0 py-2 mt-auto">
                  <h5>
                    © 2024 Flashop.
                    <br />
                    All rights reserved.
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-7 mx-auto d-flex align-items-center justify-content-center flex-column">
              <form className="col-md-8 col-lg-6 mx-auto d-flex align-items-center login-form-gap justify-content-center flex-column">
                {currentPage === 'SignUp' ? (
                  <Signup
                    loginError={loginError}
                    loading={loading}
                    responseMessage={responseMessage}
                    setSignUpFormValues={setSignUpFormValues}
                    signUpFormValues={signUpFormValues}
                    validate={validate}
                  />
                ) : (
                  <>
                    <div className="w-100">
                      <label className="form-label fw-bold">
                        Email/Username
                      </label>
                      <input
                        disabled={loading}
                        type="text"
                        className={
                          'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
                        }
                        onChange={(event) => {
                          setEmail(event.target.value)
                          validate()
                        }}
                        value={email}
                      />
                    </div>
                    {currentPage === 'Login' && (
                      <div className="w-100">
                        <label className="form-label fw-bold">Password</label>
                        <input
                          disabled={loading}
                          type="password"
                          className={
                            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
                          }
                          onChange={(event) => {
                            setPassword(event.target.value)
                            validate()
                          }}
                          value={password}
                        />
                      </div>
                    )}
                    {currentPage === 'Login' ? (
                    <div className="w-100 d-flex justify-content-end">
                      <a
                        className="noDescoration text-primary pointer"
                        disabled={loading}
                        onClick={() => {
                          setCurrentPage((prevState) => prevState === 'Login'? 'Forgot Password' : 'Login')
                          resetValidate()
                        }}
                      >
                        Forgot Password?
                      </a>
                    </div>
                    ): null}
                    {loading && <Spinner text="Processing..." />}
                    <div className="w-100">
                      <div className="col-12" hidden={loginError.length <= 0}>
                        <div className="alert alert-danger" role="alert">
                          {loginError}
                        </div>
                      </div>
                      <div
                        className="col-12"
                        hidden={responseMessage.length <= 0}
                      >
                        <div className="alert alert-success" role="alert">
                          {responseMessage}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="w-100">
                  {showEnterprise ? (
                    <Button
                      onClick={async () => {
                        handleSubmit()
                      }}
                      className="btn btn-primary pl-4 pr-4 w-100"
                    >
                      {/* {!isForgetPassword ? 'LOGIN' : 'RESET PASSWORD'} */}
                      {currentPage === 'Forgot Password' ? 'RESET PASSWORD' : currentPage.toUpperCase()}
                    </Button>
                  ) : null} 
                </div>
                <h5>Welcome to Flashop Cloud!</h5>
                {currentPage === 'Login' ? (
                <Card className="cardBG mb-2">
                  <CardBody>
                  <div className="d-flex justify-space-between">
                    <div className="d-flex flex-column">
                      <h5>Create a Company</h5>
                      <p>In the next steps, we will create your company & get you started</p>
                    </div>
                      <img src={companyIcon} alt=""/>
                  </div>
                  <Button 
                    onClick={() => {
                    setCurrentPage((prevState) => prevState === 'SignUp' ? 'Login' : 'SignUp')
                    resetValidate()
                  }}
                  className="btn btn-primary w-100"
                  >{currentPage === 'SignUp' ? 'Login' : 'SIGN UP'}
                  </Button>
                  </CardBody>
                </Card>
                ) : <span className="d-flex"><p>{currentPage === 'Forgot Password'?'Back to ':'Already have an account?'}</p>
                <a
                  className="ml-2"
                  onClick={() => {
                    setCurrentPage((prevState) => prevState === 'SignUp' ? 'Login' : 'SignUp')
                    resetValidate()
                  }}
                >
                Login
              </a></span>}
              </form>
              
            </div>
          </div>
        </section>
      </main>
      <div hidden={true} className="landing-page login-selector">
        <div>
          <div className="content clearfix">
            <Form>
              <FormGroup>
                <Label className="form-label fw-bold">Email/Username</Label>
                <input
                  type="text"
                  className={
                    'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
                  }
                  onChange={(event) => {
                    setEmail(event.target.value)
                    validate()
                  }}
                  value={email}
                />
              </FormGroup>
              <FormGroup>
                <Label className="form-label fw-bold">Password</Label>
                <input
                  type="password"
                  className={
                    'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
                  }
                  onChange={(event) => {
                    setPassword(event.target.value)
                    validate()
                  }}
                  value={password}
                />
              </FormGroup>
              <FormGroup
                hidden={loginError.length <= 0}
                className="alert alert-danger errorMessage"
              >
                <Label>{loginError}</Label>
              </FormGroup>
            </Form>

            <div>
              {showEnterprise ? (
                <button
                  onClick={async () => {
                    if (validate()) {
                      await authenticator
                        .login({ email, password })
                        .then(() => {
                          window.localStorage.setItem(
                            'rm.login_mode',
                            'Enterprise',
                          )
                          window.location.reload()
                        })
                        .catch((error) => {
                          setLoginError(error.message)
                        })
                    }
                  }}
                  className="btn btn-primary pl-4 pr-4 w-100"
                >
                  LOGIN
                </button>
              ) : null}
            </div>
          </div>
          <div className="content clearfix"></div>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  )
}
