/* global EM */
import React, { Component } from "react";
import _ from "underscore";
import { FormGroup, Label } from "reactstrap";
import Select from 'react-select';
import Routes from '../app/Routes';
import PageTitle from '../components/PageTitle';
import CalendarView from '../components/CalendarView';
import CalendarDayDetails from '../components/CalendarDayDetails';
import Dates from '../util/Dates';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.locations;
    let user = EM.account.get();
    let company = _.findWhere(user.userCompanies, { defaultCompany: true });
    this.state = {
      loading:false,
      parentRecordDetail: { companyId: company.companyId },
      company : company,
      locationOptions:[],
      data:[],
      locationId:null,
      showCalendar:false,
      showDetails:false,
      dayDetails:null,
      selectedDate:null,
      isSuperAdmin:EM.isSuperAdmin(),
    };
    this.locationChange = this.locationChange.bind(this);
    this.onCalendarChange = this.onCalendarChange.bind(this);
    this.onDayClick = this.onDayClick.bind(this);
  }

  componentDidMount(){
    this.setState({companyName:EM.getDefaultCompany().companyName})
    EM.locations.loadEntityByParent(this.state.parentRecordDetail, true).then(()=>{
      this.setState({locationOptions:EM.locations.asOptionList(null, true)});
    });

  }

  toIntervals(locationId) {
    this.props.history.push(Routes.compose(Routes.client.intervals, { locationId }));
  }

  locationChange(locationId,date){
    let self =this;
    if(locationId && locationId>0)
    EM.calendar.executeGet(null,{locationId:locationId,date:(date ? date : new Date()).toISOString()},Routes.clientApi.calendar, {})
    .then((result)=>{
      self.setState({data:result, locationId,showCalendar:true,showDetails:false});
    });
  }

  onCalendarChange(date){
    if(date) this.locationChange(this.state.locationId,date);
  }

  async onDayClick(date){    
    if(date)
    {
      let self =this;
      if(self.state.locationId && self.state.locationId>0)
      {
        this.setState({loading:true})
        await EM.calendar.executeGet(null,{locationId:self.state.locationId,date:(date ? Dates.toDateTime(Dates.fromDate(date)) : new Date().toISOString())},Routes.clientApi.calendarday, {})
        .then((result)=>{        
          self.setState({dayDetails:result,showCalendar:false,showDetails:true,selectedDate:date});
    
        });
        this.setState({loading:false})
      }
      
    }
    else
    this.setState({showCalendar:false,showDetails:true});
  }


  render() {
    let self = this;
    if (EM.allLoaded(EM.locations)) {
      return (
        <div key="contents" className="page container-fluid">  
          <PageTitle title={`Calendar ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`} bar={true}></PageTitle>
          <div className="container">
              <FormGroup className="col-  mt-4 locationsSticky">
                <Label>Location</Label>
                <Select
                  className="single-select"
                  classNamePrefix="single-select"
                  isSearchable={true}
                  isMulti={false}
                  isClearable={true}
                  options={this.state.locationOptions}
                  placeholder={'Please select location'}
                  onChange={(selection) => {                    
                    self.locationChange(selection.value);
                  }}
                />
              </FormGroup>
                  {
                    self.state.showCalendar ? <CalendarView onDayClick={(date)=>{self.onDayClick(date);}} onCalendarChange={(date)=>{self.onCalendarChange(date)}} data={this.state.data}></CalendarView> : ''
                  }
                  {
                    self.state.showDetails ? <CalendarDayDetails loading={this.state.loading} onClose={()=>{self.locationChange(self.state.locationId)}} onDayClick={(date)=>{self.onDayClick(date);}} data={this.state.dayDetails} locationId={this.state.locationId} date={this.state.selectedDate} ></CalendarDayDetails> :''
                  }
                
          </div>
        </div>
      );
    }
    return (
      <div id="pre-load"></div>
    )
   
  }
}

export default Calendar;
