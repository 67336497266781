/* global EM */
import React, { Component } from "react";
import { Validators } from "../util/EntityValidators";
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import { Type } from "react-bootstrap-table2-editor";

class Intervals extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.intervals;
    let user = EM.account.get();
    let company = _.findWhere(user.userCompanies, { defaultCompany: true });
    let locationId = this.props.match.params.locationId;
    this.state = {
      parentRecordDetail: { companyId: company.companyId, locationId },
      locationId: locationId,
      durationOptions: this.getDurationOption(),
      bookingCountOptions: this.getBookingCountOption(),
      daysOptions:this.getDaysOptions(),
      location:{},
      isSuperAdmin:EM.isSuperAdmin(),
    };   
    this.entity.loadEntityByParent(this.state.parentRecordDetail);
  }

  componentDidMount(){    
    this.setState({companyName:EM.getDefaultCompany().companyName})  
    this.getLocation();
  }

  getLocation(){  
    let location = _.findWhere(EM.locations.get(),{locationId:parseInt(this.props.match.params.locationId)});
    if(!location)
    {
      let user = EM.account.get();
      let company = _.findWhere(user.userCompanies, { defaultCompany: true });
      EM.locations.loadEntityByParent({companyId:company.companyId}).then(()=>{
        let location = _.findWhere(EM.locations.get(),{locationId:parseInt(this.props.match.params.locationId)});
        this.setState({location:location});
      });
    }
    else{
      this.setState({location});
    }

  }
  getDaysOptions() {
    return [
      { label: 'Monday', value: 1 },
      { label: 'Tuesday', value: 2 },
      { label: 'Wednesday', value:3 },
      { label: 'Thursday', value: 4 },
      { label: 'Friday', value: 5 },
      { label: 'Saturday', value: 6 },
      { label: 'Sunday', value: 7 }
    ];
  }

  getDurationOption() {
    return [
      { label: 10, value: "10" },
      { label: 15, value: "15" },
      { label: 20, value: "20" },
      { label: 30, value: "30" },
      { label: 60, value: "60" },
    ];
  }

  getBookingCountOption() {
    return [
      { label: 1, value: "1" },
      { label: 2, value: "2" },
      { label: 3, value: "3" },
      { label: 4, value: "4" },
    ];
  }

  getTitle(){
    return `Intervals for ${this.state.location.name}`;
  }

  render() {
    let columns = [];
    let data = [];
    let allLoaded = EM.allLoaded(this.entity,EM.locations);    
    if (allLoaded) {
      data = this.entity.get();
      columns = [
        {
          dataField: "startDateTime",
          text: "Start Date Time",
          validators: [Validators.required],
          asDateTime: true,
          // formatter: (cell, row) => {          
          //   return '';
          // }
        },
        {
          dataField: "endDateTime",
          text: "End Date Time",
          validators: [Validators.required],
          asDateTime: true,
        },
        {
          dataField: "duration",
          text: "Duration",
          validators: [Validators.required],
          editor: {
            type: Type.SELECT,
            options: this.state.durationOptions,
          },
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "bookingCount",
          text: "Booking Count",
          validators: [Validators.required],
          editor: {
            type: Type.SELECT,
            options: this.state.bookingCountOptions,
          },
        },
        {
          dataField: "days",
          text: "Days",
          validators: [Validators.required],
          asCheckbox: true,
          options: this.state.daysOptions,
        }
      ];
    }
    return (
      <div key="contents" className="page container-fluid">
        <EntityTable
          entity={this.entity}          
          pageTitle={`${this.getTitle()} ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`}
          data={data}
          columns={columns}
          className="table-overflow-container"
          hideImportExport={true}
          hideAudit={true}
          parentRecordDetail={this.state.parentRecordDetail}
          onClose={() => {
            this.data = this.entity.get();
          }}
        />
      </div>
    );
  }
}

export default Intervals;
