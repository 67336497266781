import React from 'react'
import {
    Card,
    CardBody,
    CardHeader,
    Button,
  } from 'reactstrap'
  
const CardStats = ({label, value, color, navigate, to}) => {
  return (
    <Card className="" style={{maxHeight:"160px", height:"160px"}}>
        <CardHeader className="d-flex justify-content-center" style={{ backgroundColor: 'transparent'}}>
        <h6>{label}</h6>
        </CardHeader>
        <CardBody className="d-flex flex-column align-items-center">
            <h3 style={{ color: color }}>{value}</h3>
            {navigate? (
              // <Button color="primary" >
              <i className='fas fa-eye' style={{color: '#007bff', cursor: "pointer"}} onClick={()=>navigate.push(to)}/>
              //   View Details
              // </Button>
            ): null}
        </CardBody>
    </Card>
  )
}

export default CardStats;

