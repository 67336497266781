import * as types from '../actionTypes';

export default function notifications(state = [], action) {
    switch (action.type) {
        case types.LOAD_NOTIFICATIONS:
            return action.notifications;
        case types.REMOVE_NOTIFICATION:
            let leftNotifications = state.filter(x => {
                return x.id !== action.notification.id;
            });
            return leftNotifications;
        case types.CLEAR_NOTIFICATIONS:
            return {}
        default:
            return state;
    }
}