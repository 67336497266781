/* global EM */
import React, { useState, useEffect } from "react";
import _ from "underscore";
import Routes from "../app/Routes";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {Input} from 'reactstrap'
export default function GroupUsers(props) {
  const { SearchBar } = Search;
  let user = EM.account.get();
  let company = _.findWhere(user.userCompanies, { defaultCompany: true });
  let eventGroupId = props.match.params.eventGroupId;
  const initialState = {
    parentRecordDetail: { companyId: company.companyId, eventGroupId },
    eventGroupId: eventGroupId,
    location: false,
    isSuperAdmin: EM.isSuperAdmin(),
    users: [],
    eventUsers:[],
    selectedUserIds: [],
    selectedEventUserIds: [],
    filterOptions:[
      'Company',
      'Event',
      'Group'
    ],
    SelectfilterOptions:'Company'
  };
  const [state, setState] = useState(initialState);
  const entity = EM.groupUsers;
  const getTitle = () => {
    return `Users ${state.location.name ? "for " + state.location.name : ""}`;
  };
  const loadData = async () => {
    EM.groupUsers.clear();
    await entity.loadEntityByParent(state.parentRecordDetail);
    let body = {
      companyId:
        EM.isSuperAdmin() !== true && company
          ? company.companyId
          : company.companyId,
      userTypeId: !EM.isSuperAdmin() && !EM.isAdmin() ? 1 : null,
    };
    let commonUserIds
    await EM.users
      .executePost(body, {}, Routes.clientApi.userSearch, {}, true)
      .then((data) => {
        let transformedArray = EM.users
          .get()
          .filter((item) => item.firstName !== null)

        commonUserIds = EM.users
          .get()
          .map((item) => item.userId) 
          .filter((userId) =>
            entity.get().some((item) => item.userId === userId)
          );
        
        if (commonUserIds) {
          transformedArray = transformedArray.map((item) => ({
            ...item,
            assigned: commonUserIds.includes(item.userId) ? 'a' : 'z',
          }));
        }
        setState((prevState) => ({
          ...prevState,
          selectedUserIds: commonUserIds,
          users: transformedArray,
        }));
        
        
      });
    await EM.eventGroups
      .loadEntityByParent({ companyId: EM.getDefaultCompany().companyId }, true)
      .then(async(result) => {
        
        if (EM.eventGroups.get(true).length === 0) {
          props.history.push(Routes.compose(Routes.client.eventGroups));
        }
        let location = _.findWhere(EM.eventGroups.get(true), {
          eventGroupId: Number(eventGroupId),
        });

        if(location===undefined){
          props.history.push(Routes.compose(Routes.client.eventGroups));
          return
        } else {
            await EM.eventGroups.executeGet(null, null, Routes.clientApi.eventUsers, { locationId: location.locationId }).then(async(result) => {
              
              let transformedArray = result
            .filter((item) => item.firstName !== null)

          const commonEventUserIds = result
            .map((item) => item.userId) 
            .filter((userId) =>
              entity.get().some((item) => item.userId === userId)
            );
          
          if (commonEventUserIds) {
            transformedArray = transformedArray.map((item) => ({
              ...item,
              assigned: commonEventUserIds.includes(item.userId) ? 'a' : 'z',
            }));
          }
          // console.log(state.selectedUserIds)
          // const newArray = commonUserIds; // Create a copy of the existing array
          // console.log(newArray)
          // newArray.push(...commonEventUserIds);
          // console.log(newArray)
          setState((prevState) => ({
            ...prevState,
            selectedEventUserIds: commonEventUserIds,
            eventUsers: transformedArray,
          }));
          })  
        setState((prevState) => ({
          ...prevState,
          location: location,
        }));
      }
      });
    
  };
  const assignUsers = () => {
    const { selectedUserIds, eventGroupId,selectedEventUserIds,SelectfilterOptions } = state;

    let body = {
      userIds: SelectfilterOptions==='Event'?selectedEventUserIds:selectedUserIds,
      eventGroupId: eventGroupId,
    };
    EM.groupUsers
      .executePost(body, {}, Routes.clientApi.assignGroupUsers)
      .then((data) => {
        console.log(data);
        console.log(EM.groupUsers.get());
        loadData()
      });
  };
  const onSelect = (row, isSelect, rowIndex, e) => {
    const {SelectfilterOptions} = state
    const selectedUsers = (state.SelectfilterOptions==='Event')?state.selectedEventUserIds:state.selectedUserIds
    if (isSelect === true) {
      if (!selectedUsers.includes(row.userId)) {
        if(SelectfilterOptions==='Event'){
          setState((prevState) => ({
            ...prevState,
            selectedEventUserIds: [
              ...prevState.selectedEventUserIds,
              row.userId,
            ],
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            selectedUserIds: [
              ...prevState.selectedUserIds,
              row.userId,
            ],
          }));
        }
        
      }
    }

    if (isSelect === false) {
      if (selectedUsers.includes(row.userId)) {
        if(SelectfilterOptions==='Event'){
          setState((prevState) => ({
            ...prevState,
            selectedEventUserIds: prevState.selectedEventUserIds.filter(
              (item) => item !== row.userId
            ),
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            selectedUserIds: prevState.selectedUserIds.filter(
              (item) => item !== row.userId
            ),
          }));
        }
      }
    }
  }
  const onSelectAll= (isSelect, rows, e) => {
    if (isSelect === false) {
      if(state.SelectfilterOptions==='Event')
     {
      setState((prevState) => ({
        ...prevState,
        selectedEventUserIds: []
      }));
     } else {
      setState((prevState) => ({
        ...prevState,
        selectedUserIds: []
      }));
     }
      
    } else {
      let userIds=[]
      if(state.SelectfilterOptions==='Event')
     {
      userIds = state.eventUsers.map((obj) => obj.userId);
      setState((prevState) => ({
        ...prevState,
        selectedEventUserIds: userIds
      }));
    }else{
      userIds = state.users.map((obj) => obj.userId);
      setState((prevState) => ({
        ...prevState,
        selectedUserIds: userIds
      }));
     }
      
    }
    // ...
  }
  useEffect(() => {
    loadData();
    return () => {
      // Cleanup logic
    };
  }, []);

  let columns = [];
  let data = [];

  columns = [
    {
      dataField: "assigned",
      text: "assigned",
      sort:true,
  hidden: true
    },
    {
      dataField: "firstName",
      text: "First Name",
      editable: false,
    },
    {
      dataField: "lastName",
      text: "Last Name",
      editable: false,
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      editable: false,
    },
    {
      dataField: "emailAddress",
      text: "Email",
      editable: false,
    },
    {
      dataField: "city",
      text: "City",
      editable: false,
    },
  ];
  if (state.users.length && state.location) {
    if(state.SelectfilterOptions==='Company')
    {data = state.users;}
    if(state.SelectfilterOptions==='Event')
    {data = state.eventUsers;}
    if(state.SelectfilterOptions==='Group')
    {data = state.users.filter(
      (item) => item.assigned === 'a'
    );}
  }
  
  return (
    <div key="contents" className="page container-fluid">
      <ToolkitProvider
        keyField="userId"
        data={data}
        columns={columns}
        search={{
          searchFormatted: true,
        }}
        loading={true}
      >
        {(props) => (
          <div>
            <div className="clearfix page-title page-title-bar">
              <h3>{`${getTitle()} ${
                state.companyName && state.isSuperAdmin
                  ? `(${state.companyName})`
                  : ""
              }`}</h3>
              <div className="page-title-children">
                <div className="table-tools">
                  <div className="btn-group">
                  <Input value={state.SelectfilterOptions} type="select" onChange={(event) => { setState((prevState) => ({
          ...prevState,
          SelectfilterOptions: event.target.value,
        }))}}>       
        {state.filterOptions &&
          _.map(state.filterOptions, (value, index) => {
            return (<option value={value} key={index}>{value}</option>);
          })
        }
      </Input>
      </div>
      <div className="btn-group">
                    <button
                      className="btn btn-primary btn-sm"
                      title="Create"
                      onClick={() => assignUsers()}
                    >
                      <i className="fas fa-save"></i>
                      <span>Save</span>
                    </button>
                  </div>
                  <label htmlFor="search-bar-0" className="search-label">
                    <SearchBar
                      className="form-control form-control-sm"
                      {...props.searchProps}
                    />
                  </label>
                </div>
              </div>
            </div>
            <BootstrapTable
              sort={{ dataField: "assigned", order: "asc" }}
              pagination={paginationFactory({
                sizePerPage: 100,
                hideSizePerPage: true,
                hidePageListOnlyOnePage: true,
                paginationTotalRenderer: (from, to, size) => {
                  return EM.t("util.table.paginationTotal", false, [
                    from,
                    to,
                    size,
                  ]);
                },
              })}
              selectRow={{
                // hideSelectAll: true,
                mode: "checkbox",
                bgColor: "lightgreen",
                selected: state.SelectfilterOptions==='Event'?state.selectedEventUserIds:state.selectedUserIds, // should be a row keys array
                onSelect: (row, isSelect, rowIndex, e) => {
                  onSelect(row, isSelect, rowIndex, e)
                },
                onSelectAll: (isSelect, rows, e) => {
                  onSelectAll(isSelect, rows, e)
                },
              }}
              {...props.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}
