/* global EM */
import React, { Component } from "react";
import _ from 'underscore';
import Dates from '../util/Dates';

class TimeSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            times: [],
            selectedTime: this.props.selectedTime,
            internalDate: "",
            appointmentsByDate: []
        };
        this.getAppointmentsCount = this.getAppointmentsCount.bind(this);
    }
    componentDidMount() {
        this.loadSlots();
    }

    componentDidUpdate() {
        if (this.state.internalDate != this.props.date) {
            this.loadSlots();
            let appointments = _.groupBy(this.props.appointments, (appointment) => {
                return `${appointment.displayDate}`;
            });
            this.setState({ internalDate: this.props.date, appointmentsByDate: appointments });
        }
    }

    loadSlots() {
        let self = this;
        let locationId = this.props.locationId;
        if (locationId > 0 && typeof this.props.date === 'string') {
            let params = { locationId: locationId, date: this.props.date };
            let qs = { date: this.props.date, bookByAdmin: true };
            EM.availableSlots.searchEntity(params, qs, true).then(() => {
                self.setState({ times: EM.availableSlots.get() });
            });
        }
    }
    convertDateString(dateString) {
        // Split the date string into its components
        let [datePart, timePart, am_pm] = dateString.split(' ');

        // Further split the date part into month, day, and year
        let [month, day, year] = datePart.split('/');

        // Remove leading zero from the month
        month = parseInt(month, 10).toString();

        // Reassemble the date string
        return `${month}/${day}/${year} ${timePart} ${am_pm}`;
    }
    getAppointmentsCount(slot) {
        /**following was old code and had format issue on day part***/ 
        /**let appointments = this.state.appointmentsByDate[`${Dates.toDateShort(Dates.fromStr(this.props.date))} ${slot}`] || [];*/
        let dateToConvert = this.convertDateString(`${this.props.date} ${slot}`)
        let appointments = this.state.appointmentsByDate[dateToConvert] || [];
        return `(Appointments : ${appointments ? appointments.length : 0})`
    }

    render() {
        var self = this;
        return (
            <div>
                <label>Time Slot</label>
                <select className="form-control" onChange={this.props.timeChange} value={this.props.selectedTime}>
                    <option>Please Select</option>
                    {this.state.times.map(loc => <option value={loc}>{loc} {this.getAppointmentsCount(loc)}</option>)}
                </select>
            </div>
        )
    }
}
export default TimeSlot;