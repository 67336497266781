/* global EM */
import React, { Component } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import addDays from 'date-fns/addDays'
import Routes from '../app/Routes';
import _ from 'underscore';
import { Tabs, Tab } from 'react-bootstrap';
import Packages from "./labBilling/Packages"
import Summary from "./labBilling/Summary"

const ExcelJS = require("exceljs");

class LabBilling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentRecordDetail: { companyId: this.props.company.companyId },
            endDate: new Date(),
            startDate: new Date(),
            company: this.props.companys,
            orders: [],
            packageOrders: [],
            products: [],
            summary: {
                totalOrders:0,
                totalSales: 0,
                totalLabCost: 0,
                startDate: '',
                endDate: ''
            },
            packageOrdersSummary: {
                consumedPrice: 0,
                nonConsumedPrice: 0,
                totalOrders:0,
                totalSales: 0,
                consumed:0
            },
            companyConfig: null,
            recordsNotFound: '',
            singleUnitProduct: null,
            singleUnitSessionProduct: null,
            locationId: 0,
            tabKey: 'orders'
        };
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.createHeaderCell = this.createHeaderCell.bind(this);
        this.createHeaderRow = this.createHeaderRow.bind(this);
        this.initSummaryData();
    }

    componentDidMount() {
        this.getProducts();
        this.getCompanyConfig();
    }

    initSummaryData() {
        this.summary = {
            totalOrders: this.state.orders ? this.state.orders.length : 0,
            packagePrice:0,
            totalSales: 0,
            totalLabCost: 0,
            startDate: this.state.startDate ? this.state.startDate.toLocaleDateString() : '',
            endDate: this.state.endDate ? this.state.endDate.toLocaleDateString() : ''
        };
    }

    excelCellStyle() {
        return {
            font: { name: 'Arial', size: 10 },
            alignment: { vertical: 'middle', horizontal: 'left' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        }
    }

    excelHeaderStyle() {
        return {
            font: { bold: true, color: { argb: 'FFFFFFFF' } },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' },
                bgColor: { argb: 'FFFFFFFF' }
            },
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
        };
    }

    getCompanyConfig() {
        let self = this;
        EM.companyConfig.loadSingleEntity({ id: this.props.company.companyId }).then(() => {
            self.setState({ companyConfig: EM.companyConfig.get() || {} });
        });
    }

    getProducts() {
        let self = this;
        EM.products.executeGet(null, null, Routes.clientApi.products, { companyId: this.props.company.companyId }, true).then(() => {
            let products = EM.products.get();
            let singleUnitProduct = products.find((p) => p.productId === 2);
            let singleUnitSessionProduct = products.find((p) => p.productId === 1);
            self.setState({ products, singleUnitProduct, singleUnitSessionProduct });
        });
    }

    endDateChange(date) {
        var self = this;
        self.setState({ endDate: date, maxDate: date });
    }
    startDateChange(date) {
        var self = this;
        self.setState({ startDate: date, minDate: date });
    }

    loadData() {
        var self = this;
        var request = {
            startDate: self.state.startDate.toISOString().split('T')[0],
            endDate: self.state.endDate.toISOString().split('T')[0],
            locationId: self.state.locationId
        }
        EM.completedOrders.executeGet(null, request, Routes.clientApi.completedOrders, { companyId: this.props.company.companyId }, false).then((res) => {
            self.summary = Object.assign({}, self.summary, { totalOrders: res?.printOrders?.length ?? 0, startDate: request.startDate, endDate: request.endDate, totalLabCost: 0, totalSales: 0 });
            this.populateSummary(res.printOrders, res.packageOrders)
            self.setState({ orders: res?.printOrders, recordsNotFound: res?.printOrders && res.length > 0 ? '' : 'No records found.' })
            self.setState({ packageOrders: res.packageOrders })
        });
    }

    populateSummary(orders = [], packageOrders=[]) {
        if (!orders.length) return

        let ss = {
            totalSales: 0,
            totalLabCost: 0
        }
        let packageOrderSale = 0
        let consumedPrice = 0
        let nonConsumedPrice = 0
        let consumed = 0
        let self = this;
        
        packageOrders.forEach((packageOrder) => {
            packageOrderSale += packageOrder.price;
            consumed += packageOrder.consumed ? 1 : 0
            consumedPrice += packageOrder.consumed ? packageOrder.price : 0
            nonConsumedPrice += packageOrder.consumed ? 0 : packageOrder.price
        })

        orders.forEach((order, index) => {
            let totalUnits = 0
            let singleImageProducts = [...order.printOrderLineItems].filter((p) => p.product.productId === 2);
            let singleSessionProduct = [...order.printOrderLineItems].filter((p) => p.product.productId === 1);

            order.printOrderLineItems.forEach((lineItem) => {
                let product = lineItem.product;
                if (product && product.productId !== 1 && product.productId !== 2) {
                    let unit = (product.unit ? product.unit : 0) * lineItem.quantity;
                    totalUnits += unit;
                }
            })

            singleImageProducts.forEach((lineItem, index) => {
                let product = lineItem.product;
                if (product) {
                    let unit = product.unit ? product.unit : 0;
                    if (index + 1 > 1 && index + 1 <= 20) unit = 0.18;
                    if (index + 1 > 20) unit = 0.04;
                    totalUnits += unit;
                }
            })
            singleSessionProduct.forEach((lineItem, index) => {
                let product = lineItem.product;
                if (product) {
                    for (let i = 0; i < product.userSessionImageCount; i++) {
                        let unit = (self.state.singleUnitProduct.unit ? self.state.singleUnitProduct.unit : 0);
                        if (i + 1 > 1 && i + 1 <= 20) unit = 0.18;
                        if (i + 1 > 20) unit = 0.04;

                        totalUnits += unit;
                    }
                }
            })
            let hasSheet = _.find(order.printOrderLineItems, (lineItems) => {
                return lineItems.product.unit === 1;
            });
            
            // ss.totalSales += order.gross ? order.gross : 0
            ss.totalSales += order.prepaid ? order.prepaid.price + order.gross : order.gross ?? 0
            ss.totalLabCost += ((totalUnits + (hasSheet ? 1 : 0)) * (self.state.companyConfig.unitPrice ? self.state.companyConfig.unitPrice : 2))
        });

        this.setState({packageOrdersSummary:{totalSales:packageOrderSale, totalOrders:packageOrders.length, consumed:consumed, consumedPrice, nonConsumedPrice}})
        this.setState({summary: {totalLabCost:ss.totalLabCost, totalSales:ss.totalSales, totalOrders:orders.length, startDate: this.state.startDate.toLocaleDateString(), endDate: this.state.endDate.toLocaleDateString()}})
    }

    export() {
        let self = this;
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Sheet 1");
        let rowNumber = 4;
        self.state.orders.map((order, index) => {
            self.addOrderNumberHeading(sheet, order);
            let rows = [];
            let totalUnits = 0;
            let imageCount = 0;
            let imageUnit = 0;
            let singleImageProducts = [...order.printOrderLineItems].filter((p) => p.product.productId === 2);
            let singleSessionProduct = [...order.printOrderLineItems].filter((p) => p.product.productId === 1);
            let hasSheet = _.find(order.printOrderLineItems, (lineItems) => {
                return lineItems.product.unit === 1;
            });

            order.printOrderLineItems.map((lineItem) => {
                let product = lineItem.product;
                if (product && product.productId !== 1 && product.productId !== 2) {
                    let unit = (product.unit ? product.unit : 0) * lineItem.quantity;
                    totalUnits += unit;
                    rows.push([
                        product.name,
                        lineItem.quantity,
                        unit
                    ]);
                }
            });

            singleImageProducts.map((lineItem, index) => {
                let product = lineItem.product;
                if (product) {
                    let unit = product.unit ? product.unit : 0;
                    if (index + 1 > 1 && index + 1 <= 20) unit = 0.18;
                    if (index + 1 > 20) unit = 0.04;

                    totalUnits += unit;
                    imageUnit += unit;
                    imageCount += lineItem.quantity;
                }
            });

            singleSessionProduct.map((lineItem, index) => {
                let product = lineItem.product;
                if (product) {
                    for (let i = 0; i < product.userSessionImageCount; i++) {
                        let unit = (self.state.singleUnitProduct.unit ? self.state.singleUnitProduct.unit : 0);
                        if (i + 1 > 1 && i + 1 <= 20) unit = 0.18;
                        if (i + 1 > 20) unit = 0.04;

                        totalUnits += unit;
                        imageUnit += unit;
                    }
                    imageCount += product.userSessionImageCount;
                }
            });

            rows.push(["Images", imageCount, imageUnit.toFixed(2)]);

            if (hasSheet) {
                rows.push(["First Sheet Processing", "", 1]);
                totalUnits += 1;
            }

            rows.push(["Total Units", "Package Price", "Gross Sale", "Total Sales", "Total lab Cost"]);
            rows.push([`${totalUnits.toFixed(2)}`,`$${order?.prepaid?.price ?? 0}`,`$${order?.gross ?? 0}`, `$${order.prepaid && order.prepaid.eventPackageId == null ? (order.prepaid.price + order.gross).toFixed(2) : order.gross.toFixed(2)}`, `$${(totalUnits * (self.state.companyConfig.unitPrice ? self.state.companyConfig.unitPrice : 2)).toFixed(2)}`]);
            // headers
            self.createHeaderRow(sheet, rowNumber);

            rows.forEach((row, index) => {
                self.createContentRow(sheet, rowNumber + index + 1, row);
            });

            rowNumber += rows.length + 5;
            sheet.addRow(['', '', '','','','']);
        });

        let summary = sheet.addRow(['Summary']);
        let summaryHeader = sheet.addRow(['Total Orders','Package Price', 'Total Lab Cost', 'Total Sales', 'Start Date', 'End Date'], self.excelHeaderStyle());
        let summaryContent = sheet.addRow([`${this.state.summary.totalOrders}`, `$${self.summary.packagePrice}`,`$${this.state.summary.totalLabCost.toFixed(2)}`, `$${this.state.summary.totalSales}`, `${self.summary.startDate}`, `${self.summary.endDate}`]);


        self.initSummaryStyles(summaryHeader, summaryContent, self);
        summary.getCell(1).style = { font: { bold: true } };
        sheet.getColumn(1).width = 50;
        sheet.getColumn(2).width = 50;
        sheet.getColumn(3).width = 50;
        sheet.getColumn(4).width = 50;
        sheet.getColumn(5).width = 50;
        sheet.getColumn(6).width = 50;

        workbook.xlsx.writeBuffer().then(function (data) {
            self.triggerDownload(data);
        });
    }

    initSummaryStyles(header, content, self) {
        header.getCell(1).style = self.excelHeaderStyle();
        header.getCell(2).style = self.excelHeaderStyle();
        header.getCell(3).style = self.excelHeaderStyle();
        header.getCell(4).style = self.excelHeaderStyle();
        header.getCell(5).style = self.excelHeaderStyle();
        header.getCell(6).style = self.excelHeaderStyle();

        content.getCell(1).style = self.excelCellStyle();
        content.getCell(2).style = self.excelCellStyle();
        content.getCell(3).style = self.excelCellStyle();
        content.getCell(4).style = self.excelCellStyle();
        content.getCell(5).style = self.excelCellStyle();
        content.getCell(6).style = self.excelCellStyle();
    }

    triggerDownload(data) {
        const arrayBuffer = new Uint8Array(data);
        const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "Report.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
    }

    addOrderNumberHeading(sheet, order, text) {
        var row = sheet.addRow(text ? text : ["Order " + order.printOrderId +" | User ID: " + order.userId]);
        var headCell = row.getCell(1);
        headCell.style = { font: { bold: true } };
    }

    createHeaderCell(sheet, cellAddress, value) {
        var cell = sheet.getCell(cellAddress);
        cell.value = value;
        cell.style = this.excelHeaderStyle();
    }

    createHeaderRow(sheet, rowNumber) {
        this.createHeaderCell(sheet, `A${rowNumber}`, 'Products');
        this.createHeaderCell(sheet, `B${rowNumber}`, 'Quantity');
        this.createHeaderCell(sheet, `C${rowNumber}`, 'Units');
        this.createHeaderCell(sheet, `D${rowNumber}`, '');
        this.createHeaderCell(sheet, `E${rowNumber}`, '');
    }

    createContentCell(sheet, cellAddress, value, bold) {
        var cell = sheet.getCell(cellAddress);
        cell.value = value;
        cell.style = this.excelCellStyle();
        if (bold === true)
            cell.style.font.bold = true;
    }

    createContentRow(sheet, rowNumber, row) {
        let bold = (row[0] === "Total Units" || row[0] === "Package Price" || row[0] === "Gross Price", row[0] === "Total Sales" || row[0] === "Total lab Cost");
        this.createContentCell(sheet, `A${rowNumber}`, row[0], bold);
        this.createContentCell(sheet, `B${rowNumber}`, row[1], bold);
        this.createContentCell(sheet, `C${rowNumber}`, row[2], bold);
        this.createContentCell(sheet, `D${rowNumber}`, row[3], bold);
        this.createContentCell(sheet, `E${rowNumber}`, row[4], bold);
    }

    locationChange = (event) => {
        var self = this;
        self.setState({ locationId: event.target.value });
    }

    handleSelect = (tabKey) => {
        this.setState({ tabKey });
    }

    render() {
        let self = this;
        if (EM.allLoaded(EM.products, EM.companyConfig)) {
            this.initSummaryData();
            return (
                <div>
                    <Tabs
                        activeKey={this.state.tabKey}
                        onSelect={this.handleSelect}
                    >
                        <Tab
                            eventKey="orders"
                            title="Orders " />
                        <Tab eventKey="packages" title="Packages" />
                        <Tab eventKey="summary" title="Summary" />
                    </Tabs>
                    <div className="row">
                        <div className="col-2">
                            <FormGroup>
                                <Label>Start Date</Label>
                                <DatePicker
                                    selected={self.state.startDate}
                                    onChange={self.startDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    minDate={addDays(new Date(), -10000)}
                                    maxDate={self.state.maxDate}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <Label>End Date</Label>
                                <DatePicker
                                    selected={self.state.endDate}
                                    onChange={self.endDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    minDate={self.state.minDate}
                                    maxDate={new Date()}
                                />
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <Label>Event</Label>
                                <select className="form-control" onChange={self.locationChange}>
                                    <option value="">Please Select</option>
                                    {self.props.locations.map(loc => <option value={loc.locationId}>{loc.name}</option>)}
                                </select>
                            </FormGroup>
                        </div>
                        <div className="col-2">
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Button value="Export" onClick={() => { this.loadData(); }} className="btn btn-primary">Apply</Button>
                                <Button hidden={this.state.orders.length === 0} value="Export" onClick={() => { this.export(); }} className="btn btn-primary ml-2">Export</Button>
                            </FormGroup>
                        </div>
                    </div>
                    {
                        this.state.tabKey === "orders" &&
                        <>
                            {self.state.orders.length === 0 ? (<div>{this.state.recordsNotFound}</div>) :
                                self.state.orders.map((order) => {
                                    let totalUnits = 0;
                                    let imageCount = 0;
                                    let imageUnit = 0;
                                    let singleImageProducts = [...order.printOrderLineItems].filter((p) => p.product.productId === 2);
                                    let singleSessionProduct = [...order.printOrderLineItems].filter((p) => p.product.productId === 1);
                                    let hasSheet = _.find(order.printOrderLineItems, (lineItems) => {
                                        return lineItems.product.unit === 1;
                                    });
                                    return (
                                        <div className="row" key={order.id}>
                                            <div className="col-6">
                                                <div style={{ backgroundColor: '#f5f6fa' }}><h5>Order: {order.printOrderId} | User ID: {order.userId}</h5></div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr style={{ backgroundColor: "#ecf0f1" }}>
                                                            <th>Products</th>
                                                            <th colSpan={2}>Quantity</th>
                                                            <th colSpan={2}>Units</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            order.printOrderLineItems.map((lineItem) => {
                                                                let product = lineItem.product;
                                                                if (product && product.productId !== 1 && product.productId !== 2) {
                                                                    let unit = (product.unit ? product.unit : 0) * lineItem.quantity;
                                                                    totalUnits += unit;
                                                                    return (<tr>
                                                                        <td>{product.name}</td>
                                                                        <td colSpan={2}>{lineItem.quantity}</td>
                                                                        <td colSpan={2}>{unit}</td>
                                                                    </tr>)
                                                                }
                                                            })
                                                        }
                                                        <tr>
                                                            {
                                                                singleImageProducts.map((lineItem, index) => {
                                                                    let product = lineItem.product;
                                                                    if (product) {
                                                                        let unit = product.unit ? product.unit : 0;
                                                                        if (index + 1 > 1 && index + 1 <= 20) unit = 0.18;
                                                                        if (index + 1 > 20) unit = 0.04;
                                                                        totalUnits += unit;
                                                                        imageUnit += unit;
                                                                        imageCount += lineItem.quantity;
                                                                    }
                                                                })
                                                            }
                                                            {
                                                                singleSessionProduct.map((lineItem, index) => {
                                                                    let product = lineItem.product;
                                                                    if (product) {
                                                                        for (let i = 0; i < product.userSessionImageCount; i++) {
                                                                            let unit = (self.state.singleUnitProduct.unit ? self.state.singleUnitProduct.unit : 0);
                                                                            if (i + 1 > 1 && i + 1 <= 20) unit = 0.18;
                                                                            if (i + 1 > 20) unit = 0.04;

                                                                            totalUnits += unit;
                                                                            imageUnit += unit;
                                                                        }
                                                                        imageCount += product.userSessionImageCount;
                                                                    }
                                                                })
                                                            }
                                                            <td>Image</td>
                                                            <td colSpan={2}>{imageCount}</td>
                                                            <td colSpan={2}>{imageUnit.toFixed(2)}</td>
                                                        </tr>
                                                        {
                                                            (hasSheet ?
                                                                <tr>
                                                                    <td>First Sheet Processing</td>
                                                                    <td colSpan={2}></td>
                                                                    <td colSpan={2}>1</td>
                                                                </tr> : null)
                                                        }
                                                        <tr>
                                                            <td><b>Total Units</b></td>
                                                            <td><b>App Sales</b></td>
                                                            <td><b>Package Price</b></td>
                                                            <td><b>Total Sales</b></td>
                                                            <td><b>Total lab Cost</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>{(totalUnits + (hasSheet ? 1 : 0)).toFixed(2)}</td>
                                                            <td>${order.gross}</td>
                                                            <td>${order?.prepaid?.price ?? 0}</td>
                                                            <td>${order.prepaid && order.prepaid.eventPackageId == null ? (order.prepaid.price + order.gross).toFixed(2) : order.gross.toFixed(2)}</td>
                                                            <td>
                                                                ${((totalUnits + (hasSheet ? 1 : 0)) * (self.state.companyConfig.unitPrice ? self.state.companyConfig.unitPrice : 1)).toFixed(2)}
                                                                <div hidden={true}>
                                                                    {/* {self.summary.totalSales += order.gross ? order.gross : 0} */}
                                                                    {self.summary.packagePrice += order.prepaid ? order.prepaid.price : 0}
                                                                    {self.summary.totalSales += order.prepaid && order.prepaid.eventPackageId == null ? order.prepaid.price + order.gross : order.gross}
                                                                    {self.summary.totalLabCost += ((totalUnits + (hasSheet ? 1 : 0)) * (self.state.companyConfig.unitPrice ? self.state.companyConfig.unitPrice : 2))}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>)
                                })
                            }
                        </>
                    }
                    {this.state.tabKey === "summary" && (
                        <>
                            {self.state.orders.length === 0 ? (<div></div>) :
                                (<Summary summary={this.state.summary} packageOrdersSummary={this.state.packageOrdersSummary}/>)
                            }
                        </>
                    )}

                    {
                        this.state.tabKey === "packages" && <Packages packageOrders={this.state.packageOrders} />
                    }
                </div>
            )
        }
        return (<div>Loading...</div>);
    }
}
export default LabBilling;