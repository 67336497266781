/* global EM */
/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component } from 'react';
import PageTitle from  '../components/PageTitle';
import { connect } from "react-redux";
import ContactSupportButton from './ContactSupportButton';

class ErrorBlock extends Component {
    render() {        
        let error = this.props.error || { errorCode: 'general', message: 'Error' };                
        const msgKey = 'error.' + (error.errorCode || error.name);
        let message = EM.t(msgKey, true);
        if (!message)message = error.message;

        let title = EM.t('error.title', true);
        if (!title)title = 'Error';

        let isAccountError = error.errorCode?error.errorCode.startsWith('account'):false;

        return (
            <div className="p-4">
                <div className="error-block alert alert-danger clearfix">                              
                    <PageTitle title={title} text={message} className="m-3"/>
                    {isAccountError ? 
                        <button onClick={() => EM.authenticator.logout()} className="btn btn-link float-left">{EM.t('login.clear')}</button>
                    : null }
                    <ContactSupportButton className="float-right"/>  
                </div> 
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        error: state.error
    };
};

export default connect(mapStateToProps, null)(ErrorBlock);
