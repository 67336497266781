/* global EM */
import React, { Component } from 'react';

export default class ErrorIndicator extends Component {
    
    render() {
        if (!this.props.error)return null;
        let self = this;    
        let messages = [];
        Object.values(this.props.error).forEach((error) => {
            let message = EM.t('error.' + error.errorCode, true);
            if (!message){
                let bits = error.message.split(': ');
                if (bits.length > 1){
                    message = EM.t('error.' + bits[0], true);
                }
            }
            if (!message){
                message = error.message;
            }
            messages.push(message);
        });

        if (messages.length === 0)return null;
        
        return (
            <div className="error-indicator">    
                <div className='close' onClick={()=>{
                    EM.hideErrors(Object.keys(self.props.error)[0]);
                }}>X</div>
                <div>{EM.t('util.errors')}:</div>                
                <ul>
                    {messages.map((msg, index) => {
                        return (<li key={index}>{msg}</li>);
                    })}
                </ul>
            </div> 
        );
    }
}
