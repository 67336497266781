/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import Routes from '../app/Routes';
import ActiveEditField from '../components/ActiveEditField';
class EventTypes extends Component {
  constructor(props) {
    super(props);
    
    this.entity = props.eventTypes;
    this.state = {
      parentRecordDetail:{companyId:props.company.companyId},
      isSuperAdmin:EM.isSuperAdmin(),
      companyName:props.company.companyName,
    };
  }

  toEventPackages(locationId){
    console.log(Routes.compose(Routes.client.eventPackages,{locationId}))
    this.props.history.push(Routes.compose(Routes.client.eventPackages,{locationId}));
    // EM.redirectTo(Routes.client.intervals,{locationId})
  }

  render() {
    let columns = [];
    let data = [];
    let self = this;
    if (EM.allLoaded(this.entity)) {
      data = this.entity.get();
      columns = [     
        {
          dataField: "name",
          text: this.entity.columns("name"),
          validators: [ Validators.required,Validators.length100],
          filterOptions: {
            text: true,
          },
        },
        {
          dataField: "Packages",
          text: this.entity.columns("packages"),
          creatable:false,
          formatter: (cell,row) => {            
            return (
            <>
            <button className="btn btn-primary" onClick={(event)=>{self.toEventPackages(row.eventTypeId);}}><i className="fas fa-box"></i></button>
            </> 
            )
        },  
        },
      ];
    }
    const onUpdate=(toUpdate)=>{
      // console.log(toUpdate)
      const newRow= toUpdate.row
      newRow[toUpdate.dataField]=toUpdate.value

      this.entity.update(toUpdate.row, newRow,{companyId:toUpdate.row.companyId}).then((res) => {
    });
    }
    const chooseControls = (element, row)=>{
      if(element.asActive && !element.formatter && !element.editor){
        return <ActiveEditField row={row} dataField={element.dataField} onUpdate={onUpdate} defaultValue={row[element.dataField]!=null?row[element.dataField]:'false'} value={row[element.dataField]} />
      } else {
        return <input className="form-control" row={row} dataField={element.dataField} onBlur={(event)=>onUpdate({row:row,dataField:element.dataField,value:event.target.value})} defaultValue={row[element.dataField]} />
      }
    }
    const expandRow = {
      renderer: row => {
        const expandableColumns = columns.filter(column => (column.expandable===true))
        const render = expandableColumns.map((element,index) => {
            return (
              <div class="row  mt-4 form-group container" key={index}>
                <div class="col-3">
                <label class="">{element.text}</label>
                </div>
                <div class="col-3">
                  {chooseControls(element, row)}
                 
                </div>
            </div>
            )
        });
        return <div className="form-create">{render}</div>
    },
      showExpandColumn: false,
      onExpand: (row, isExpand, rowIndex, e) => {
        // console.log(row);
        // console.log(isExpand);
        // console.log(rowIndex);
        // console.log(e);
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <i class="fa fa-minus" aria-hidden="true"></i>;
        }
        return <i class="fa fa-plus" aria-hidden="true"></i>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <i class="fa fa-minus" aria-hidden="true"></i>
          );
        }
        return (
          <i class="fa-solid fa-magnifying-glass-plus"></i>
        );
      }
    };
    return (   
        <EntityTable
          expandRow={expandRow}
          pageTitle={`Event Types ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`}
          entity={this.entity}
          data={data}
          columns={columns}
          customOverRide={[this.state.parentRecordDetail]}
          className="table-overflow-container"
          hideImportExport={true}
          hideAudit ={true}
          parentRecordDetail={this.state.parentRecordDetail}
          onCreate={(data) =>{
            return true;
          }}
          onClose={() => {
            this.data = this.entity.get();
          }}
        />
    );
  }
}

export default EventTypes;
