/* global EM */
import logo from "../assets/logo.svg";
import "../style/Login.css";
import React, { Component } from "react";
import Spinner from "../components/Spinner";
import Routes from "../app/Routes";

class CompleteOrder extends Component {
  constructor(props) {
    super(props);
    let self = this;
    self.state = {
      stripeSessionId: self.props.match.params.stripeSessionId,
      event:null
    };

  
  }

  componentDidMount() {   
    EM.events.executePost({stripeSessionId:this.state.stripeSessionId},null,Routes.clientApi.activateAppointment,{stripeSessionId:this.state.stripeSessionId},true)
    .then((result)=>{
      let events = EM.events.get();
      EM.storage.set("message", events.closeMessage);
      // EM.redirectTo(Routes.client.completeOrder,{stripeSessionId:''});

    }); 
  }

  render() {
    
      return (
        <div key="contents" className="page container-fluid">
          <header className="header py-3" hidden={false}>
            <section className="container-fluid d-flex">
              <a href="/" className="">
                <img src={logo} alt="Flashops" />
              </a>
            </section>
          </header>
          <main className="heightFull">
            <div className="row heightFull">
              <div className="col-lg-5 aboutBG d-none d-lg-flex">
                <div className="row pt-5 px-4 h-100">
                  <div className="col-11 px-0 mt-5">
                    {/* <h4>About Company</h4>
                    <p>Booking Copy You can book your date and time simply from our website, Flashop app, or in person at one of our many retail locations we serve. Our promotional special is a digital download of your favorite image for $20 with over 25 images to choose from. Also other simple pricing package specials to choose from.</p>
                    <p>Want us to come to you?</p>
                    <p>Photography session and delivery.</p>
                    <p>Now the fun part. You confirmed your photography session and location to bring your loved ones Dress to impress to be Photographed.</p>
                    <p>We will take over 25+ images. Directly after the photo session your photos will be delivered to your Flashop app. Your Photographer will show you exactly how to select your Favorite image. When unlock it all the watermarks are removed so you can download it to your mobile device share it with friends and family or put it in your favorite editing app.</p>
                    <p>It’s all about the Digital and instant gratification.</p>
                    <p>If you decide you want all of your images unlocked. $149.99 groups under six. When you unlock the digital you also receive an text with all the images In a zip file.</p>
                    <p>Check out or print specials as well With sizes up to 30 x 40. At AM Studios We give you the most convenient way to have professional portraits scheduled, photographed, and delivered.</p>
                    <p>Thank you for your business and we look forward to serving you.</p> */}
                  </div>
                  <div className="col-12 px-0 py-2 mt-auto">
                    <h5>© 2024 Flashop. All rights reserved.</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 pt-5 mt-3">
                <div className="form mt-3 mt-lg-0">                
                  <div className="col-lg-10 mx-auto px-0">                    
                    <div >
                      <div className="step step1">
                        <div className="border border-primary border-2 text-dark text-uppercase p-3 d-flex w-100">
                          Thank you!
                        </div>
                        <p className="my-4">{EM.storage.get("message")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

        </div>
      );    
   
  }
}

export default CompleteOrder;
