/* global EM */
import React, { Component } from "react";
import { Button } from "reactstrap";
import PanelModal from '../PanelModal';
import { Button as ButtonPop, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const initialState={
    maziOrganizationCode:'',
    popUpError:''
};
export default class ConfirmMaziCode extends Component {
    constructor(props) {
        super(props);    
        this.state =initialState 
        this.onTextChange = this.onTextChange.bind(this);
        this.confirmMatch = this.confirmMatch.bind(this);
        this.ShowError = this.ShowError.bind(this);
        this.resetState = this.resetState.bind(this);
    }
    onTextChange(event, field) {
        this.setState({ maziOrganizationCode:event.target.value });
      }
    resetState(){
        this.setState(initialState);
        this.props.isValid(false)
    }  
    confirmMatch(){
        const maziOrganizationCode = this.state.maziOrganizationCode
        const prefix = this.props.prefix?this.props.prefix:''
        if((maziOrganizationCode)===(prefix+EM.location.get().maziOrganizationCode)){
            this.resetState()
            return this.props.isValid(true)
        } else {
            this.setState({popUpError:"Invalid Authorization Code Given!"})
            this.props.isValid(false)
        }
    }
    ShowError() {
        if (this.state.popUpError != "") {
            return <div className="row">
                <div className="col">
                    <div className="alert alert-danger" role="alert">
                        {this.state.popUpError}
                    </div>
                </div>
            </div>
        }
    }
    render(){

        return <PanelModal isOpen={this.props.isOpen} className={'panel-full'} toggle={(event) => { this.resetState();this.props.close() }}>
        <ModalHeader>Confirm Identity</ModalHeader>
        <ModalBody> 
        {this.ShowError()}
        <div className="">
        <div className="row">
        <div className="mb-3 col-sm">
            <span className="form-label fw-bold d-block mb-1">Authorization Code</span>
            <input placeholder="Authorization Code" autoFocus className="form-control m-0 border border-2 border-primary p-2" value={this.state.maziOrganizationCode} onChange={(event) => { this.onTextChange(event, 'maziOrganizationCode') }}></input>
          </div>
        </div>
        </div>
        </ModalBody>
        <ModalFooter>
            <Button color="light" className="mr-auto" onClick={this.confirmMatch}>Submit</Button>
            <Button className="pull-left" onClick={()=>{this.resetState();this.props.close()}}>
                {EM.t('util.closeButton')}
            </Button>
        </ModalFooter>
    </PanelModal>
    }
}
