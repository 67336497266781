/* global EM */
import React, { Component } from "react";
import { FaFileDownload, FaTrashAlt, FaUpload, FaDownload } from "react-icons/fa";
import _ from "underscore";
import MessageBox from '../components/MessageBox';
import { Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import PanelModal from '../components/PanelModal';
import EntityTable from "../components/EntityTables/EntityTable";
import FetchIndicator from "../components/FetchIndicator";

class Presets extends Component {
    constructor(props) {
        super(props);
        
        this.entity = EM.presets;
        this.state = {
            presets: [],
            showDeleteBox: false,
            deleteMessage: "",
            selectedPreset: {},
            showUploadPreset: false,
            presetName: "",
            showUploadImage: false,
            file: null,
            isLoading:false
        };
        this.entity.load();
        this.handleUploadPreset = this.handleUploadPreset.bind(this);
        this.handleUploadImage = this.handleUploadImage.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.delete = this.delete.bind(this);
        this.cancel = this.cancel.bind(this);  
    }

    componentDidMount() {
        let self = this;
        self.entity.load().then(() => {
            self.setState({ presets: self.entity.get() });
        });
    }

    rowStyleFormat(rowIdx) {
        return { backgroundColor: rowIdx % 2 === 0 ? '#ecf0f1' : '#dcdde1' };
    }

    populatePresets(preset, index) {
        const file = preset.name.split(".")
        const fileExtension = file[file.length - 1].toLowerCase();
        return (
            <div className="row m-1 p-2" style={this.rowStyleFormat(index)}>
                <div className="col mr-4">{preset.name}</div>
                <div className="col ml-4">
                    {/* {fileExtension!=="jpeg" && fileExtension!=="jpg" ? ( */}
                    {fileExtension=="preset" ?  (
                    <button
                        onClick={() => { this.handleUploadImage(preset.name, "Sample") }}
                        title="Upload New Sample Image"
                        className="btn btn-primary buttonWidth100">
                        <FaUpload />
                    </button>
                    ):null}
                    {preset.name =="SampleHSSeniors.jpg" || preset.name=="SampleDefault.jpg"?(
                        <img src={`${preset.path}?${Date.now()}`} alt={preset.name} style={{height:"40px", width:"40px", marginLeft:"5px"}}/>
                    ):null}
                </div>
                <div className="col" style={{marginLeft:"100px"}}>
                    {fileExtension=="preset" ? (
                    <button
                        onClick={() => { this.handleUploadImage(preset.name, "Original") }}
                        title="Upload New Original Image"
                        className="btn btn-primary buttonWidth100">
                        <FaUpload />
                    </button>
                ) : null}
                    {preset.name ==="OriginalHSSeniors.jpg" || preset.name==="OriginalDefault.jpg"?
                        <img src={`${preset.path}?${Date.now()}`} alt={preset.name} style={{height:"40px", width:"40px", marginLeft:"5px"}}/>
                    :null}
                </div>
                <div className="col-auto">
                    <a href={preset.path} title="Download" className="btn btn-primary" ><FaFileDownload /></a>
                    <button onClick={() => { this.showDeleteEvent(preset); }} className="btn btn-danger ml-1"><FaTrashAlt /></button>
                </div>
            </div>
        );
    }
    showDeleteEvent(preset) {
        let confirmMessage = "Are you sure you want to delete: " + preset.name + "?";
        this.setState({ deleteMessage: confirmMessage, selectedPreset: preset, showDeleteBox: true });
    }
    handleUploadPreset() {
        this.setState({ showUploadPreset: !this.state.showUploadPreset });
    }
    handleUploadImage(presetName, imgType) {
        const imgName = presetName.split(".")
        const newImgName = `${imgType}${imgName[0]}.jpg`
        this.setState({ showUploadImage: !this.state.showUploadImage, presetName:newImgName });
    }
    delete() {
        let self = this;
        this.entity.delete(this.state.selectedPreset.name)
            .then(() => {
                let presets = _.filter([...this.state.presets], (pr) => {
                    return pr.name !== self.state.selectedPreset.name;
                });
                this.setState({ presets: presets, showDeleteBox: false });
            });
    }
    cancel() {
        this.setState({ showDeleteBox: false });
    }
    handleFileChange(event) {
        this.setState({ file: event.target.files[0] });
    }
    containsSpecialChars(str) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str.replace(".preset", ""));
    }
    uploadFile() {
        let self = this;
        if (self.state.file.size > 0) {
            var data = new FormData();
            const isImage = self.state.file.type === "image/jpeg" || self.state.file.type === "image/jpg"
            if(!isImage) {
                if (self.containsSpecialChars(self.state.file.name)) {
                    alert("File has special char in the name");
                    return;
                }
                let preset_exist = _.filter([...self.state.presets], (preset) => {
                    return preset.name === self.state.file.name;
                });
                if (preset_exist.length > 0) {
                    alert("File Already exist");
                    return;
                }
                data.append("file", self.state.file);
            } else {
                // const imageName = self.state.presetName==="Default.preset"?"SampleDefault.jpg":"SampleHSSeniors.jpg"
                data.append("file", self.state.file, self.state.presetName);
            }
            this.setState({isLoading:true})
            self.entity.upload(data)
            .then((res) => {
                if (res.data.status == 200) {
                    let updatedPresets = [...self.state.presets]
                    if(isImage) {
                        const isImgExist = self.state.presets.find(img => img.name === res.data.data.name)
                        const existingOne = self.state.presets.filter(img => img.name !== res.data.data.name)
                        if(isImgExist) {
                            updatedPresets = [...existingOne, isImgExist];
                        } else {
                            updatedPresets = [...updatedPresets, res.data.data];
                        }
                    } else {
                        updatedPresets = [...updatedPresets, res.data.data];
                    }
                      this.setState({
                        presets: updatedPresets,
                        showUploadPreset: false,
                        showUploadImage:false,
                        file: null,
                        isLoading:false
                      });
                  }
            });
        }
        else {
            self.setState({
                presets: this.state.presets,
                showUploadPreset: true, file: null,
                showUploadImage: true,
                isLoading:false
            });
        }

    }
    render() {
        let self = this;
        return (
            <div key="contents" className="page container-fluid">
            {this.state.isLoading?<FetchIndicator items={this.state.isLoading?["Uploading"]:[]}/>:null}
                <div className="row">
                    <div className="col">
                        <h3><b>Presets</b></h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-right mb-1">
                        <button onClick={self.handleUploadPreset}
                            title="Add New Preset"
                            className="btn btn-primary buttonWidth100">
                            <FaUpload />
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row border-top border-bottom">
                            <div className="col p-2  font-weight-bold">Name</div>
                            <div className="col p-2  font-weight-bold">Sample Image</div>
                            <div className="col p-2 mr-4 font-weight-bold">Original Image</div>
                        </div>
                        {_.map(self.state.presets, (preset, index) => {
                            return self.populatePresets(preset, index);
                        })}
                    </div>
                    {/* <div className="col-6">
                        <div className="row">
                            <div className="col ml-4 mr-4 p-2 border-top border-bottom font-weight-bold">
                                <button onClick={self.handleUploadPreset}
                                    title="Add New Image"
                                    className="btn btn-primary buttonWidth100">
                                    <FaUpload />
                                </button>
                            </div>
                        </div>
                    </div> */}
                </div>

                {/* <MessageBox
                    m_bOpen={self.state.showDeleteBox}
                    m_fpYes={self.delete}
                    m_fpNo={self.cancel}
                    m_strMessage={self.state.deleteMessage}
                ></MessageBox> */}
                <PanelModal isOpen={this.state.showDeleteBox}>
                    <ModalHeader>Upload</ModalHeader>
                    <ModalBody>
                        <Form className="form-create">
                            <FormGroup className="form-group">
                                <Label>{self.state.deleteMessage}</Label>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => { self.delete() }}>Yes</Button>
                        <Button color="secondary" onClick={() => { self.cancel() }}>No</Button>
                    </ModalFooter>
                </PanelModal>
                <PanelModal isOpen={this.state.showUploadPreset}>
                    <ModalHeader>Upload</ModalHeader>
                    <ModalBody>
                        <Form className="form-create">
                            <FormGroup className="form-group">
                                <Input
                                    accept=".preset"
                                    onChange={self.handleFileChange}
                                    type="file"
                                >
                                </Input>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => { self.uploadFile() }}>Upload Preset</Button>
                        <Button color="secondary" onClick={() => { self.handleUploadPreset() }}>Cancel</Button>
                    </ModalFooter>
                </PanelModal>
                <PanelModal isOpen={this.state.showUploadImage}>
                    <ModalHeader>Upload</ModalHeader>
                    <ModalBody>
                        <Form className="form-create">
                            <FormGroup className="form-group">
                                <Input
                                    accept=".jpg, .jpeg"
                                    onChange={self.handleFileChange}
                                    type="file"
                                >
                                </Input>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => { self.uploadFile() }}>Upload Image</Button>
                        <Button color="secondary" onClick={() => { self.handleUploadImage("") }}>Cancel</Button>
                    </ModalFooter>
                </PanelModal>
            </div>

        );
    }
}
export default Presets; 