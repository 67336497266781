/* global EM */
import React, { Component } from "react";
import PageTitle from '../components/PageTitle';
class Downloads extends Component {
    render() {
        return (
            <div key="contents" className="page container-fluid">
               <PageTitle text={"These are downloads to run your photo business."} title={'Downloads'} bar={true} ></PageTitle>
               <div className="row">                
                 <div className="col-4"><a href="https://flashopmobileweb.blob.core.windows.net/install/16_6/FlashopStudioCloud_v16.exe" target="_open"><h4>Flashop Studio for Windows</h4></a></div>
                 <div className="col-auto">Flashop Studio allows you to send images to your customers.</div>
               </div>
               <div className="row">                
                 <div className="col-4"><a href="https://apps.apple.com/us/app/flashop-studio/id6446352436?mt=12" target="_open"><h4>Flashop Studio for Mac</h4></a></div>
                 <div className="col-auto">Flashop Studio allows you to send images to your customers.</div>
               </div>
            </div>
        )
    }
}
export default Downloads;