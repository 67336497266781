import React from 'react'
import Spinner from '../components/Spinner'

const Signup = ({loginError, loading, responseMessage,signUpFormValues, setSignUpFormValues,validate}) => {
    const handleFormValues = (e) => {
        const {name, value} = e.target
        setSignUpFormValues((prevState) => {
            return {...prevState, [name]: value}
        })
    }
  return (
    <>
      <div className="mb-2 mt-4 w-100">
        <label className="form-label fw-bold">First Name</label>
        <input
          disabled={loading}
          type="text"
          name='firstName'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.firstName}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">Last Name</label>
        <input
          disabled={loading}
          type="text"
          name='lastName'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.lastName}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">Company Name</label>
        <input
          disabled={loading}
          type="text"
          name='name'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.name}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">Email</label>
        <input
          disabled={loading}
          type="text"
          name="emailAddress"
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.emailAddress}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">Address</label>
        <input
          disabled={loading}
          type="text"
          name='address'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.address}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">Address2(Optional)</label>
        <input
          disabled={loading}
          type="text"
          name='address2'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.address2}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">Phone</label>
        <input
          disabled={loading}
          type="text"
          name='phone'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.phone}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">City</label>
        <input
          disabled={loading}
          type="text"
          name='city'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.city}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">State</label>
        <input
          disabled={loading}
          type="text"
          name='state'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.state}
        />
      </div>
      <div className="mb-2 w-100">
        <label className="form-label fw-bold">Zip</label>
        <input
          disabled={loading}
          type="text"
          name='zip'
          className={
            'form-control bg-light border-0 py-3 border-bottom rounded-0 w-100'
          }
          onChange={(event) => {
            handleFormValues(event)
            validate()
          }}
          value={signUpFormValues.zip}
        />
      </div>
      {loading && <Spinner text="Processing..." />}
      <div className="w-100">
        <div className="col-12" hidden={loginError.length <= 0}>
          <div className="alert alert-danger" role="alert">
            {loginError}
          </div>
        </div>
        <div className="col-12" hidden={responseMessage.length <= 0}>
          <div className="alert alert-success" role="alert">
            {responseMessage}
          </div>
        </div>
      </div>
    </>
  )
}

export default Signup
