/* global EM */
import React, { Component } from "react";
import { Validators } from '../util/EntityValidators';
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Routes from '../app/Routes';
import PopeUpUpdate from '../components/Widgets/PopeUpUpdate'
class Events extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.events;
    this.state = {
      isEditOpen:false,
      parentRecordDetail: { companyId: this.props.match.params.companyId },
      locationOptions:[],
      isSuperAdmin:EM.isSuperAdmin(),
    };
    this.entity.loadEntityByParent(this.state.parentRecordDetail,true);
    this.openEdit = this.openEdit.bind(this);
    this.getLocations();
    this.popUpRef = React.createRef()
  }
  async componentDidMount(){
    
    this.setState({companyName:EM.getDefaultCompany().companyName})    
    
  }
  getLocations() {
    EM.locations.loadEntityByParent(this.state.parentRecordDetail,true);
  }

  openEdit(column,row,validators, entity){
    this.popUpRef.current.setConfig({
      key:column.dataField,
      label:column.text,
      value:row[column.dataField],
      validators,
      entity,
      row
    })
    this.setState({isEditOpen:true})
  }
  
  render() {
    let columns = [];
    let data = [];
    if (EM.allLoaded(this.entity, EM.locations)) {
      data = this.entity.get();
      columns = [
        {
          dataField: "deal",
          text: 'Deal',
          validators: [Validators.required],
          filterOptions: {
            text: true
          },
          editable:false,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) =>this.openEdit(column,row,[Validators.required],this.entity) 
          },
          type:'textarea'
        },
        {
          dataField: "description",
          text: this.entity.columns("description"),
          validators: [Validators.required],
          filterOptions: {
            text: true
          },
          editable:false,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) =>this.openEdit(column,row,[Validators.required],this.entity) 
          },
          type:'textarea'
        },
        {
          dataField: "locationId",
          text: this.entity.columns("locationId"),
          fromEntity: EM.locations,
          filterOptions: {
            text: true
          }
        },
        {
          dataField: "allLocations",
          text: this.entity.columns("allLocations"),
          asActive: true
        },
        {
          dataField: "chargeAmount",
          text: this.entity.columns("chargeAmount"),
          validators:[Validators.positiveNumber,Validators.numberOnly],
          filterOptions: {
            text: true
          },
        },
        {
          dataField: "closeMessage",
          text: this.entity.columns("closeMessage"),
          filterOptions: {
            text: true
          },
          editable:false,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) =>this.openEdit(column,row,[Validators.required],this.entity) 
          },
          type:'textarea'
        },
        // {
        //   dataField: "order",
        //   text: this.entity.columns("order"),
        //   validators:[Validators.positiveNumber,Validators.numberOnly],
        //   filterOptions: {
        //     number: true
        //   }
        // },
        // {
        //   dataField: "showZipSearch",
        //   text: this.entity.columns("showZipSearch"),
        //   asActive: true,
        //   filterOptions: {
        //     bool: true
        //   }
        // },
        {
          dataField: "active",
          text: this.entity.columns("active"),
          asActive: true,
          creatable: false
        },
        {
          dataField: "copy",
          text: this.entity.columns("copy"),
          creatable: false,
          editable:false,
          formatter: (cell, row) => {
            return (<CopyToClipboard text={`${EM.baseUrl}${Routes.compose(Routes.client.booking,{eventId:row.eventId})}`}>
                    <button className="btn btn-primary">
                    <i className="fa fa-copy"></i>
                    </button>
                    </CopyToClipboard>);
          }
        }
      ];
    }
    return (
      <div key="contents" className="page container-fluid">
        <PopeUpUpdate ref={this.popUpRef} isOpen={this.state.isEditOpen} close={()=>this.setState({isEditOpen:false})} />
        <EntityTable
          customOverRide={[{order:"1"}]}          
          pageTitle={`Promotions ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`}
          entity={this.entity}
          data={data}
          columns={columns}
          className="table-overflow-container"
          hideImportExport={true}
          hideAudit={true}
          parentRecordDetail={this.state.parentRecordDetail}
          onClose={() => {
            this.data = this.entity.get();
          }}
          onCreate={(data) =>{
            let proceed=true;
            if(_.isNull(data.locationId) && _.isEqual(data.allLocations,false))
            {
              EM.setError("Please select a location or all.");
              proceed=false;
            }
            return proceed;
          }}
        />
      </div>
    );
  }
}

export default Events;
