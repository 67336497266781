/* global EM */
import * as types from '../actionTypes';

export function clearDomainEntity(entity) {
    return { type: 'CLEAR_' + entity.name.toUpperCase() };
}

export function loadEntityByParent(entity, isSilent, parentDetails) {
    let entityName = entity.name;
    let apiName = entity.apiName;
    return (dispatch, getState, api) => {
        return api.loadEntityByParent(apiName, isSilent, parentDetails, entity.getUrl).then(data => {
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function loadSingleEntity(entity, isSilent, params) {
    let entityName = entity.name;
    let apiName = entity.apiName;
    return (dispatch, getState, api) => {
        return api.loadSingleEntity(apiName, isSilent, params, entity.getSingleUrl).then(data => {
            return dispatch({ type: `LOAD_${entityName.toUpperCase()}`, data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function searchEntity(entity, isSilent, params, qs) {
    let entityName = entity.name;
    let apiName = entity.apiName;
    return (dispatch, getState, api) => {
        return api.searchEntity(apiName, isSilent, params, entity.searchUrl, qs).then(data => {
            console.log(`LOAD_${entityName.toUpperCase()}`);
            return dispatch({ type: `LOAD_${entityName.toUpperCase()}`, data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function loadDomainEntity(entity, isSilent) {
    let entityName = entity.name;
    let apiName = entity.apiName;
    return (dispatch, getState, api) => {
        return api.loadDomainEntity(apiName, isSilent).then(data => {
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function createDomainEntity(entity, newItem, parentRecordDetail) {
    let entityName = entity.name;
    let apiName = entity.apiName;
    return (dispatch, getState, api) => {
        return api.createDomainEntity(apiName, newItem, entity.createUrl, parentRecordDetail).then(data => {
            return dispatch({ type: 'CREATE_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function createBulkDomainEntity(domainId, entity, newItems) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.createDomainEntity(domainId, entityName, newItems).then(data => {
            return dispatch({ type: 'CREATE_BULK_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function updateDomainEntity(entity, newItem, oldItem, parentRecordDetail) {
    let entityName = entity.name;
    let apiName = entity.apiName;
    return (dispatch, getState, api) => {
        //Optimisitically update the value for immediate re-render
        dispatch({ type: 'UPDATE_' + entityName.toUpperCase(), data: newItem });
        return api.updateDomainEntity(apiName, newItem, entity.editUrl, parentRecordDetail).catch(error => {
            EM.setError(error);
            return dispatch({ type: 'UPDATE_' + entityName.toUpperCase(), data: oldItem });
        });
    };
}

export function deleteDomainEntity(entity, ids, parentRecordDetail) {
    let entityName = entity.name;
    let apiName = entity.apiName;
    return (dispatch, getState, api) => {
        return api.deleteDomainEntity(apiName, ids, entity.deleteUrl, parentRecordDetail).then(data => {
            return dispatch({ type: 'DELETE_' + entityName.toUpperCase(), data: ids });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function clearAllDomainEntity(domainId, entity) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.clearDomainEntity(domainId, entityName).then(data => {
            return dispatch({ type: 'CLEARALL_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function importDomainEntity(domainId, entity, items, isSilent, modsOnly) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.importDomainEntity(domainId, entityName, items, isSilent, modsOnly).then(data => {
            console.log(`LOAD_${entityName.toUpperCase()}`);
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function loadAdminEntity(tenantId, entity, entityId) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.loadAdminEntity(tenantId, entityName, entityId ? entityId : "").then(data => {
            console.log(`LOAD_${entityName.toUpperCase()}`);
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function createAdminEntity(tenantId, entity, newItem, entityId) {
    let entityName = entity.name;

    return (dispatch, getState, api) => {
        return api.createAdminEntity(tenantId, entityName, newItem, entityId).then(data => {
            return dispatch({ type: 'CREATE_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function deleteAdminEntity(tenantId, entity, ids) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.deleteAdminEntity(tenantId, entityName, ids).then(data => {
            return dispatch({ type: 'DELETE_' + entityName.toUpperCase(), data: ids });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function updateAdminEntity(tenantId, entity, newItem, oldItem) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        dispatch({ type: 'UPDATE_' + entityName.toUpperCase(), data: newItem });
        return api.updateAdminEntity(tenantId, entityName, newItem).catch(error => {
            EM.setError(error);
            return dispatch({ type: 'UPDATE_' + entityName.toUpperCase(), data: oldItem });
        });
    };
}

export function importAdminEntity(tenantId, entity, items, isSilent) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.importAdminEntity(tenantId, entityName, items, isSilent).then(data => {
            console.log(`LOAD_${entityName.toUpperCase()}`);
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });;
    };
}

export function auditDomainEntity(domainId, entity, entityId) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.auditDomainEntity(domainId, entityName, entityId).then(data => {
            return dispatch({ type: types.AUDIT_ENTITY_ITEM, key: entityName + entityId, data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function execute(entity, data, requestUrl, qs, parentRecordDetail, method,executeDispatch) {
    // execute(Entity,data,requestUrl,qs,parentRecordDetail, method)
    let entityName = entity.name;
    let apiName = entity.apiName;
    return (dispatch, getState, api) => {
        return api.execute(apiName, data, requestUrl, qs, parentRecordDetail, method).then(data => {
            if(executeDispatch ===true)
                return dispatch({ type: getDispatchAction(method) + entityName.toUpperCase(), data: data });
            return data;
        }).catch(error => {
            EM.setError(error);
        });
    };
}

function getDispatchAction(method) {
    switch (method) {
        case 'GET':
            return 'LOAD_';
        case 'POST':
            return 'CREATE_';
        case 'PUT':
            return 'UPDATE_';
        case 'DELETE':
            return 'DELETE_';
        default:
            return '';
    }
}