/* global EM */
import React, { Component } from "react";
import _ from "underscore";
import { FormGroup, Label } from "reactstrap";
import Select from 'react-select';
import Routes from '../../app/Routes';
import PageTitle from '../PageTitle';
import CalendarView from '../CalendarView';
import CalendarDayDetails from '../CalendarDayDetails';
import Dates from '../../util/Dates';

class LocationCalendar extends Component {
  constructor(props) {
    super(props);
    this.entity = EM.locations;
    let company = props.company;
    this.state = {
      loading:false,
      parentRecordDetail: { companyId: company.companyId },
      company : company,
      locationOptions:[],
      data:[],
      locationId:props.location.locationId,
      showCalendar:false,
      showDetails:false,
      dayDetails:null,
      selectedDate:null,
      isSuperAdmin:EM.isSuperAdmin(),
    };
    this.locationChange = this.locationChange.bind(this);
    this.onCalendarChange = this.onCalendarChange.bind(this);
    this.onDayClick = this.onDayClick.bind(this);
  }

  componentDidMount(){
    this.setState({companyName:this.props?.company?.name})
    this.locationChange(this.state.locationId)
  }

  locationChange(locationId,date){
    console.log(locationId);
    let self =this;
    if(locationId && locationId>0)
    EM.calendar.executeGet(null,{locationId:locationId,date:(date ? date : new Date()).toISOString()},Routes.clientApi.calendar, {})
    .then((result)=>{
      self.setState({data:result, locationId,showCalendar:true,showDetails:false});
    });
  }

  onCalendarChange(date){
    if(date) this.locationChange(this.state.locationId,date);
  }

  async onDayClick(date){    
    if(date)
    {
      let self =this;
      if(self.state.locationId && self.state.locationId>0)
      {
        this.setState({loading:true})
        await EM.calendar.executeGet(null,{locationId:self.state.locationId,date:(date ? Dates.toDateTime(Dates.fromDate(date)) : new Date().toISOString())},Routes.clientApi.calendarday, {})
        .then((result)=>{        
          self.setState({dayDetails:result,showCalendar:false,showDetails:true,selectedDate:date});
    
        });
        this.setState({loading:false})
      }
      
    }
    else
    this.setState({showCalendar:false,showDetails:true});
  }


  render() {
    let self = this;
      return (
        <div key="contents" className="page container-fluid">  
          <PageTitle title={`Calendar ${(this.state.companyName && this.state.isSuperAdmin)?`(${this.state.companyName})`:''}`} bar={true}></PageTitle>
          <div className="">
                  {
                    self.state.showCalendar ? <CalendarView onDayClick={(date)=>{self.onDayClick(date);}} onCalendarChange={(date)=>{self.onCalendarChange(date)}} data={this.state.data}></CalendarView> : ''
                  }
                  {
                    self.state.showDetails ? <CalendarDayDetails btnsCol={4} bookingPage={true} loading={this.state.loading} onClose={()=>{self.locationChange(self.state.locationId)}} onDayClick={(date)=>{self.onDayClick(date);}} data={this.state.dayDetails} locationId={this.state.locationId} date={this.state.selectedDate} ></CalendarDayDetails> :''
                  }
                
          </div>
        </div>
      );
  }
}

export default LocationCalendar;
