/* global EM */
import React, { Component } from "react";
import { FormFeedback,Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { Validators } from '../util/EntityValidators';
import _ from "underscore";
import EntityTable from "../components/EntityTables/EntityTable";
import Routes from '../app/Routes';
import PanelModal from '../components/PanelModal';
import ActiveEditField from '../components/ActiveEditField';

class Products extends Component {
  constructor(props) {
    super(props);

    this.entity = EM.products;
    this.state = {
      pageLoad: true,
      showPriceChange: false,
      priceValidationMessage: '',
      priceInvalid: false,
      companyProduct:{
        companyId:"0",
        price:'',
        productId:null,
        visible:true
      }
    };
    this.CompaniesList = this.CompaniesList.bind(this);
    this.onCompanyChanged = this.onCompanyChanged.bind(this);
  }

  getProducts(){        
    this.entity.executeGet(null, null, Routes.clientApi.products, { companyId: this.state.companyProduct.companyId }, true);
  }
  async componentDidMount() {
    let company = EM.getDefaultCompany();
    
    if(!EM.isSuperAdmin() && company && company.companyId> 0){      
      await this.setState({companyProduct:{...this.state.companyProduct,companyId:company.companyId+""}})
    }
    this.getProducts();
    EM.companies.load(true);
  }

  getColumns() {
    let superAdmin = EM.isSuperAdmin() ===true;
    return [
      {
        dataField: "name",
        text: 'Name',
        editable: superAdmin,
        validators: [Validators.required],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "unit",
        text: 'Units',
        // hideInTable:!superAdmin,
        editable: superAdmin,
        validators: [Validators.required,Validators.numberOnly],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "description",
        text: 'Description',
        editable:superAdmin,
        validators: [Validators.required],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "code",
        text: 'Code',
        hideInTable:!superAdmin,
        editable: superAdmin,
        validators: [Validators.required],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "price",
        text: "Price",
        editable: superAdmin && this.state.companyProduct.companyId === '0',
        validators: [Validators.required, Validators.numberOnly],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "productUrl",
        text: 'Product Url',
        hideInTable:!superAdmin,
        editable: superAdmin,
        validators: [Validators.required],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "shipping",
        text: 'Shipping',
        editable: superAdmin,
        validators: [Validators.required, Validators.numberOnly],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "type",
        text: 'Type',
        hideInTable:!superAdmin,
        editable: superAdmin,
        validators: [Validators.required],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "order",
        text: 'Order',
        hideInTable:!superAdmin,
        editable: superAdmin,
        validators: [Validators.required, Validators.numberOnly],
        filterOptions: {
          text: true,
        },
      },
      {
        dataField: "visible",
        text: 'Visible in App',
        hideInTable:superAdmin===true,
        editable:!superAdmin && this.state.companyProduct.companyId === '0',
        creatable:  this.state.companyProduct.companyId === '0',
        asActive: true        
      },
      {
        dataField: "active",
        text: 'Active',
        hideInTable:superAdmin !==true,
        editable:superAdmin ===true,
        creatable:  false,
        asActive: true        
      },
      {
        dataField: "actions",
        text: "Actions",
        hidden: this.state.companyProduct.companyId === '0',
        classes:  this.state.companyProduct.companyId === '0' ? 'hidden':'',
        headerClasses : this.state.companyProduct.companyId === '0' ? 'hidden':'',
        creatable: false,
        formatter: (cell, row) => {
          return <button title="Change Company Price" className="btn btn-primary" onClick={(event) => { this.showChangeCompanyPrice(row) }}><i className="fas fa-gear"></i></button>
        }
      }
    ];
  }

  onCompanyChanged(event) {
    const self = this;
    let companyProduct = Object.assign({}, self.state.companyProduct);
    companyProduct.companyId = event.target.value;
    self.setState({companyProduct},()=>{
      self.getProducts();
    });
    
  }

  CompaniesList() {
    return (<div className="btn-group">
      <Input disabled={!EM.isSuperAdmin()} value={this.state.companyProduct.companyId} type="select" onChange={(event) => { this.onCompanyChanged(event) }}>
        EM.isSuperAdmin()
        ? (<option value={"0"} key={"0"}>Default Products</option>)
        :null
        {
          _.map(EM.companies.get(), (company, index) => {
            return (<option value={company.companyId} key={company.companyId}>{company.name}</option>);
          })
        }
      </Input></div>);

  }

  showChangeCompanyPrice(row) {
    let companyProduct = Object.assign({},this.state.companyProduct,{productId:row.productId,price:row.price,visible:row.visible});
    this.setState({ showPriceChange: true,companyProduct});
  }

  onCompanyPriceChange(event) {
    let companyProduct = Object.assign({},this.state.companyProduct,{price:event.target.value});
    this.setState({ companyProduct});
  }

  onCompanyVisibleChange(value) {
    let companyProduct = Object.assign({},this.state.companyProduct,{visible:value});
    this.setState({ companyProduct});
  }

  async updateCompanyPrice() {
    const self = this;
    var notValid = Validators.numberOnly(this.state.companyProduct.price);
    if (notValid) {
      this.setState({ priceValidationMessage: notValid, priceInvalid: true });
      return;
    }

    let companyProduct = Object.assign({},self.state.companyProduct);

    EM.companyProducts.executePut(companyProduct,null,Routes.clientApi.companyProduct, null,true).then((data)=>{   
      companyProduct = Object.assign({},self.state.companyProduct,{productId:null,price:''});
      this.setState({priceInvalid:false,priceValidationMessage:'',companyProduct,showPriceChange:false});
      self.getProducts();
    });

    this.setState({priceInvalid:false,priceValidationMessage:''});

  }

  render() {
    let columns = [];
    let data = [];
    if (EM.allLoaded(this.entity, EM.companies)) {
      data = this.entity.get();
      if(EM.isSuperAdmin()!==true)
      {
        data = _.filter([...data],(item)=>{return item.active === true});
      }
      columns = this.getColumns();
    }
    return (
      <div key="contents" className="page container-fluid">
        <EntityTable
          pageTitle="Products"
          entity={this.entity}
          customTools={[this.CompaniesList]}
          data={_.sortBy(data, 'order')}
          columns={columns}
          className="table-overflow-container"
          hideImportExport={true}
          hideAudit={true}
          hideAddRecord = {EM.isSuperAdmin() !==true}
          hideDelete = {EM.isSuperAdmin() !==true}
          hideSelectColumn = {EM.isSuperAdmin() !==true}  
          onClose={() => {
            this.data = this.entity.get();
          }}
        />

        <PanelModal isOpen={this.state.showPriceChange}>
          <ModalHeader>Change Product Price for Company</ModalHeader>
          <ModalBody>
            <Form className="form-create">
              <FormGroup className="form-group" >
                <Label>
                  Price:
                </Label>
                <Input invalid={this.state.priceInvalid} key="ctrl" type="text" value={this.state.companyProduct.price} onChange={evt => { this.onCompanyPriceChange(evt); }} />
                <FormFeedback hidden={!this.state.priceInvalid} key="msg">{this.state.priceValidationMessage}</FormFeedback>
              </FormGroup>
            </Form>
            <Form className="form-create">
              <FormGroup className="form-group" >
                <Label>
                  Visible:
                </Label>          
                <ActiveEditField onUpdate={evt => { this.onCompanyVisibleChange(evt); }} defaultValue={this.state.companyProduct.visible} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="btn btn-primary" onClick={() => { this.updateCompanyPrice() }}>Save</Button>
            <Button color="btn btn-secondary" onClick={() => { this.setState({ showPriceChange: false, companyProduct: Object.assign({},this.state.companyProduct,{productId:null,price:''}) }); }}>Cancel</Button>
          </ModalFooter>
        </PanelModal>
      </div>
    );
  }
}

export default Products;
