/* eslint no-extend-native: 0 */
import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import Authenticator from "./app/Authenticator";
import API from "./app/API";
import EntityManager from "./entities/EntityManager";
import reducers from "./entities/reducers";
import App from "./app/App";
import Routes from "./app/Routes";
import ErrorBlock from "./components/ErrorBlock";
import LoginBlock from "./components/LoginBlock";
import LoginSelector from "./components/LoginSelector";
import LoggingInNotice from "./components/LoggingInNotice";
import PreLoginError from "./components/PreLoginError";
import Common from "./util/Common";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./style/index.scss";
import * as serviceWorker from "./serviceWorker";

(async function () {
  Common.registerGlobal();
  serviceWorker.register();
  let instance = { key: null, loginModes: ["Enterprise"] };
  let loginMode = window.localStorage.getItem("rm.login_mode");
  let loginRequired = window.location.href.toLocaleLowerCase().indexOf('booking') < 0 && window.location.href.toLocaleLowerCase().indexOf('completeorder') <0 && window.location.href.toLocaleLowerCase().indexOf('rescheduleappointmentbooking') <0;  
  if(loginRequired ===false){
    login(loginMode, instance);
  }
 else if (!loginMode) {
    ReactDOM.render(
      <LoginSelector
        instance={instance}
        onSelectLoginMode={(mode,credentials) => {
          window.localStorage.setItem("rm.login_mode", mode);
          login(mode, instance,credentials);
        }}
      />,
      document.getElementById("root")
    );
    return;
  } else {
    login(loginMode, instance);
  }
})();

async function login(loginMode, instance,credentials) {
  try {
    console.log("Login mode: " + loginMode);
    const authenticator = new Authenticator(instance);
    if (authenticator.initialize) {
      await authenticator.initialize();
    }

    console.log(`Authenticated:${await authenticator.isAuthenticated()}`);
    let loginRequired = window.location.href.toLocaleLowerCase().indexOf('booking') < 0 && window.location.href.toLocaleLowerCase().indexOf('completeorder') && window.location.href.toLocaleLowerCase().indexOf('rescheduleappointmentbooking') < 0;
    if (loginRequired===false) {}
    else if (!(await authenticator.isAuthenticated())) {}

    if (
      authenticator.isCallback ||
      (window !== window.parent && !window.opener)
    ) {
      console.log("Silent request via iFrame completed.");
      return;
    }

    initialize(authenticator, instance, loginMode,(!loginRequired));
  } catch (e) {
    ReactDOM.render(
      <PreLoginError error={e} />,
      document.getElementById("root")
    );
  }
}

async function initialize(authenticator, instance, loginMode,allowAnonymous) {
  const api = new API(authenticator, instance.key, loginMode);
  const store = createStore(
    reducers,
    applyMiddleware(thunk.withExtraArgument(api))
  );
  window.EM = new EntityManager(store, authenticator, api);
  var success = await window.EM.initialize(allowAnonymous);

  let component = null;
  if (success) {
    component = <App allowAnonymous={allowAnonymous} />;
    let qsObj = Routes.parseQuery();
    if (qsObj.code) {
      window.history.replaceState({}, document.title, "/");
    }
  } else {
    let state = store.getState();
    console.log(state.error)
    if (state.error) {
      if (
        state.error.errorCode === "account:100" ||
        state.error.errorCode === "account:101"
      ) {
        component = <LoginBlock authenticator={authenticator} />;
      } else {
        component = <ErrorBlock />;
      }
    } else {
      component = <ErrorBlock />;
    }
  }

  ReactDOM.render(
    <Provider store={store}>{component}</Provider>,
    document.getElementById("root")
  );
}
